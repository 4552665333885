import * as React from "react";
import * as ReactDOM from "react-dom";
import {BrowserRouter, NavLink, Route, Switch} from "react-router-dom";
import {EntityProvider} from "./providers/EntityProvider";
import {Index} from "./components/Index";
import {Nav} from "./components/Nav";

import {AppUtilProvider} from "./components/AppUtil";
import {ArtHistoryViewProvider} from "./components/ArtHistoryViewComponent";
import {ManagePlanProvider} from "./providers/ManagePlanProvider";
import {GuestOnlyComponent, UserOnlyComponent} from "./components/Sesssion";
import {LabelPrintViewProvider} from "./providers/LabelPrintViewProvider";
import {StockEditProvider} from "./providers/StockEditProvider";
import {EntitiesProvider} from "./providers/EntitiesProvider";
import {WithRoute} from "./providers/RouteProvider";
import {ArtDetailProvider} from "./providers/ArtDetailProvider";
import {ManageCostReceiptProvider} from "./providers/ManageCostReceiptProvider";
import {ReportsProvider} from "./providers/ReportsProvider";


window["require"] = function (mod) {
  return require('./' + mod);
}
// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update');
//   whyDidYouUpdate(React)
// }
// mobx.useStrict(true);

window["run"] = function () {
  ReactDOM.render(
    <div>
      <AppUtilProvider>

        <BrowserRouter>
          <Switch>
            <Route exact path='/login' component={GuestOnlyComponent}/>
            <UserOnlyComponent>
              <div>
                <Route path='/' component={Nav}/>

                <div style={{marginTop: '3em'}}>
                  <Route exact path='/' component={Index}/>
                  <Route exact path='/arts/print' component={LabelPrintViewProvider}/>
                  <Route exact path='/plans/:planId/arts/:action' component={ManagePlanProvider}/>
                  <Route exact path='/stocks/:stockId/:action' component={WithRoute(StockEditProvider)}/>
                  <Route exact path='/artCostReceipts/:artCostReceiptId/arts/:action' component={WithRoute(ManageCostReceiptProvider)}/>
                  <Route exact path='/artDetails/:artId/:action' component={WithRoute(ArtDetailProvider)}/>
                  <Route exact path='/artHistory/:artId' component={ArtHistoryViewProvider}/>
                  <Route exact path='/entity/:entity/:id' component={EntityProvider}/>
                  <Route exact path='/report/:entity/:type' component={WithRoute(ReportsProvider)}/>
                  {/*<Route exact path='/entity/:entity' component={EntitiesProvider}/>*/}
                  <Route exact path='/entity/:entity' component={WithRoute(EntitiesProvider)}/>
                </div>
              </div>

            </UserOnlyComponent>

          </Switch>

        </BrowserRouter>
      </AppUtilProvider>
      {/*<DevTools/>*/
      }
    </div>
    , document.getElementById('react'));


}
