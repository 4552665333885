import * as _ from "lodash";
import {columnGetter} from "../../utils/dataGrid";
import {constants} from "../../../defines/constants";
import {NumberCellFormatter} from "../../components/dataGrid/NumberCell";
import {autoCompleteEditor, autoCompleteFormatter} from "../../components/Utilities";
import * as React from "react";
import {ColumnProps} from "../../components/lib/VirtualizedDataGridDefinition";

export const DEFAULT_REFERENCES_DEFINITION = {
  artId: {
    width: 80,
    editable: false,
    editor: undefined,
    formatter: ({dependentValues, value}) => {
      let textColor = dependentValues.isUpdated ? 'red' : '';
      return <div className='system-id'>
        <div className={"ui text " + textColor}>{dependentValues.isUpdated ? '*' : ''} {value}</div>
      </div>
    },
    reference: {
      modelName: `art`,
      query: `&scope=full`
    },
  },
  artTags: {
    filterFormat: (key, value) => {
      return _.reduce(value.split(","), (res, val) => {
        res.push(`${key}$like=%${val}%`);
        return res;
      }, []).join('&')
    },
    schema: {
      endpoint: "/rest/artTags?count=1000",
      valueKey: 'key',
      multiple: true
    },
  },
  signId: {
    schema: {
      endpoint: "/rest/artSigns?count=1000",
      allowEmpty: true,
    },
    width: 200,
    reference: {
      modelName: `artSign`,
      query: ``
    },
  },
  staffId: {
    schema: {
      endpoint: "/rest/staffs?scope=masterCandidate&count=1000",
      allowEmpty: true,
      placeHolder: (value: any, dependentValues: any): any => {
        let staffId = columnGetter(dependentValues, 'staff.id');
        if (!_.isUndefined(staffId) && staffId === value) {
          return `${columnGetter(dependentValues, 'staff.name')}`
        } else {
          return "";
        }
      },
    },
    width: 200,
    reference: {
      modelName: `staff`,
      query: ``
    },
  },
  planType: {
    editor: autoCompleteEditor({suggestions: constants.convertToDropdownOption(constants.PLAN_TYPES)}),
    formatter: autoCompleteFormatter({suggestions: constants.convertToDropdownOption(constants.PLAN_TYPES)}),
    width: 200
  },
  authorKind: {
    editor: autoCompleteEditor({suggestions: constants.convertToDropdownOption(constants.AUTHOR_KINDS)}),
    formatter: autoCompleteFormatter({suggestions: constants.convertToDropdownOption(constants.AUTHOR_KINDS)}),
    width: 100
  },
  placeGroup: {
    editor: autoCompleteEditor({suggestions: constants.convertToDropdownOption(constants.PLACE_GROUP)}),
    formatter: autoCompleteFormatter({suggestions: constants.convertToDropdownOption(constants.PLACE_GROUP)}),
    width: 100
  },
  priorityKey: {
    schema: {
      endpoint: "/rest/priorities?sort=-key",
      valueKey: 'key',
    },
    width: 100
  },
  companyId: {
    schema: {
      endpoint: "/rest/companies?scope=masterCandidate&count=1000",
      placeHolder: (value: any, dependentValues: any): any => {
        let companyId = columnGetter(dependentValues, 'company.id');
        if (!_.isUndefined(companyId) && companyId === value) {
          return `${columnGetter(dependentValues, 'company.name')}`
        } else {
          return "";
        }
      },
    },
    width: 200,
    reference: {
      modelName: `company`,
      query: ``
    },
  },
  placeId: {
    schema: {
      endpoint: "/rest/places?scope=masterCandidate&count=1000",
      labelKey: (item) => `${item.name} : ${item.company.name}`,
      placeHolder: (value: any, dependentValues: any): any => {
        let placeId = columnGetter(dependentValues, 'place.id');
        if (!_.isUndefined(placeId) && placeId === value) {
          return `${columnGetter(dependentValues, 'place.name')} : ${columnGetter(dependentValues, 'place.company.name')}`
        } else {
          return "";
        }
      },
    },
    width: 200,
    reference: {
      modelName: `place`,
      query: ``
    },
  },
  authorId: {
    schema: {
      endpoint: "/rest/authors/withArtOrder?scope=open",
      placeHolder: (value: any, dependentValues: any): any => {
        let authorId = columnGetter(dependentValues, 'author.id');
        if (!_.isUndefined(authorId) && authorId === value) {
          return columnGetter(dependentValues, 'author.name')
        } else {
          return "";
        }
      },
    },
    width: 200,
    reference: {
      modelName: `author`,
      query: ``
    },
  },
  accountItemId: {
    schema: {
      endpoint: "/rest/accountItems"
    },
    reference: {
      modelName: `accountItem`,
      query: ``
    },
  },
  artCostItemId: {
    schema: {
      endpoint: "/rest/artCostItems"
    },
    reference: {
      modelName: `artCostItem`,
      query: ``
    },
  },
};

export const MODEL_SPECIFIC_DEFINITION = {
  arts: {
    id: {
      formatter: ({dependentValues, value}) => {
        const iconClassName = dependentValues.isUpdated ? 'red' : '';
        return <div className="system-id">
          <span
            className={"ui text " + iconClassName}>{dependentValues.isUpdated ? '*' : ''} {value}{dependentValues.stockDetail.stock.type === 'consignment' ? '@' : ''} </span>
        </div>
      }
    }
  },
  stocks: {
    type: {
      name: '在庫/委託',
      width: 80,
      editor: autoCompleteEditor({suggestions: constants.convertToDropdownOption(constants.STOCK_TYPES)}),
      formatter: autoCompleteFormatter({suggestions: constants.convertToDropdownOption(constants.STOCK_TYPES)}),
    }
  },
  planCosts: {
    type: {
      editor: autoCompleteEditor({suggestions: constants.convertToDropdownOption(constants.PLAN_COST_TYPES)}),
      formatter: autoCompleteFormatter({suggestions: constants.convertToDropdownOption(constants.PLAN_COST_TYPES)}),
    }
  },
  artResults: {
    soldStaffId: DEFAULT_REFERENCES_DEFINITION.staffId
  },
  planArts: {
    planArtStatusKey: {
      schema: {
        endpoint: "/rest/planArtStatuses",
        valueKey: 'key',
      }
    }
  }

};


interface ISpecificDefinition {
  readOnly?: boolean;
  updateOnly?: boolean;
  deletable?: boolean;
  fixedColumnCount?: number;
  fields?: ColumnProps[];
  excludeFields?: string[];
  filters?: {query: string, label: string}[];
}

export const SPECIFIC_TITLE: { [index: string]: string } = {
  'planArts?forRestListReadOnly': '出展リスト',
  'planArts?forRestListReadOnly,leave': '取置リスト',
  'planArts?forRestListReadOnly,arrive': '現着リスト',
  'planArts?forRestListReadOnly,new': '出展新規リスト',
  'plans?scheduled': '予定企画リスト',
  'plans?ended': '終了企画リスト',
  'artResults?forRestList': '販売履歴',
  'arts?full,isNotSold': '作品未売却リスト',
  'arts?full,isConsignment': '委託作品リスト',
  'arts?full,isSold': '売却済作品リスト',
  'arts?full,isNotSold,isStock': '在庫リスト',
  'arts?full,isNotSold,longTerm': '長期未出展リスト',
  'arts?full,isNotSold,isStock,longTerm': '長期未出展在庫リスト',
};

const PLANARTS_FORRESTLIST = {
  readOnly: true,
  fields: [
    {key: 'action'},
    {key: 'plan.title', name: '企画名'},
    {key: 'plan.place.name', name: '開催地名'},
    {key: 'plan.startDate', name: '開始日'},
    {key: 'plan.endDate', name: '終了日'},
    {key: 'art.id', name: '作品ID', width: 80, cellClass: 'ui text right'},
    {key: 'art.author.name', name: '作家名'},
    {key: 'art.title', name: '作品名'},
    {key: 'showPrice'},
    {key: 'planArtStatus.name', name: '出展状態'},
    {key: 'addDate'},
    {key: 'createdAt'},
    {key: 'updatedAt'},
  ],
  excludeFields: ['id', 'artId', 'planId', 'planArtStatusKey'],
  fixedColumnCount: 4
}

export const SPECIFIC_DEFINITION: { [index: string]: ISpecificDefinition } = {
  'planArts?forRestListReadOnly,leave': PLANARTS_FORRESTLIST,
  'planArts?forRestListReadOnly,arrive': PLANARTS_FORRESTLIST,
  'planArts?forRestListReadOnly,new': PLANARTS_FORRESTLIST,
  'planArts?forRestListReadOnly': PLANARTS_FORRESTLIST,
  'artResults?forRestList': {
    readOnly: true,
    fields: [
      {key: 'action'},
      {key: 'art.id', name: '作品ID', width: 80, cellClass: 'ui text right'},
      {key: 'art.title', name: '作品名'},
      {key: 'soldDate'},
      {key: 'soldPrice'},
      {key: 'soldStaff.name', name: '販売スタッフ'},
      {key: 'planArt.plan.title', name: '企画名'},
      {key: 'planArt.plan.startDate', name: '開始日'},
      {key: 'planArt.showPrice', name: '出展価格', formatter: <NumberCellFormatter/>},
      {key: 'planArt.plan.detail', name: '会場名'},
      {key: 'planArt.plan.place.name', name: '開催地名'},
    ],
    excludeFields: ['id', 'artId', 'planArtId', 'soldStaffId', 'createdAt'],
    fixedColumnCount: 6
  },
  'arts': {
    updateOnly: true,
    fields: [
      {key: 'id'}, // ・作品ID
      {key: 'action'},  // ・作家名
      {key: 'authorId'},  // ・作家名
      {key: 'title'},  // ・作品名
      {key: 'technique'},  // ・技法
      {key: 'edition'},  // ・ED
      {key: 'since'},  // ・年代
      {key: 'signId'},  // ・サイン
      {key: 'reasonedNo'},  // ・レゾネNO
      {key: 'note'},  // ・備考
      {key: 'listPrice'},  // ・上代
      {key: 'bazaarPrice'},  // ・バザール
      {key: 'auctionPrice'},  // ・オークション
      {key: 'cost'},  // ・原価
      {key: 'artTags'},  // ・タグ
      {key: 'planArtCount'},  // ・出展回数
      {key: 'artDetailCost'},  // ・出展回数
      {key: 'artResult.soldPrice', name: '販売価格', formatter: <NumberCellFormatter/>},  // ・販売価格
      {key: 'createdAt'},  // ・作成日時
      {key: 'updatedAt'},  // ・更新日時
    ],
    excludeFields: ['stockDetailId'],
    fixedColumnCount: 4
  },
  'artCosts?forRestList': {
    readOnly: true,
    fields: [
      {key: 'action'},
      {key: 'art.id', name: '作品ID', width: 80, cellClass: 'ui text right'},
      {key: 'art.title', name: '作品名'},
      {key: 'artCostItem.name', name: '作品経費項目'},
      {key: 'receiptDate'},
      {key: 'cost'},
      {key: 'note'},
      {key: 'createdAt'},  // ・作成日時
      {key: 'updatedAt'},  // ・更新日時
    ]
  },
  'plans': {
    fixedColumnCount: 4,
    deletable: true
  },
  'stocks': {
    fixedColumnCount: 5
  },
  'places': {
    filters:
      constants.PLACE_GROUP.map((group)=>{
        return {label: group.label, query: `placeGroup="${group.value}"`}
      })
  }
};
