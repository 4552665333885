import * as React from "react";
import * as _ from "lodash";

import {Treebeard} from "react-treebeard";
import {computed, observable} from "mobx";
import {IPlanCost} from "../../main/models/PlanCost";
import {DataGridBaseStore} from "./dataGrid/DataGridBaseStore";
import {JsonSchemeScope} from "../components/Utilities";


export class PlanCostStore extends DataGridBaseStore<IPlanCost>{

  @observable planId: number;

  customSchemaScope(schemaScope: JsonSchemeScope) {
    schemaScope.includes.push(
      {
        prefix: 'art',
        includes: [{
          prefix: 'author',
        }]
      }
    );
    return schemaScope;
  }

  get entityPluralLabel(): string {
    return 'planCosts';
  }

  get recordQuery(): string {
    return `?sort=-id&count=1000&planId=${this.planId}`;
  }

  getAdditionalCellActions(dependentValues): JSX.Element[] {
    const children = super.getAdditionalCellActions(dependentValues);
    children.push(
      <div key="copy" className="item" onClick={() => {
        this.addRow(_.reduce(dependentValues, (res, value, key) => {
          if (key !== 'id') {
            res[key] = value;
          }
          return res;
        }, {}));
      }}>コピー</div>
    );
    return children;
  }


  @computed
  get visibleAndOrderColumnKeys(): string[] {
    return [
      'action',
      'accountItemId',
      'staffId',
      'details',
      'receiptDate',
      'type',
      'cost',
      'note',
      'createdAt',
      'updatedAt'];
  }

}
