export const style =
  {
    tree: {
      base: {
        listStyle: 'none',
        backgroundColor: '#ffffff',
        margin: 0,
        padding: 0,
        color: '#21252B',
        fontFamily: 'lucida grande ,tahoma,verdana,arial,sans-serif',
        fontSize: '14px'
      }
      ,
      node: {
        base: {
          position: 'relative'
        }
        ,
        link: {
          cursor: 'pointer',
          position: 'relative',
          padding: '0px 5px',
          display: 'block'
        },
        activeLink: {
          backgroundColor: '#9DA5AB'
        },
        toggle: {
          base: {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'top',
            marginLeft: '-5px',
            height: '24px',
            width: '24px'
          }
          ,
          wrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: '-7px 0 0 -7px',
            height: '14px'
          },
          height: 14,
          width: 14,
          arrow: {
            fill: '#21252B',
            strokeWidth: 0
          }
        },
        header: {
          base: {
            display: 'inline-block',
            verticalAlign: 'top',
            color: '#21252B'
          }
          ,
          connector: {
            width: '2px',
            height: '12px',
            borderLeft: 'solid 2px black',
            borderBottom: 'solid 2px black',
            position: 'absolute',
            top: '0px',
            left: '-21px'
          },
          title: {
            lineHeight: '24px',
            verticalAlign: 'middle'
          }
        },
        subtree: {
          listStyle: 'none',
          paddingLeft: '19px'
        },
        loading: {
          color: '#E2C089'
        }
      }
    }

  };