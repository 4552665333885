import * as _ from "lodash";

export namespace constants {
  export const PLAN_TYPES = [
    {label: '上代', value: 'listPrice'},
    {label: 'バザール', value: 'bazaarPrice'},
    {label: 'オークション', value: 'auctionPrice'},
    {label: '廃棄', value: 'dispose'},
    {label: '返却', value: 'return'},
    {label: '紛失', value: 'missing'}
  ];

  export const PLAN_COST_TYPES = [
    {label: '未精算', value: 'cache'},
    {label: '精算済', value: 'card'}
  ];

  export const getPlanCostLabel = (value: string) => {
    return _.find(PLAN_COST_TYPES, (type) => {
      return type.value === value
    }).label;
  }

  export const STOCK_TYPES = [
    {label: '在庫', value: 'stock'},
    {label: '委託', value: 'consignment'}
  ];

  export const AUTHOR_KINDS = [
    {label: '国外', value: '0-foreign'},
    {label: '国内', value: '1-internal'}
  ];

  export const PLACE_GROUP = [
    {label: '販売', value: '0-sales'},
    {label: '額装', value: '1-repair'},
    {label: '交換', value: '2-exchange'},
    {label: '貸出', value: '3-lend'},
    {label: '返却', value: '4-return'}
  ];

  export const convertToDropdownOption = (values: { label: string, value: string }[]): { key: string, text: string, value: string }[] => {
    return values.map((value, key) => {
      return {key: value.label, text: value.label, value: value.value}
    })
  }

}
