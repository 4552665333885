import * as React from "react";
import {SuperagentPromise} from "../../utils/agent";
import {AppUtilStore} from "../../store/AppUtilStore";
import {RouteStore} from "../../providers/RouteProvider";
import {inject, observer} from "mobx-react";
import {IArt} from "../../../main/models/Art";

import Bluebird = require("bluebird");
import {ArtLabelPDFView} from "./ArtLabelPDF";


export interface LabelPrintViewState {
  arts: IArt[]
  skip: number
  copy: number
}

export interface LabelPrintViewProps {
  rowsCount?: number;
  rowGetter?: (i: number) => any & { get?: (key: string) => any };
  exclude?: string[];
  header?: JSX.Element;
  firstPageRowCount?: number;
  pageRowCount?: number;
  routeStore?: RouteStore;
  appUtilStore?: AppUtilStore;
}


let agent: SuperagentPromise;


@inject("routeStore", 'appUtilStore')
@observer
export class LabelPrintView extends React.Component<LabelPrintViewProps, LabelPrintViewState> {

  printContent: HTMLElement;

  constructor(props) {
    super(props);
    console.log("LabelPrintView:constructor", props);
    agent = props.appUtilStore.agent;
    this.state = {
      arts: [],
      skip: 0,
      copy: 1
    }
  }

  retrieveEntities(props) {
    console.log("retrieveEntities", props);
    let entityEndpoint = `/rest/arts${props.routeStore.location.search}`;
    props.appUtilStore.dimmerActive = true;

    agent.get<IArt[]>(entityEndpoint)
      .then((entities) => {
        this.setState({arts: entities.body});
      })
      .finally(() => {
        props.appUtilStore.dimmerActive = false;
      });

  }

  componentDidMount() {
    this.retrieveEntities(this.props);
  }

  componentWillReceiveProps(props) {
    this.retrieveEntities(props);
  }

  render() {

    return <div>
      <label>ラベルスキップ数:
        <input type="text" style={{'textAlign': 'right'}} value={this.state.skip} onChange={(e) => {
          try {
            let skip = parseInt(e.target.value, 10);
            if (skip >= 0) {
              this.setState({skip: skip});
            } else {
              this.setState({skip: 0});
            }
          } catch (e) {
          }
        }} onKeyDown={((e) => {
          let skip = this.state.skip;
          if (e.keyCode === 38) {
            skip++;
          } else if (e.keyCode === 40) {
            skip--;
          }
          if (skip < 0) {
            skip = 0;
          }
          this.setState({skip: skip});
        })}/>
      </label>
      <label>部数:
        <input type="text" style={{'textAlign': 'right'}} value={this.state.copy} onChange={(e) => {
          try {
            let copy = parseInt(e.target.value, 10);
            if (copy >= 1) {
              this.setState({copy: copy});
            } else {
              this.setState({copy: 1});
            }
          } catch (e) {
          }
        }} onKeyDown={((e) => {
          let copy = this.state.copy;
          if (e.keyCode === 38) {
            copy++;
          } else if (e.keyCode === 40) {
            copy--;
          }
          if (copy < 1) {
            copy = 1;
          }
          this.setState({copy: copy});
        })}/>
      </label>
      <button onClick={()=> {

        // せっかくなので残しておく…
        // function ImageToBase64(img, mime_type, width, height) {
        //   // New Canvas
        //   const canvas = document.createElement('canvas');
        //   canvas.width  = width;
        //   canvas.height = height;
        //   // Draw Image
        //   const ctx = canvas.getContext('2d');
        //   ctx.drawImage(img, 0, 0);
        //   // To Base64
        //   return canvas.toDataURL(mime_type);
        // }
        //
        // _.each(this.printContent.getElementsByTagName('img'), (img)=>{
        //   img.src = ImageToBase64(img, "image/jpeg", 230, 230);
        // });
        //
        //
        // this.printContent.getElementsByClassName("artLabelPDFViewDocument")[0].classList.remove("artLabelPDFViewDocument--preview")
        //
        // Bluebird.reduce(this.printContent.getElementsByClassName("artLabelPDFViewPage"), (pdf: jsPDF, page) => {
        //
        //   return html2canvas(page, {dpi: 400}).then((canvas) => {
        //     const dataURI = canvas.toDataURL("image/png");
        //     pdf.addImage(dataURI, 'PNG', 0, 0);
        //     pdf.addPage();
        //     return pdf;
        //   });
        //
        // }, new jsPDF()).then((pdf) => {
        //   pdf.deletePage(this.printContent.getElementsByClassName("artLabelPDFViewPage").length + 1);
        //   window.open(URL.createObjectURL(pdf.output('blob')));
        //   this.printContent.getElementsByClassName("artLabelPDFViewDocument")[0].classList.add("artLabelPDFViewDocument--preview");
        // });

        const searchParams = new URLSearchParams(this.props.routeStore.location.search);
        window.open(`/api/pdf/artLabel?id=${searchParams.get('id')}&skip=${this.state.skip}&copy=${this.state.copy}`);


      }}>印刷</button>
      <div className="react-pdf A4" ref={(ref) => {
        this.printContent = ref;
      }}>
        { ArtLabelPDFView(this.state.arts, this.state.skip, this.state.copy) }
      </div>
    </div>

  }

}
