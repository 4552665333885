import {Treebeard} from "react-treebeard";
import {action, computed, observable} from "mobx";
import {AppUtilStore} from "../store/AppUtilStore";

import * as moment from "moment";
import {IPlan} from "../../main/models/Plan";


export class PlanCalendarStore {

  constructor(public appUtilStore: AppUtilStore) {
    appUtilStore.title = "トップ";
    this.onNavigate(new Date());
  }

  @computed
  get agent() {
    return this.appUtilStore.agent
  }

  @observable.ref events = [];
  @observable.ref currentDate = new Date();

  @action.bound
  onNavigate(date) {
    this.currentDate = date;

    return this.agent.get(`/rest/plans/schedules?date=${moment(date).format('YYYY-MM-DD')}`)
      .then((plans: { body: IPlan[] }) => {
        this.events = plans.body.map((plan) => {
          plan.startDate = moment(plan.startDate).toDate();
          plan.endDate = moment(plan.endDate).toDate();
          return plan;
        });
      });
  }

}
