import {action, autorun, observable} from "mobx";
import {SAResponse} from "../../utils/agent";
import {RouteStore} from "../../providers/RouteProvider";
import * as React from "react";
import {AppUtilStore} from "../AppUtilStore";
import {IArt} from "../../../main/models/Art";
import moment = require("moment");


export class ArtStockStore {

  @observable.ref visible: boolean;
  @observable.ref artStocks: IArt[];
  @observable baseDate: Date = new Date();

  get agent() {
    return this.appUtilStore.agent;
  }

  get action() {
    return this.routeStore.match.params.action;
  }

  get artCostReceiptId() {
    return this.routeStore.match.params.artCostReceiptId;
  }

  constructor(public routeStore: RouteStore, private appUtilStore: AppUtilStore) {
    this.appUtilStore.title = '在庫レポート';
    // this.retrieve();
  }

  retrieve() {
    this.visible = false;
    this.appUtilStore.dimmerActive = true;

    return this.agent.get(`/rest/arts/stock?baseDate=${moment(this.baseDate).format('YYYY-MM-DD')}`)
    .then((artStocks: SAResponse<IArt[]>) => {
      this.artStocks = artStocks.body;

      this.appUtilStore.dimmerActive = false;
      this.visible = true;
    });

  }

  @action.bound
  refresh() {
    return this.retrieve()
  }

  @action.bound
  changeBaseDateDatePicker(date) {
    this.baseDate = date;
  }
}
