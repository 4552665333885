import * as React from "react";
import * as _ from "lodash";
import {EditorBase, HandleKeyWrapper} from "./EditorBase";
import {StringUtils} from "../../../utils/string";
import Bluebird = require("bluebird");

export class SimpleTextEditor extends EditorBase<any, any> {

  getValue(): any {
    let updated = {};
    let value = this.getInputNode().value;

    updated[this.props.column.key] = _.isEmpty(value) ?
      '' :
      StringUtils.toAscii(value);
    return updated;
  }

  render() {
    return <HandleKeyWrapper>
      <input
        ref={(node) => this.input = node}
        type="text"
        onBlur={this.props.onBlur}
        className="form-control"
        defaultValue={this.props.value}/>
    </HandleKeyWrapper>
  }
}
