import * as React from "react";

import * as _ from "lodash";

import Bluebird = require("bluebird");

export class FormatterBase<P, S> extends React.Component<P & { value?: any }, S> {

  shouldComponentUpdate(nextProps: any) {
    return nextProps.value !== this.props.value;
  }

  render() {
    return <div title={this.props.value}>{this.props.value}</div>;
  }
}
export class EditableFormatter extends FormatterBase<any, any> {

  render() {
    const isDisplayValue = !_.isEmpty(this.props.value + "") && !_.isUndefined(this.props.value);
    let value = isDisplayValue ? this.props.value : '　';

    if (this.props.column.editable) {
      let iconClass = isDisplayValue ? '' : this.props.column.required ? 'formatter--red' : 'formatter--olive';
      let cellClass = "formatter__value ui text ";
      cellClass += this.props.right ? " right" : "";

      return <div className={"ui labeled formatter formatter--editable " + iconClass}>
        <div className={cellClass}>{value}</div>
      </div>
    } else {
      let cellClass = "ui text formatter__value";
      cellClass += this.props.right ? " right" : "";

      return <div className="formatter formatter--default">
        <div className={cellClass}>{value}</div>
      </div>
    }
  }
}
