import * as React from "react";
import * as _ from "lodash";
import {inject, observer, Provider} from "mobx-react";
import {autorun, observable} from "mobx";
import {agent, SuperagentPromise} from "../utils/agent";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer/Dimmer";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";
import {AppUtilStore} from "../store/AppUtilStore";


@inject('appUtilStore')
@observer
export class AppUtilComponent extends React.Component<{ appUtilStore?: AppUtilStore }, {}> {

  render() {
    return <div>
      <Dimmer page inverted active={this.props.appUtilStore.dimmerActive}>
        <div className="ui large text loader">Loading</div>
      </Dimmer>

      {
        this.props.appUtilStore.modalOpen &&
        <Modal
          size="tiny"
          open={this.props.appUtilStore.modalOpen}>
          <Modal.Header>{this.props.appUtilStore.modalParams.title}</Modal.Header>
          <Modal.Content>
            {this.props.appUtilStore.modalParams.content}
          </Modal.Content>
          <Modal.Actions>
            {this.props.appUtilStore.modalParams.negative &&
            <Button color='red'
                    onClick={() => {
                      this.props.appUtilStore.modalOpen = false;
                      this.props.appUtilStore.modalParams.callback && this.props.appUtilStore.modalParams.callback(this.props.appUtilStore.modalParams.negativeLabel);
                    }}
            >{this.props.appUtilStore.modalParams.negativeLabel}</Button>
            }

            <Button color='green'
                    ref={(ref) => {
                      if(ref) {
                        ref.focus();
                      }
                    }}
                    onClick={() => {
                      this.props.appUtilStore.modalOpen = false;
                      this.props.appUtilStore.modalParams.callback && this.props.appUtilStore.modalParams.callback(this.props.appUtilStore.modalParams.positiveLabel);
                    }}
            >{this.props.appUtilStore.modalParams.positiveLabel}</Button>
          </Modal.Actions>
        </Modal>

      }
      {this.props.appUtilStore.dropDownParams &&
      <div style={
        {
          position: 'absolute',
          top: this.props.appUtilStore.dropDownParams.event.clientY,
          left: this.props.appUtilStore.dropDownParams.event.clientX,
          zIndex: 100,
        }
      }>
        {this.props.appUtilStore.dropDownParams.contents}
      </div>
      }

    </div>
  }
}

export class AppUtilProvider extends React.Component<{}, {}> {
  appUtilStore: AppUtilStore;

  constructor(props) {
    super(props);
    this.appUtilStore = new AppUtilStore();
    this.handleResize = this.handleResize.bind(this);
  }

  handleResize(e) {
    this.appUtilStore.height = window.innerHeight;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return <Provider appUtilStore={this.appUtilStore}>
      <div>
        <AppUtilComponent/>
        {this.props.children}
      </div>
    </Provider>
  }
}
