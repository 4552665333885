import * as React from "react";
import {Provider} from "mobx-react";

import {RouteProvider} from "./RouteProvider";
import {PlanTreeComponent} from "../components/PlanTreeComponent";
import {PlanTreeStore} from "../store/PlanTreeStore";

import Bluebird = require("bluebird");

export class PlanTreeProvider extends React.Component<{}, {}> {

  planTreeStore: PlanTreeStore;

  constructor(props) {
    super(props);
    this.planTreeStore = new PlanTreeStore();
    console.log("PlanTreeProvider:constructor", props);
  }

  componentDidMount() {
    this.planTreeStore.retrieve()
  }

  render() {
    return <RouteProvider {...this.props}>
      <Provider planTreeStore={this.planTreeStore}>
        <PlanTreeComponent />
      </Provider>
    </RouteProvider>
  }
}
