import * as React from "react";
import * as _ from "lodash";
import {SimpleTextEditor} from "../dataGrid/SimpleTextEditor";
import {ColumnProps, GridRowsUpdatedEvent} from "./VirtualizedDataGridDefinition";
import {SemanticDropdownEditor} from "../dataGrid/SemanticDropdown";
import Form from "semantic-ui-react/dist/commonjs/collections/Form/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";


export class VirtualizedDataGridEditorRenderer extends React.Component<{
  column: ColumnProps,
  value: any,
  editor: JSX.Element
  editorRef: any,
}, any> {

  render() {

    const editorProps = _.defaults({
      ref: this.props.editorRef
    }, this.props)

    let editorElement;
    if (React.isValidElement(this.props.editor)) {
      if (this.props.editor.type === SemanticDropdownEditor) {
        editorProps['suggestions'] = [{key: '-', text: '', value: ''}].concat(this.props.editor.props['suggestions'])
      }
      editorElement = React.cloneElement(this.props.editor as any, editorProps);
    } else if (_.isFunction(this.props.editor)) {
      let EditorClass = this.props.editor as any;
      editorElement = <EditorClass {...editorProps} />;
    }
    return <div>{editorElement}</div>
  }
}


export class VirtualizedDataGridFilterColumn extends React.Component<{
  column: ColumnProps,
  value: any,
  toRow?: number
  onGridRowsUpdated: (e: GridRowsUpdatedEvent) => void
}, {}> {

  editor: any;

  render() {
    let editorRef = (c) => {
      if (c) {
        this.editor = c;
      }
    };

    let editor = this.props.column.filterRenderer || this.props.column.editor || SimpleTextEditor;

    return <Form.Field
      label={this.props.column.name}
      control={VirtualizedDataGridEditorRenderer}
      {...this.props}
      editor={editor}
      onBlur={() => {
      }}
      onCommit={() => {
      }}
      editorRef={editorRef}
    />
  }
}

export class VirtualizedDataGridFilterEditor extends React.Component<{
  title: string,
  columns: Array<ColumnProps>,
  filters?: any
  open?: boolean
  onOK
  onCancel
  onClear
}, { filters: any }> {

  constructor(props) {
    super(props);
    this.state = {
      filters: props.filters || {}
    }
  }

  onGridRowsUpdated(e: GridRowsUpdatedEvent) {
    let filters = this.state.filters;
    let newFilters = _.omitBy(_.assign({}, filters, e.updated), _.isEmpty);
    this.setState({filters: newFilters})
  }

  componentWillReceiveProps(nextProps) {
    this.setState({filters: nextProps.filters})
  }

  filterColumns: Array<VirtualizedDataGridFilterColumn>;

  render() {
    const {
      columns,
      title,
      open,
    } = this.props;

    this.filterColumns = [];

    return <Modal
      size="large"
      open={open}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content image >
        <Form>
          <Grid doubling columns={5}>
            {
              columns.map((column) => {
                if (column.filterable) {
                  const editorProps = {
                    column: column,
                    value: this.state.filters[column.key],
                    dependentValues: this.state.filters,
                    onGridRowsUpdated: this.onGridRowsUpdated.bind(this)
                  };
                  return <Grid.Column key={column.key}>
                    <VirtualizedDataGridFilterColumn ref={(c) => {
                      this.filterColumns.push(c)
                    }} {...editorProps}/>
                  </Grid.Column>
                }
              })
            }
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red'
                onClick={this.props.onCancel}
        >閉じる</Button>
        <Button color='red'
                onClick={this.props.onClear}
        >クリア</Button>
        <Button color='green'
                onClick={() => {

                  let filters = _.omitBy(_.reduce(this.filterColumns, (result, filterColumn) => {
                    let updated = filterColumn.editor.getValue();
                    return _.assign(result, updated);
                  }, {}), (val, key) => {
                    return _.isUndefined(val) || _.isString(val) && _.isEmpty(val);
                  });
                  this.props.onOK(filters);
                }}
        >検索</Button>
      </Modal.Actions>
    </Modal>


  }

}
