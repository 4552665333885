import * as React from "react";
import * as _ from "lodash";

import {inject, observer} from "mobx-react";

import {Treebeard} from "react-treebeard";
import {PlanTreeStore} from "../store/PlanTreeStore";

import {style} from "./styles/treebeardStyle";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";


@inject("planTreeStore")
@observer
export class PlanTreeComponent extends React.Component<{ planTreeStore?: PlanTreeStore }, {}> {

  constructor(props) {
    super(props);
    console.log("PlanTreeComponent", props);
  }

  render() {
    return <div>
      <div>
        {
          <Treebeard
            style={style}
            data={this.props.planTreeStore.tree}
            onToggle={this.props.planTreeStore.onToggle.bind(this.props.planTreeStore)}
          />
        }
      </div>
    </div>
  }
}

@inject("planTreeStore")
@observer
export class PlanTreeModalComponent extends React.Component<{
  planTreeStore?: PlanTreeStore,
  onSelect?: (number: number) => void
}, {}> {

  handleModal = (openModal) => this.props.planTreeStore.openModal = openModal;

  render() {
    return <Modal
      open={this.props.planTreeStore.openModal}>
      <Modal.Header>企画選択</Modal.Header>
      <Modal.Content>
        <PlanTreeComponent />
      </Modal.Content>
      <Modal.Actions>
        <Button color='red'
                onClick={() => {
                  this.handleModal(false);
                }}
        >閉じる</Button>
        <Button color='green'
                disabled={_.isUndefined(this.props.planTreeStore.activeNode)}
                onClick={() => {
                  this.props.onSelect(this.props.planTreeStore.activeNode.id);
                  // this.props.routeStore.history.push(`/plans/${this.props.planTreeStore.activeNode.id}/arts/${this.props.routeStore.match.params.action}`);
                  this.handleModal(false);
                }}
        >選択</Button>
      </Modal.Actions>
    </Modal>
  }
}
