import * as React from "react";
import {observer} from "mobx-react";

import * as _ from "lodash";
import {Treebeard} from "react-treebeard";

import {RouteStore} from "../providers/RouteProvider";
import {agent} from "../utils/agent";

import {PlanTreeModalComponent} from "./PlanTreeComponent";
import {PlanTreeStore} from "../store/PlanTreeStore";
import {AppUtilStore} from "../store/AppUtilStore";
import {RegisterPlanArtStore} from "../store/RegisterPlanArtStore";
import {PlanCostStore} from "../store/PlanCostStore";
import {ManagePlanStore} from "../store/ManagePlanStore";
import {VirtualizedDataGrid} from "./lib/VirtualizedDataGrid";
import {DateUtils} from "../../utils/dateUtils";
import DatePicker from "react-datepicker";

const QrReaderImported: any = function (){ // FIXME: i dont know mocha failed. it will solved by babel?
  if(process.env.NODE_ENV !== "test") {
    return require('react-qr-reader');
  } else {
    return <div></div>
  }
}();



import {ArtListPDFView} from "./plan/ArtListPDF";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import Dropdown, {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";
import Container from "semantic-ui-react/dist/commonjs/elements/Container/Container";
import {PlanArtResultStore} from "../store/PlanArtResultStore";
import {PlanReportStore} from "../store/PlanReportStore";
import {PlanReportPDF} from "./ManagePlanComponent/PlanReportPDF";

@observer(["managePlanStore",
  "registerPlanArtStore",
  "planTreeStore",
  "routeStore",
  "appUtilStore",
  "planCostStore",
  "planArtResultStore",
  "planReportStore"
])
export class ManagePlanComponent extends React.Component<{
  managePlanStore?: ManagePlanStore,
  registerPlanArtStore?: RegisterPlanArtStore,
  planTreeStore?: PlanTreeStore,
  planArtResultStore?: PlanArtResultStore,
  routeStore?: RouteStore,
  appUtilStore?: AppUtilStore,
  planCostStore?: PlanCostStore,
  planReportStore?: PlanReportStore
}, {}> {

  grid: any;

  constructor(props) {
    super(props);
    console.log("RegisterPlanArt", props);
  }

  componentDidMount() {
    this.props.appUtilStore.title = '企画';
  }

  bulkUpdateStatus(from, to) {
    let entityEndpoint = `/rest/plans/${this.props.routeStore.match.params.planId}/arts/bulkStatusUpdate`;
    if (!this.promiseCaches[entityEndpoint]) {
      let arts = this.props.registerPlanArtStore.records.map((planArt) => {
        return {
          artId: planArt.artId,
          version: planArt.version
        }
      });
      this.props.appUtilStore.dimmerActive = true;

      let promise = agent.post(entityEndpoint, {from, to, arts});
      this.promiseCaches[entityEndpoint] = promise;
      promise
        .then((response) => {
          return this.props.managePlanStore.refresh();
        })
        .catch((response) => {
          console.log('agent.put.catch', response);
          this.props.appUtilStore.showError(response);
        })
        .finally(() => {
          console.log('agent.put.finally');
          delete this.promiseCaches[entityEndpoint];
          this.props.registerPlanArtStore.bulkChangeModalOpen = false;
          this.props.appUtilStore.dimmerActive = false;
        })
    }
  }

  promiseCaches = {};

  excel() {
    if (this.props.registerPlanArtStore.addDateDropdownValue) {
      window.open(`/api/excel/planList?id=${this.props.managePlanStore.plan.id}&addDate=${this.props.registerPlanArtStore.addDateDropdownValue}`);
    } else {
      window.open(`/api/excel/planList?id=${this.props.managePlanStore.plan.id}`);
    }
  }

  bindCellKeyDownToStore(store: { updateByIndex: (number) => any }) {
    return (e: KeyboardEvent, rowIndex: number, columnIndex: number) => {
      if (e.keyCode === 13 && e.ctrlKey) {
        store.updateByIndex(rowIndex);
        return false;
      }
      return true;
    }
  }

  render() {
    let toolbar: JSX.Element = null;
    if (this.props.managePlanStore.isEditing) {
      toolbar = !_.isUndefined(this.props.managePlanStore.plan) && <Grid>
        <Grid.Column floated='left' width={8}>
          <span>追加日: </span>
          <div style={{display: "inline-block"}}>
            <Dropdown placeholder='追加日' selection
                      options={this.props.registerPlanArtStore.addDateCandidates}
                      value={this.props.registerPlanArtStore.addDateDropdownValue}
                      onChange={this.props.registerPlanArtStore.changeAddDateDropdown}
            />
          </div>
          <Button onClick={() => {
            if (this.props.registerPlanArtStore.addDateDropdownValue) {
              window.open(`/api/pdf/planList?id=${this.props.managePlanStore.plan.id}&addDate=${this.props.registerPlanArtStore.addDateDropdownValue}`);
            } else {
              window.open(`/api/pdf/planList?id=${this.props.managePlanStore.plan.id}`);
            }

          }} icon="print"/>
          <Button onClick={() => {
            this.excel();
          }} icon="file excel outline"/>
        </Grid.Column>
        <Grid.Column floated='right' width={8}>
          <div style={{textAlign: "right"}}>
            <Button floated="right" onClick={() => {
              this.props.registerPlanArtStore.saveRows()
            }} icon="save"/>
          </div>
        </Grid.Column>
      </Grid>;
    } else if (this.props.managePlanStore.isNew) {
      toolbar = !_.isUndefined(this.props.managePlanStore.plan) &&
        <Grid>
          <Grid.Column floated='left' width={8}>
            <span>作品ID: </span>
            <Input onKeyDown={(e) => {
              let promise = this.props.registerPlanArtStore.addArtKeyDown(e);
              if (promise) {
                e.persist();
                promise.finally(() => {
                  e.target.focus();
                });
              }
            }}/>

            <Button onClick={this.props.registerPlanArtStore.toggleQRInput} icon="qrcode"/>

          </Grid.Column>

          <Grid.Column floated='right' width={8}>
            <div style={{textAlign: "right"}}>
              <span>追加日: </span>
              <div style={{display: "inline-block"}}>
                <DatePicker
                  className="form-control"
                  dateFormat='yyyy/MM/dd'
                  selected={this.props.registerPlanArtStore.addDate}
                  onChange={this.props.registerPlanArtStore.changeAddDateDatePicker}
                  shouldCloseOnSelect={false}
                />
              </div>
              <Button floated="right"
                      onClick={this.props.registerPlanArtStore.registerPlanArts} icon="save"/>
            </div>
          </Grid.Column>
        </Grid>
    } else if (this.props.managePlanStore.isSales) {
      toolbar = !_.isUndefined(this.props.managePlanStore.plan) && <Grid>
        <Grid.Column floated='right' width={8}>
          <div style={{textAlign: "right"}}>
            <Button floated="right" onClick={() => {
              this.props.planArtResultStore.saveRows()
            }} icon="save"/>
          </div>
        </Grid.Column>
      </Grid>
    } else if (this.props.managePlanStore.isCost) {
      toolbar = <div>
        <Grid>
          <Grid.Column floated='left' width={8}>
            <Button onClick={() => {
              this.props.planCostStore.addRow({planId: this.props.managePlanStore.plan.id})
            }}>行追加</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={8}>
            <div style={{textAlign: "right"}}>
              <Button floated="right" onClick={() => {
                this.props.planCostStore.saveRows()
              }} icon="save"/>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    } else if (this.props.managePlanStore.isReport) {
      toolbar = <div>
        <Grid>
          <Grid.Column floated='left' width={8}>
          </Grid.Column>
          <Grid.Column floated='right' width={8}>
            <div style={{textAlign: "right"}}>
              <Button onClick={() => {
                window.open(`/api/pdf/planReport?id=${this.props.managePlanStore.plan.id}`);
              }} icon="print"/>
            </div>
          </Grid.Column>
        </Grid>
      </div>

    }

    const tabChange = (action) => {
      if (this.props.managePlanStore.action !== action) {
        this.props.routeStore.history.push(`/plans/${this.props.managePlanStore.plan.id}/arts/${action}`)
      }
    };

    //HACK: 開発時にPDFのプレビューと切り替える
    const pdfPreview = false;

    return <div>
      <Segment.Group horizontal>
        <Segment>
          {!_.isUndefined(this.props.managePlanStore.plan) ?
            <span>
              <span><h3>
                <a href="javascript: void 0" onClick={() => {
                  this.props.routeStore.history.push(`/entity/plans?id=${this.props.managePlanStore.plan.id}`)
                }}>{this.props.managePlanStore.plan.title} {this.props.managePlanStore.plan.detail}</a>
                {
                  this.props.managePlanStore.plan.place &&
                  <span> [
                    <a href="javascript: void 0" onClick={() => {
                      this.props.routeStore.history.push(`/entity/places?id=${this.props.managePlanStore.plan.place.id}`)
                    }}>{this.props.managePlanStore.plan.place.name}</a>
                    :
                    <a href="javascript: void 0" onClick={() => {
                      this.props.routeStore.history.push(`/entity/companies?id=${this.props.managePlanStore.plan.place.company.id}`)
                    }}>{this.props.managePlanStore.plan.place.company.name}</a>
                  ]</span>
                }</h3></span>
            <span><h4>{DateUtils.format(this.props.managePlanStore.plan.startDate)}〜{DateUtils.format(this.props.managePlanStore.plan.endDate)}</h4></span>
            </span> :
            <span>企画選択をしてください</span>
          }
        </Segment>
        <Segment textAlign='right' className='button container no-border'>
          <Button onClick={() => {
            this.props.planTreeStore.openModal = true;
          }} icon="search"/>
        </Segment>
      </Segment.Group>

      {this.props.managePlanStore.visible ?
        <div>
          <div className="ui top attached tabular menu">
            <a className={(this.props.managePlanStore.isNew ? 'active' : '') + " item"}
               onClick={tabChange.bind(this, 'new')}>追加
            </a>
            <a className={(this.props.managePlanStore.isEditing ? 'active' : '') + " item"}
               onClick={tabChange.bind(this, 'edit')}>編集
            </a>
            <a className={(this.props.managePlanStore.isSales ? 'active' : '') + " item"}
               onClick={tabChange.bind(this, 'sales')}>売上
            </a>
            <a className={(this.props.managePlanStore.isCost ? 'active' : '') + " item"}
               onClick={tabChange.bind(this, 'cost')}>経費
            </a>
            <a className={(this.props.managePlanStore.isReport ? 'active' : '') + " item"}
               onClick={tabChange.bind(this, 'report')}>レポート
            </a>
          </div>

          <div className="ui bottom attached active tab segment" style={{display: 'block'}}>
            {(this.props.managePlanStore.isNew || this.props.managePlanStore.isEditing) &&
            this.props.registerPlanArtStore.records ?
              <div style={{position: "relative"}}>
                {toolbar}

                {!pdfPreview ?
                  <VirtualizedDataGrid
                    ref={(grid) => {
                      this.grid = grid;
                    }}
                    updateRef={this.props.registerPlanArtStore.records}
                    columns={this.props.registerPlanArtStore.columns}
                    rowsCount={this.props.registerPlanArtStore.rowsCount}
                    rowGetter={this.props.registerPlanArtStore.rowGetter}
                    onGridRowsUpdated={this.props.registerPlanArtStore.onGridRowsUpdated}
                    onCellKeyDown={this.bindCellKeyDownToStore(this.props.registerPlanArtStore)}
                    fixedColumnCount={
                      this.props.managePlanStore.isEditing ? 6 : 4
                    }
                    onGridSort={this.props.registerPlanArtStore.onGridSort}
                    sortColumn={this.props.registerPlanArtStore.sortColumn}
                    sortDirection={this.props.registerPlanArtStore.sortDirection}
                    height={this.props.appUtilStore.height - 300}
                  /> :

                  <div className="react-pdf A4" style={
                    {
                      height: this.props.appUtilStore.height - 270,
                      overflowY: "scroll"
                    }
                  }>
                    {
                      ArtListPDFView(
                        this.props.managePlanStore.plan,
                        this.props.registerPlanArtStore.addDateDropdownValue,
                        this.props.registerPlanArtStore.computeRows,
                        {
                          artSchema: this.props.registerPlanArtStore.schemaDefinitions['arts'],
                          planArtsSchema: this.props.registerPlanArtStore.schemaDefinitions['planArts'],
                        }
                      )}
                  </div>
                }

                <div>
                  {this.props.registerPlanArtStore.beepSound &&
                  <QrReaderImported
                    delay={300}
                    onError={this.props.registerPlanArtStore.handleError}
                    onScan={this.props.registerPlanArtStore.handleScan}
                    style={
                      {
                        width: '100%',
                        maxWidth: '800px',
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        opacity: 0.5
                      }
                    }
                  />
                  }
                </div>

              </div>
              :
              null}

            {this.props.managePlanStore.isSales && this.props.planArtResultStore.records ?
              <div>
                {toolbar}
                <VirtualizedDataGrid
                  ref={(grid) => {
                    this.grid = grid;
                  }}
                  updateRef={this.props.planArtResultStore.records}
                  columns={this.props.planArtResultStore.columns}
                  rowsCount={this.props.planArtResultStore.rowsCount}
                  rowGetter={this.props.planArtResultStore.rowGetter}
                  onGridRowsUpdated={this.props.planArtResultStore.onGridRowsUpdated}
                  onCellKeyDown={this.bindCellKeyDownToStore(this.props.planArtResultStore)}
                  fixedColumnCount={2/*  FIXME */}
                  onGridSort={this.props.planArtResultStore.onGridSort}
                  sortColumn={this.props.planArtResultStore.sortColumn}
                  sortDirection={this.props.planArtResultStore.sortDirection}
                  height={this.props.appUtilStore.height - 280}
                />
              </div>
              :
              null}

            {this.props.managePlanStore.isCost && this.props.planCostStore.records ?
              <div>
                {toolbar}
                <VirtualizedDataGrid
                  ref={(grid) => {
                    this.grid = grid;
                  }}
                  updateRef={this.props.planCostStore.records}
                  columns={this.props.planCostStore.columns}
                  rowsCount={this.props.planCostStore.rowsCount}
                  rowGetter={this.props.planCostStore.rowGetter}
                  onGridRowsUpdated={this.props.planCostStore.onGridRowsUpdated}
                  onGridSort={this.props.planCostStore.onGridSort}
                  onCellKeyDown={this.bindCellKeyDownToStore(this.props.planCostStore)}
                  sortColumn={this.props.planCostStore.sortColumn}
                  sortDirection={this.props.planCostStore.sortDirection}
                  fixedColumnCount={2/*  FIXME */}
                  height={this.props.appUtilStore.height - 280}
                />
              </div>
              :
              null}

            {this.props.managePlanStore.isReport && this.props.planReportStore.plan &&
            <div>
              {toolbar}
              <div style={{
                height: this.props.appUtilStore.height - 280,
                overflowY: 'scroll'
              }}>
                {PlanReportPDF(this.props.planReportStore.plan)}
              </div>
            </div>
            }
          </div>

        </div>
        :
        null}

      <PlanTreeModalComponent onSelect={(planId) => {
        this.props.routeStore.history.push(`/plans/${planId}/arts/${this.props.routeStore.match.params.action}`);
      }}/>

      <Modal
        size="tiny"
        open={this.props.registerPlanArtStore.bulkChangeModalOpen}>
        <Modal.Header>出展状態一括編集</Modal.Header>
        <Modal.Content>
          {this.props.registerPlanArtStore.bulkChangeModalOpen &&
          this.props.registerPlanArtStore.planArtStatuses ?
            <Container textAlign='center'>
              <Dropdown placeholder='変更前' search selection options={this.props.registerPlanArtStore.planArtStatuses}
                        value={this.props.registerPlanArtStore.fromStatus}
                        onChange={(e, d: DropdownProps) => {
                          this.props.registerPlanArtStore.fromStatus = d.value as string;
                        }}/>
              <i className="icon arrow right"/>
              <Dropdown placeholder='変更後' search selection options={this.props.registerPlanArtStore.planArtStatuses}
                        value={this.props.registerPlanArtStore.toStatus}
                        onChange={(e, d: DropdownProps) => {
                          this.props.registerPlanArtStore.toStatus = d.value as string;
                        }}/>
            </Container> : null
          }
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  onClick={() => {
                    this.props.registerPlanArtStore.bulkChangeModalOpen = false;
                  }}
          >閉じる</Button>
          <Button color='green'
                  onClick={() => {
                    this
                      .bulkUpdateStatus(this.props.registerPlanArtStore.fromStatus, this.props.registerPlanArtStore.toStatus)

                  }}
          >実行</Button>
        </Modal.Actions>
      </Modal>
    </div>
  }
}
