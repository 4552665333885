import * as React from "react";

import * as _ from "lodash";
import {EditorBase, HandleKeyWrapper} from "./EditorBase";
import {EditableFormatter, FormatterBase} from "./FormatterBase";
import {ColumnProps} from "../lib/VirtualizedDataGridDefinition";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";


export class SemanticDropdownFormatter extends FormatterBase<SemanticDropdownEditorProps, any> {

  getLabel(value) {
    return _.first(_.chain(this.props.suggestions)
      .filter((v) => {
        return v.value === value;
      })
      .map(v => {
        return v.text;
      })
      .value());
  }

  render() {

    if (!this.props.multiple) {
      let label = this.getLabel(this.props.value);
      if(_.isEmpty(label) && _.isFunction(this.props.placeHolder)) {
        label = this.props.placeHolder(this.props.value, this.props.dependentValues)
      }
      return <EditableFormatter {...this.props} value={label}/>
    } else {

      return <EditableFormatter {...this.props} value={
        (this.props.value ? this.props.value.split(',') : []).map((value) => {
          const label = this.getLabel(value);

          if(_.isFunction(this.props.placeHolder)) {
            return this.props.placeHolder(label, this.props.dependentValues)
          } else {
            return label;
          }

        }).join(',')
      }/>
    }
  }
}

export interface SemanticDropdownEditorProps {
  suggestions?: Array<DropdownItemProps>
  value?: any
  column?: any
  onCommit?: Function
  onChange?: Function
  onBlur?: any
  multiple?: boolean
  allowEmpty?: boolean
  placeHolder?: Function
  dependentValues?: any
}

const orderBySuggestions = (values: any[], suggestions: Array<DropdownItemProps>) => {
  let keyBySuggestions = _.keyBy(suggestions, 'value');

  return _.orderBy(values, (value) => {
    return _.indexOf(suggestions, keyBySuggestions[value])
  })
}

interface SemanticDropdownEditorState {
  value: any;
  options?: DropdownItemProps[];
  currentSuggestion: DropdownItemProps
}

export class SemanticDropdownEditor extends EditorBase<SemanticDropdownEditorProps, SemanticDropdownEditorState> {

  constructor(props) {
    super(props);
    let state: SemanticDropdownEditorState = {} as any;
    if (!props.multiple) {
      state.value = props.value;
      state.currentSuggestion = _.find(this.props.suggestions, { 'value': this.props.value }) as any; // FIXME: type error
    } else{
      state.value = props.value ? orderBySuggestions(props.value.split(','), props.suggestions) : []
    }

    state.options = this.createOptions(undefined, this.props.suggestions, this.props.allowEmpty, state.currentSuggestion);
    this.state = state;
  }

  createOptions(filter: string, suggestions: DropdownItemProps[], allowEmpty: boolean, currentSuggestion: DropdownItemProps) {
    let options = _(suggestions)
      .filter((suggestion) => {
        return !filter ||
          _.includes(suggestion.text as string, filter)
      })
      .take(20)
      .reduce((res, suggestion) => {
        res.push(suggestion);
        return res;
      }, allowEmpty ? [
        {key: '', text: '', value: ''} as DropdownItemProps
      ] : []);
    if(currentSuggestion && !_.find(options, { 'value': currentSuggestion.value })){
      options.push(currentSuggestion);
    }
    return options;
  }

  getValue(): any {
    let updated = {};
    if (!this.props.multiple) {
      updated[this.props.column.key] = this.state.value;
    } else {
      updated[this.props.column.key] = this.state.value.join(',')
    }
    return updated;
  }

  onChange(e, dropdown) {
    console.log("onChange", dropdown)
    if (this.props.onChange) {
      this.props.onChange(dropdown.value);
    }

    if (!this.props.multiple) {
      // this.props.onCommit({key: 'Enter'});
      this.setState({value: dropdown.value});
    } else {
      this.setState({value: orderBySuggestions(dropdown.value, this.props.suggestions)});
    }
  }


  onClose(e, dropdown) {
    console.log("onClose")
    setTimeout(() => {
      this.props.onCommit();
    }, 0);

  }

  onSearchChange(e, dropdown) {
    console.log("onSearchChange", e, dropdown);

    const options = this.createOptions(dropdown.searchQuery, this.props.suggestions, this.props.allowEmpty, this.state.currentSuggestion);

    this.setState({options: options});
    // 絞り込み後に１個めを選択しないと上下が効かなくなる
    setTimeout(()=>{
      this.setState({value: _(options).take(1).map(v => v.value).head()});
    }, 0);

  }

  render() {
    console.log("SemanticDropdownEditor#render", this);
    return <HandleKeyWrapper noStopKeys={[9, 27]}>
      <Dropdown
        ref={(dropdown: any)=>{
          if (dropdown) {
            const dropdownElement = dropdown.ref.current;
            const menu: HTMLElement = dropdownElement.getElementsByClassName('transition')[0] as HTMLElement;
            dropdownElement.addEventListener('mousewheel', (e) => {
              const height = menu.offsetHeight;
              const scrollHeight = menu.scrollHeight;
              const d = e.deltaY;
              if ((Math.abs(menu.scrollTop - (scrollHeight - height)) < 3/* 3は適当 */ && d > 0) || (menu.scrollTop === 0 && d < 0)) {
                e.preventDefault();
              }
            });
          }
        }}
        placeholder={this.props.column.name} search selection options={this.state.options}
        value={this.state.value}
        multiple={this.props.multiple}
        onChange={this.onChange.bind(this)}
        onClose={this.onClose.bind(this)}
        onBlur={this.props.onBlur}
        scrolling={false}
        onSearchChange={this.onSearchChange.bind(this)}
      />
    </HandleKeyWrapper>
  }
}

interface FilterPropTypes {
  onChange: Function,
  column: ColumnProps & { schema: SemanticDropdownEditorProps }
}
export class SemanticDropdownFilter extends React.Component<FilterPropTypes, any> {

  constructor(props) {
    super(props);
    console.log("SemanticDropdownFilter", props)
    this.state = {filterTerm: '', value: []};
  }

  onChange(e, dropdown) {
    if (this.props.column.schema.multiple) {
      let value = orderBySuggestions(dropdown.value, this.props.column.schema.suggestions);
      this.setState({value: value});
      this.props.onChange({
        filterTerm: value.length > 0 ? value.join(',') : undefined,
        column: this.props.column
      });
    } else {
      let value = dropdown.value.length > 0 ? [_.last(dropdown.value)] : [];
      this.setState({value: value});
      this.props.onChange({filterTerm: value[0], column: this.props.column});
    }
  }

  renderInput() {
    if (this.props.column.filterable === false) {
      return <span/>;
    }

    return <Dropdown
      ref={(dropdown: any)=> {
        if (dropdown) {
          const dropdownElement = dropdown.ref.current;
          const menu: HTMLElement = dropdownElement.getElementsByClassName('transition')[0] as HTMLElement;
          dropdownElement.addEventListener('mousewheel', (e) => {
            const height = menu.offsetHeight;
            const scrollHeight = menu.scrollHeight;
            const d = e.deltaY;
            if ((Math.abs(menu.scrollTop - (scrollHeight - height)) < 3/* 3は適当 */ && d > 0) || (menu.scrollTop === 0 && d < 0)) {
              e.preventDefault();
            }
          });
        }
      }}
      placeholder='State' search selection options={this.props.column.schema.suggestions}
      value={this.state.value}
      multiple={true}
      onChange={this.onChange.bind(this)}
    />
  }

  render() {
    return <div>
      <div className="form-group">
        {this.renderInput()}
      </div>
    </div>
  }
}
