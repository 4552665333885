import {Treebeard} from "react-treebeard";
import {autorun, computed, observable} from "mobx";
import * as React from "react";
import * as _ from "lodash";
import {DataGridBaseStore} from "../dataGrid/DataGridBaseStore";
import {SemanticCOLORS, SemanticICONS} from "semantic-ui-react";
import {ArtDetailStore} from "../ArtDetailStore";
import {IArtCost} from "../../../main/models/ArtCost";
import moment = require("moment");


type IRegisterArtCost = IArtCost & {
  _message?: string;
  isUpdated?: boolean;
  get?: (key: any) => any
}

export class ArtCostStore extends DataGridBaseStore<IRegisterArtCost> {

  @observable.ref visible: boolean;

  @observable addRowsInputNum: number = 1;

  constructor(public artDetailStore: ArtDetailStore) {
    super(artDetailStore.appUtilStore);

    autorun(() => {
      this.visible = false;
      return this.retrieve()
        .then(() => {
          this.visible = true;
        });
    });
  }

  get entityPluralLabel(): string {
    return 'artCosts';
  }

  get recordQuery(): string {
    return `?artId=${this.artDetailStore.artId}&sort=createdAt`;
  }

  canDeleteDependentValues(dependentValues: any): boolean {
    return true;
  }

  @computed
  get visibleAndOrderColumnKeys(): string[] {
    return [
      'action',  // system
      'artCostItemId',
      'receiptDate',
      'cost',
      'note',
      'createdAt',
      'updatedAt',
    ];
  }

  getActionIconProp(dependentValues: any): { name: SemanticICONS, color: SemanticCOLORS } {
    const name: SemanticICONS = dependentValues.artCostReceiptId ?
      'clipboard' : 'list';

    const color: SemanticCOLORS = dependentValues.isUpdated ? 'red' : 'black';

    return {name, color};
  }

  getAdditionalCellActions(dependentValues): JSX.Element[] {
    const children = super.getAdditionalCellActions(dependentValues);
    if(dependentValues.artCostReceiptId) {
      children.push(<div key="editArt" className="item" onClick={() => {
        this.artDetailStore.routeStore.history.push(`/artCostReceipts/${dependentValues.artCostReceiptId}/arts/edit`)
      }}>伝票編集</div>);
    }
    return children;
  }

  addRows() {
    _.range(0, this.addRowsInputNum)
      .forEach(() => {
        this.addRow(
          {
            artId: this.artDetailStore.artId,
            receiptDate: moment().toDate()
          });
      })
  }

}
