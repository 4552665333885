import * as React from "react";

import * as _ from "lodash";
import * as moment from "moment";
import {EditableFormatter, FormatterBase} from "./FormatterBase";
import Bluebird = require("bluebird");

export class DateTimeFormatter extends FormatterBase<any, any> {

  render() {
    if(!this.props.value) {
      return <div>　</div>
    } else {
      return <div className='ui text right formatter formatter--default'>{moment(this.props.value).format('YYYY/MM/DD HH:mm')}</div>
    }
  }
}
