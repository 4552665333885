import * as React from "react";
import * as _ from "lodash";
import {SimpleTextEditor} from "../dataGrid/SimpleTextEditor";
import {ColumnProps, GridRowsUpdatedEvent} from "./VirtualizedDataGridDefinition";
import {EditableFormatter} from "../dataGrid/FormatterBase";
import {isMobile} from "is-mobile";

import {columnGetter} from "../../utils/dataGrid";

export class VirtualizedDataGridCell extends React.Component<{
  column: ColumnProps,
  dependentValues: any
  style: any
  className?: string
  _key: string
  _ref?: (node: HTMLDivElement) => any
  fixedRowCount: number
  fixedColumnCount: number
  rowIndex: number
  columnIndex: number
  onGridRowsUpdated: (e: GridRowsUpdatedEvent) => any
  onCellKeyDown?: (e: KeyboardEvent, rowIndex: number, columnIndex: number) => any
  onSelectCell: (e: { scrollToColumn: number, scrollToRow: number }) => any

}, { editing: boolean }> {

  constructor(props) {
    super(props);
    this.state = {editing: false};
  }

  editor;

  commit() {
    this.setState({editing: false});
    if (this.props.onGridRowsUpdated) {
      this.props.onGridRowsUpdated({
        toRow: this.props.rowIndex - this.props.fixedRowCount,
        cellKey: this.props.column.key,
        updated: this.editor.getValue()
      })
    }
  }

  commitCancel() {
    this.setState({editing: false});
  }


  setTextInputFocus() {
    let inputNode = this.editor.getInputNode();
    if (inputNode) {
      inputNode.focus();
      if (inputNode.tagName === 'INPUT') {
        inputNode.select();
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.column !== nextProps.column ||
      this.state.editing !== nextState.editing ||
      this.props.className !== nextProps.className ||
      this.props.dependentValues !== nextProps.dependentValues;
  }

  componentDidUpdate() {
    if (this.state.editing) {
      this.setTextInputFocus();
    }
  }

  onClick() {
    this.props.onSelectCell({
      scrollToRow: this.props.rowIndex,
      scrollToColumn: this.props.columnIndex
    });
  }

  onKeyDown(e: KeyboardEvent) {

    if(this.props.onCellKeyDown && this.props.onCellKeyDown(e, this.props.rowIndex - this.props.fixedRowCount, this.props.columnIndex) === false) {
      return;
    }

    if (e.keyCode === 13) {
      if (this.state.editing) {
        this.commit();
      } else {
        this.setState({editing: true});
      }

    } else if (e.keyCode === 27) {
      this.setState({editing: false});
    } else if (e.keyCode === 9) { // tab key

      if (this.state.editing) {
        this.commit();
      }

      this.props.onSelectCell({
        scrollToRow: this.props.rowIndex,
        scrollToColumn: this.props.columnIndex + (e.shiftKey ? -1 : 1)
      });
      e.preventDefault();
    }
  }

  onBlur() {
    if (this.state.editing) {
      this.commit();
    }
  }


  onDoubleClick() {
    if (!this.state.editing) {
      this.setState({editing: true});
    }
  }

  render() {
    const {
      dependentValues,
      column,
      className,
      _key,
      _ref,
      rowIndex,
      fixedRowCount,
      style,
      columnIndex
    } = this.props;

    let tabIndex = -1;
    let onKeyDown = null;
    let onClick = null;
    let onDoubleClick = null;

    let value = columnGetter(dependentValues, column.key);

    if (column.editable || column.editor) {
      onKeyDown = this.onKeyDown.bind(this);
      onDoubleClick = this.onDoubleClick.bind(this);

      if (isMobile()) {
        onClick = this.onDoubleClick.bind(this);
      } else {
        onClick = this.onClick.bind(this);
      }

    } else {
      onClick = this.onClick.bind(this);
    }

    if (this.state.editing) {
      let editorElement = null;
      let editorRef = (c) => {
        if (c) {
          this.editor = c;
        }
      };

      const editorProps = {
        ref: editorRef,
        column: this.props.column,
        value: value,
        onCommit: this.commit.bind(this),
        onCommitCancel: this.commitCancel.bind(this),
        dependentValues: dependentValues,
        onBlur: this.onBlur.bind(this),
      };

      if (column.editor) {
        if (React.isValidElement(column.editor)) {
          editorElement = React.cloneElement(column.editor as any, editorProps);
        }
        if (_.isFunction(column.editor)) {
          // const ColumnEditor = column.editor as any;
          // editorElement = <ColumnEditor ref={editorRef} {...editorProps} />
          throw new Error("is not supported function type editor")
        }
      } else {
        editorElement = <SimpleTextEditor {...editorProps}/>
      }

      return <div className={className + ' ReactVirtualized__Grid__innerScrollContainer__Body__Cell--isEditing'}
                  key={_key}
                  onKeyDown={onKeyDown}
                  tabIndex={tabIndex}
                  style={style}>{editorElement}</div>


    } else {


      let formatterElement = null;

      const formatterProp = {
        column: this.props.column,
        value: value,
        dependentValues: dependentValues,
      };

      if (column.formatter) {
        if (React.isValidElement(column.formatter)) {
          formatterElement = React.cloneElement(column.formatter as JSX.Element, formatterProp);
        } else {
          // formatterElement = column.formatter(formatterProp);
          const ColumnFormatter = column.formatter as any;
          formatterElement = <ColumnFormatter {...formatterProp} />
          // throw new Error("is not supported function type formatter")
        }
      } else if (column.editable || column.editor) {
        formatterElement = <EditableFormatter {...formatterProp} />
      } else {

        formatterElement = <div className="formatter formatter--default">
          <div className={'formatter__value ' + column.cellClass}>{value}</div>
        </div>

      }

      return <div className={className}
                  key={_key}
                  ref={_ref}
                  onKeyDown={onKeyDown}
                  onClick={onClick}
                  onDoubleClick={onDoubleClick}
                  tabIndex={tabIndex}
                  style={style}>{formatterElement}</div>
    }
  }

}
