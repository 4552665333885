import {action, computed, observable} from "mobx";
import * as _ from "lodash";
import {RouteStore} from "../../providers/RouteProvider";

export class PagerStore {

  @observable contentRange: string = ''; // Content-Range:items 0-4/5

  routeStore: RouteStore;

  regexRange = /items ([0-9]+)\-([0-9]+)\/([0-9]+)/;

  constructor(routeStore: RouteStore) {
    this.routeStore = routeStore;
  }

  @computed get rangeRegexExec() {
    /*
     1:"0"
     2:"4"
     3:"5"
     */
    return this.regexRange.exec(this.contentRange);
  }

  @computed get maxRecords(): number {
    if (!this.rangeRegexExec) {
      return 0;
    }
    return parseInt(this.rangeRegexExec[3], 10);
  }

  @computed get startRowNum(): number {
    return parseInt(this.rangeRegexExec[1], 10);
  }

  @computed get endRowNum(): number {
    return parseInt(this.rangeRegexExec[2], 10);
  }

  @computed get pagePerRecord(): number {
    if (!this.rangeRegexExec) {
      return 0;
    }
    const query: any = this.routeStore.query;
    return query.count ? parseInt(query.count, 10) : 100;
  }

  @computed get currentPage(): number {
    if (!this.rangeRegexExec) {
      return 0;
    }
    const query: any = this.routeStore.query;
    return query.page ? parseInt(query.page, 10) : Math.floor(this.endRowNum / this.pagePerRecord);
  }

  @computed get maxPage(): number {
    if (!this.rangeRegexExec) {
      return 0;
    }
    return Math.ceil(this.maxRecords / this.pagePerRecord);
  }

  @computed get pages(): number[] {
    const maxPages = _.range(this.maxPage);

    let ddd = _.filter(maxPages, (page)=>{
      return page < 3 ||
        page >= this.maxPage - 3||
        page > this.currentPage - 3 && page < this.currentPage + 3;
    });

    return _.reduce(ddd, (res, p, i)=>{

      let tail = res[res.length - 1];
      if(p - 1 !== tail && p !== 0 && p !== this.maxPage){
        res.push(-1);
      }
      res.push(p);

      return res;
    }, []);
  }


  @action.bound
  handlePageClick(pageNum) {
    let newPagePath = this.routeStore.location.pathname + this.routeStore.location.search;
    if (!_.includes(newPagePath, '?')) {
      newPagePath += '?';
    }
    let pageQuery = 'page=' + this.currentPage;
    if (_.includes(newPagePath, pageQuery)) {
      newPagePath = newPagePath.replace(pageQuery, 'page=' + pageNum)
    } else {
      newPagePath += '&page=' + pageNum;
    }
    this.routeStore.history.push(newPagePath)
  }
}
