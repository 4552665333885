import {action, autorun, computed, observable} from "mobx";
import * as _ from "lodash";
import {SortTypes} from "../components/lib/VirtualizedDataGrid";
import {DataGridBaseStore} from "./dataGrid/DataGridBaseStore";
import {IUserTransactionModel} from "../../main/models/base/UserTransactionModel";
import {AppUtilStore} from "./AppUtilStore";
import {RouteStore} from "../providers/RouteProvider";
import {DEFAULT_REFERENCES_DEFINITION, SPECIFIC_DEFINITION, SPECIFIC_TITLE} from "./dataGrid/definitions";
import {PagerStore} from "./entities/pager";
import {getActionCell} from "./entities/actionCell";
import {GridRowsUpdatedEvent} from "../components/lib/VirtualizedDataGridDefinition";
import {IPlace} from "../../main/models/Place";

export class EntitiesStore extends DataGridBaseStore<IUserTransactionModel & { isUpdated?: boolean }> {
  @observable.ref visible: boolean = false;

  @observable.ref filters: any = {};
  @observable.ref filterOpen: boolean = false;
  @observable.ref selectMode: boolean = false;

  @observable.ref selectedRecordIds: Array<any> = [];

  @observable routeStore: RouteStore;

  pager: PagerStore;

  constructor(
    routeStore: RouteStore,
    appUtilStore: AppUtilStore,
  ) {
    super(appUtilStore);
    this.routeStore = routeStore;
    this.pager = new PagerStore(routeStore);

    autorun(() => {
      if (!this.selectMode) {
        this.selectedRecordIds = [];
      }
    })
  }


  @computed get sortColumn(): string {
    return this.sort.sortColumn;
  }

  @computed get sortDirection(): SortTypes {
    return this.sort.sortDirection;
  }

  hasIdOfSelected(id: any): boolean {
    return _.includes(this.selectedRecordIds, id)
  }

  toggleSelectionId(id: any): void {
    if (this.hasIdOfSelected(id)) {
      this.deselectId(id);
    } else {
      this.selectId(id);
    }
  }

  selectId(id: any): void {
    this.selectedRecordIds = _.uniq(this.selectedRecordIds.concat([id]));
  }

  deselectId(id: any): void {
    this.selectedRecordIds = _.without(this.selectedRecordIds, id);
  }

  selectAll(): void {
    this.selectedRecordIds = _.uniq(this.selectedRecordIds.concat(this.records.map(entity => entity.id)));
  }

  @computed get sort(): { sortColumn: string, sortDirection: SortTypes } {
    const sortParam = this.routeStore.query.sort;

    if (sortParam) {
      if (sortParam.indexOf('-') == 0) {
        return {sortDirection: 'DESC', sortColumn: sortParam.substr(1)};
      } else {
        return {sortDirection: 'ASC', sortColumn: sortParam};
      }
    } else {
      return {sortDirection: 'NONE', sortColumn: undefined};
    }
  }

  retrieve() {
    this.visible = false;
    this.appUtilStore.dimmerActive = true;

    return super.retrieve()
      .then((records) => {
        this.visible = true;
        this.pager.contentRange = records.headers['content-range'];

        this.appUtilStore.dimmerActive = false;

        const title = SPECIFIC_TITLE[`${this.entityPluralLabel}?${this.routeStore.query.scope}`];

        this.appUtilStore.title = title ||
          this.currentSchema.title + "マスタ";
      });

  }

  get entityPluralLabel(): string {
    return this.routeStore.match.params.entity;
  }

  get recordQuery(): string {
    return window.location.search;
  }

  getSpecificLocation() {
    if (SPECIFIC_DEFINITION[`${this.entityPluralLabel}?${this.routeStore.query.scope}`]) {
      return `${this.entityPluralLabel}?${this.routeStore.query.scope}`;
    }
    return super.getSpecificLocation();
  }

  @action.bound
  onClearFilters() {
    this.filters = undefined;
  }

  additionalOnGridRowsUpdated(e: GridRowsUpdatedEvent): any {
    const additional: any = super.additionalOnGridRowsUpdated(e);
    if (this.routeStore.match.params.entity === 'plans' && e.cellKey === 'placeId') {
      const newValue = e.updated[e.cellKey];
      const place: IPlace = _.find(this.schemaSuggestionsOriginal['placeId'], (place: IPlace) => {
        return place.id === newValue;
      });

      additional['priceRatio'] = place.priceRatio;
    }
    return additional
  }

  virtualOnGridSort(sortColumn, sortDirection) {

    const pageUrl = `/entity/${this.routeStore.match.params.entity}`;
    const currentQuery = this.routeStore.query;

    let query = _.reduce(currentQuery, (result, value, key) => {
      const property = this.currentSchema.properties[key];


      if (property || key === 'scope' /* for system property */) {
        result += '&';
        result += key + '=' + value;
      }
      return result;
    }, '?');

    if (sortDirection !== 'NONE') {
      query += '&sort=' + (sortDirection === 'DESC' ? '-' : '') + sortColumn;
    }
    this.routeStore.history.push(pageUrl + query);
  }

  searchWithFilter() {
    let pageUrl = `/entity/${this.routeStore.match.params.entity}`;
    const currentQuery = this.routeStore.query;

    let columnsByKey = _.keyBy(this.columns, 'key');

    console.log("searchWithFilter", this.filters)

    let query = _.reduce(this.filters, (result, value, key) => {

      let property = this.currentSchema.properties[key];

      if (property) {
        let column = columnsByKey[key];
        result += '&';
        if (column.filterFormat) {
          result += column.filterFormat(key, value);

        } else if (property.type === 'string' && !property.format) {
          result += key + '$like=' + encodeURIComponent('%' + value + '%');
        } else {
          result += key + '=' + value;
        }
      }
      return result;
    }, '?');

    if (currentQuery.scope) {
      query += '&scope=' + currentQuery.scope;
    }
    if (currentQuery.sort) {
      query += '&sort=' + currentQuery.sort;
    }
    this.routeStore.history.push(pageUrl + query);
  }

  autoCompleteFilterValues(rowData, colFilter, columnKey) {
    const rowValue = rowData[columnKey];
    if (rowValue) {
      return _.includes('' + rowValue, '' + colFilter.filterTerm)
    }
    return false;
  }

  @action.bound
  onAddFilter(filter) {

    let newFilters = Object.assign({}, this.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
      if (DEFAULT_REFERENCES_DEFINITION[filter.column.key]) {
        filter.filterValues = this.autoCompleteFilterValues;
      }
    } else {
      delete newFilters[filter.column.key];
    }
    this.filters = newFilters;
  }

  get readOnly() {
    return !!this.specificDefinition.readOnly;
  }

  get fixedColumnCount() {
    return this.specificDefinition.fixedColumnCount || 2;
  }

  createActionCell() {

    console.log("resolve dependent",
      this.selectMode,
      _.head(this.selectedRecordIds)
    )
    return getActionCell(this);
  }

  @computed
  get updateOnly() {
    return !!this.specificDefinition.updateOnly;
  }
}

