import * as React from "react";
import {observer} from "mobx-react";
import {RouteStore} from "../providers/RouteProvider";
import {AppUtilStore} from "../store/AppUtilStore";
import {VirtualizedDataGrid} from "./lib/VirtualizedDataGrid";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid/Grid";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import {ArtDetailStore} from "../store/ArtDetailStore";


export interface StockEditProps {
  routeStore?: RouteStore;
  appUtilStore?: AppUtilStore;
  artDetailStore?: ArtDetailStore;
}

@observer(["artDetailStore", "routeStore", "appUtilStore"])
export class ArtDetailComponent extends React.Component<StockEditProps, any> {

  bindCellKeyDownToStore(store: { updateByIndex: (number) => any }) {
    return (e: KeyboardEvent, rowIndex: number, columnIndex: number) => {
      if (e.keyCode === 13 && e.ctrlKey) {
        store.updateByIndex(rowIndex);
        return false;
      }
      return true;
    }
  }

  render() {

    const adStore = this.props.artDetailStore;
    if (!adStore.visible) {
      return null;
    }

    const acStore = this.props.artDetailStore.artCostStore;

    let toolbar: JSX.Element = null;

    const tabChange = (action) => {
      if (adStore.action !== action) {
        this.props.routeStore.history.push(`/artDetails/${adStore.artId}/${action}`)
      }
    };

    if (adStore.isCost) {
      toolbar = <div>
        <Grid>
          <Grid.Column floated='left' width={11}>

            <div style={{width: 80, display: 'inline-block'}}>
              <Input value={acStore.addRowsInputNum}
                     style={{width: "100%"}}
                     onChange={(e) => {
                       try {
                         const skip = parseInt(e.currentTarget.value, 10);
                         if (skip >= 0) {
                           acStore.addRowsInputNum = skip
                         } else {
                           acStore.addRowsInputNum = 0;
                         }
                       } catch (e) {
                         acStore.addRowsInputNum = 0;
                       }
                     }}
                     onKeyDown={(e) => {
                       let increment = acStore.addRowsInputNum;
                       if (e.keyCode === 38) {
                         increment++;
                       } else if (e.keyCode === 40) {
                         increment--;
                       }
                       if (increment < 0) {
                         increment = 0;
                       }
                       acStore.addRowsInputNum = increment;
                     }}
              />
            </div>
            <Button onClick={() => {
              acStore.addRows();
            }}>行追加</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <div style={{textAlign: "right"}}>
              <Button floated="right" onClick={() => {
                acStore.saveRows()
              }} icon="save"/>
            </div>
          </Grid.Column>
        </Grid>
      </div>;
    }

    const art = adStore.art;
    return <div>
      <Segment.Group horizontal>
        <Segment>
            <span>
            <span><h3>id:{art.id} 【{art.author ? art.author.name : '作家未設定'}】 {art.title} </h3></span>
            </span>
        </Segment>
        <Segment textAlign='right' className='button container no-border'>
          <Button onClick={() => {
            this.props.routeStore.history.push(`/entity/arts?scope=full&id=${this.props.artDetailStore.art.id}`)
          }} icon="list"/>
        </Segment>
      </Segment.Group>

      <div>
        <div className="ui top attached tabular menu">
          <a className={(adStore.isCost ? 'active' : '') + " item"}
             onClick={tabChange.bind(this, 'cost')}>作品経費
          </a>
        </div>

        <div className="ui bottom attached active tab segment" style={{display: 'block'}}>
          <div>
            {toolbar}

            {
              adStore.isCost && acStore.visible && <VirtualizedDataGrid
                updateRef={acStore.records}
                columns={acStore.columns}
                rowsCount={acStore.rowsCount}
                rowGetter={acStore.rowGetter}
                onGridRowsUpdated={acStore.onGridRowsUpdated}
                onCellKeyDown={this.bindCellKeyDownToStore(acStore)}
                onGridSort={acStore.onGridSort}
                sortColumn={acStore.sortColumn}
                sortDirection={acStore.sortDirection}
                fixedColumnCount={2}
                height={this.props.appUtilStore.height - 300}
              />
            }

          </div>
        </div>

      </div>
    </div>

  }

}
