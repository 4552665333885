import * as React from "react";
import * as _ from "lodash";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {AppUtilStore} from "../store/AppUtilStore";
import {agent} from "../utils/agent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header/Header";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image/Image";


@inject('appUtilStore')
@observer
export class UserOnlyComponent extends React.Component<{ appUtilStore?: AppUtilStore }, {}> {

  componentDidMount() {
    return agent.get('/rest/auth')
      .then(() => {
        this.props.appUtilStore.isAuthenticated = true;
        return null;
      })
      .catch(() => {
        this.props.appUtilStore.isAuthenticated = false;
      })
  }

  render() {
    if (_.isUndefined(this.props.appUtilStore.isAuthenticated)) {
      return null;
    } else if (this.props.appUtilStore.isAuthenticated) {
      return <Route children={this.props.children}/>;
    } else {
      return <Redirect to="/login"/>
    }
  }
}

@inject('appUtilStore')
@observer
export class GuestOnlyComponent extends React.Component<{ appUtilStore?: AppUtilStore, history?: any }, {}> {

  componentDidMount() {
    if (this.props.appUtilStore.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  input: { [index: string]: string } = {};

  onChange(a, b, c) {
    this.input[b.name] = b.value;
  }

  onSubmit(a, b, c) {
    agent.post('/login', this.input)
      .then(() => {
        this.props.appUtilStore.isAuthenticated = true;
        this.props.history.push('/');
      })
  }

  render() {
    return <div className='login-form'>
      <style>{`
      body > div#react,
      body > div#react > div,
      body > div#react > div > div,
      body > div#react > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
      <Grid
        textAlign='center'
        style={{height: '100%'}}
        verticalAlign='middle'
      >
        <Grid.Column style={{maxWidth: 450}}>
          <Header as='h2' color='teal' textAlign='center'>
            <Image src='/images/logo.png'/>
            {' '}Log-in to your account
          </Header>
          <div className="ui large form">
            <Segment stacked>
              <div className="field">
                <Input
                  fluid
                  icon='user'
                  iconPosition='left'
                  placeholder='username'
                  name='username'
                  onChange={this.onChange.bind(this)}
                />
              </div>
              <div className="field">
                <Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  name='password'
                  onChange={this.onChange.bind(this)}
                />
              </div>

              <Button color='teal' fluid size='large' onClick={this.onSubmit.bind(this)}>Login</Button>
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  }

}

