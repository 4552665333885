import {observable} from "mobx";
import {AppUtilStore} from "../store/AppUtilStore";
import {SuperagentPromise} from "../utils/agent";
import Bluebird = require("bluebird");

export interface PlanNode {
  id: number
  name: string
  children: PlanNode[]
  contentsUrl: string
  loading: boolean
  toggled: boolean
  active: boolean
}

let agent: SuperagentPromise;

export class PlanTreeStore {

  _appUtilStore: AppUtilStore;
  get appUtilStore() {
    return this._appUtilStore;
  }

  set appUtilStore(value: AppUtilStore) {
    this._appUtilStore = value;
    agent = value.agent;
  }

  @observable.ref tree: Array<PlanNode> = [];
  @observable.ref activeNode: PlanNode;
  @observable openModal: boolean;

  refresh() {
    this.tree = [...this.tree];
  }

  retrieve() {
    let treeEndpoint = '/rest/plans/tree';
    return Bluebird.all([agent.get(treeEndpoint)])
      .then(([treeResponse]) => {
        console.log(treeResponse)
        this.tree = treeResponse.body;
      })
      .catch((data) => {
        console.log("catch", data)
      })
      .finally(() => {
      })
  }

  onToggle(node: PlanNode, toggled: boolean) {
    if (node.children) {
      node.toggled = toggled;
      if (node.loading) {
        this.retrieveChildren(node);
      }
    } else {
      node.active = !node.active;

      if (this.activeNode != node) {
        if (this.activeNode) {
          this.activeNode.active = false;
        }
        this.activeNode = node;
      } else if (!node.active) {
        this.activeNode = undefined;
      }

    }
    this.refresh();
  }


  retrieveChildren(node: PlanNode) {

    Bluebird.all([agent.get(node.contentsUrl)])
      .then(([treeResponse]) => {
        console.log(treeResponse)
        node.children = treeResponse.body;
        node.loading = false;
        this.refresh();
      })
      .catch((data) => {
        console.log("catch", data)
      })
      .finally(() => {
      })
  }


}

