import * as React from "react";
import * as _ from "lodash";
import {DataGridView} from "../dataGrid/DataGridView";
import {DateUtils} from "../../../utils/dateUtils";
import {IPlan} from "../../../main/models/Plan";
import {columnGetter, orderColumns} from "../../utils/dataGrid";
import {JsonSchema, PropertySchema} from "../../../defines/jsonSchema";
import {ColumnProps} from "../lib/VirtualizedDataGridDefinition";
import {IPlanArt} from "../../../main/models/PlanArt";
import currentConsumption = DateUtils.currentConsumption;


const columnsCustomize = {
  "no": {
    width: 30,
    name: 'No',
    style: ({value}) => {
      return {
        textAlign: "right"
      }
    },
    separate: true
  },
  "artId": {
    width: 50,
    style: ({value}) => {
      return {
        textAlign: "right"
      }
    },
    separate: true
  },
  "showPrice": {
    width: 65,
    valueFormatter: ({value}) => {
      return _.isNumber(value) && !_.isNaN(value) ? value.toLocaleString() : '';
    },
    style: ({value}) => {
      return {
        textAlign: "right"
      }
    }
  },
  "showPrice_withTax": {
    width: 65,
    style: ({value}) => {
      return {
        textAlign: "right"
      }
    }
  },
  "art.listPrice": {
    width: 65,
    valueFormatter: ({value}) => {
      return _.isNumber(value) && !_.isNaN(value) ? value.toLocaleString() : '';
    },
    style: ({value}) => {
      return {
        textAlign: "right"
      }
    }
  },
  "art.author.name": {
    width: 0,
    style: ({value}) => {
      return {}
    },
    autoSize: true
  },
  "art.title": {
    width: 100,
    style: ({value}) => {
      return {}
    },
    autoSize: true
  },
  "art.artSign.name": {
    width: 120,
    style: ({value}) => {
      return {}
    },
    autoSize: true
  },
  'art.technique': {
    width: 60,
  },
  'art.edition': {width: 60},
  'art.note': {
    width: 100,
    autoSize: true,
    valueFormatter: ({dependentValue, value}) => {
      return _.chain([
        'art.since',
        'art.artSign.name',
        'art.reasonedNo',
        'art.note']).map((key) => {
        return columnGetter(dependentValue, key)
      }).filter((v) => {
        return !_.isEmpty(v)
      }).join('　').value();
    }
  }
};

const printVisibleField = [
  'no',
  'artId',
  'art.author.name',
  'art.title',
  'art.edition',
  'art.technique',
  'art.listPrice',
  'showPrice',
  'showPrice_withTax',
  'art.note'
];

const ColumnNameMap = {
  'createdAt': '作成日時',
  'updatedAt': '更新日時',
  'deletedAt': '削除日時'
};


const jsonSchemaToColumnProps = (options: { schema: JsonSchema, prefix?: string, target?: ColumnProps[], exclude?: string[] }): ColumnProps[] => {
  _.defaults(options, {prefix: '', target: [], exclude: []});

  return _.reduce(options.schema.properties, (r, v: PropertySchema, key: string) => {
    let fullKey = options.prefix + key;

    if (!_.includes(options.exclude, key) && !_.includes(options.exclude, fullKey)) {
      let column: ColumnProps = {} as any;
      column.key = fullKey;
      column.name = ColumnNameMap[key] ? ColumnNameMap [key] : v.title;
      column.sortable = true;
      column.width = 150;
      column.required = _.includes(options.schema.required, key);

      r.push(column);
    }
    return r;
  }, options.target);
};


const printColumns = (plan: IPlan, options:any)=>{

  const propertiesArray = jsonSchemaToColumnProps({
    schema: options.artSchema,
    prefix: 'art.',
    target: jsonSchemaToColumnProps(
      {
        schema: options.planArtsSchema
      }
    )
  });
  const consumption = currentConsumption(plan.startDate);

  propertiesArray.push({key: "no", name: 'No'});
  propertiesArray.push({key: "art.author.name", name: '作家名'});
  propertiesArray.push({
    key: "showPrice_withTax", name: '出展価格<br/>（税込）',
    valueFormatter: ({dependentValue}) => {
      let value = columnGetter(dependentValue, 'showPrice');

      if (_.isNumber(value) && !_.isNaN(value)) {
        return (Math.floor(value * consumption)).toLocaleString();
      } else {
        return '';
      }
    }
  });

  const visibleColumns = _.filter(propertiesArray, (property) => {
    return _.includes(printVisibleField, property.key)
  });

  const customizedArray = _.map(visibleColumns, (column) => {
    return _.defaults({}, columnsCustomize[column.key], column);
  });

  return orderColumns(customizedArray, printVisibleField);

}

const ArtLabelPage = (plan: IPlan, addDate: string, planArts: IPlanArt[], options: any) => {

  return <DataGridView
    columns={printColumns(plan, options)}
    rowsCount={planArts.length}
    rowGetter={(i)=>{return planArts[i]}}
    showHeaderEachPage={true}
    firstPageRowCount={25}
    pageRowCount={25}
    rowMaxHeight={22}
    maxWidth={960}
    tableFontSize={11}
    header={<div className="header">
      <div className="header-section">
        <div className="title-section">
          <div className="subTitle">{plan.subTitle} </div>
          <div>
            <span className="mainTitle">{plan.title}</span><span>{addDate && `　<${DateUtils.format(addDate, 'YYYY年MM月DD日')}追加分> `}</span>
          </div>
        </div>
        <div className="summary-section">
          <h2>出品リスト</h2>
        </div>
        <div className="desc-section">
          <div className="fixed-text">{plan.place && plan.place.company && plan.place.company.name} </div>
          <div className="fixed-text">{plan.place && plan.place.name}{plan.detail && `　${plan.detail}`}</div>
          <div className="fixed-text">{DateUtils.format(plan.startDate, 'YYYY年MM月DD日(ddd)')}〜{DateUtils.format(plan.endDate, 'YYYY年MM月DD日(ddd)')}</div>
        </div>
      </div>
    </div>
    }
  />

};

export const ArtListPDFView = (plan: IPlan, addDate: string, planArts: IPlanArt[], options: any, preview: boolean = true) => {

  return <div className={`artListPDFViewDocument ${preview ? 'artListPDFViewDocument--preview' : ''}`}>
    { ArtLabelPage(plan, addDate, planArts, options) }
  </div>

};
