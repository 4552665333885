import * as React from "react";
import * as _ from "lodash";
import {inject, observer} from "mobx-react";
import {RouteStore} from "../../providers/RouteProvider";
import {AppUtilStore} from "../../store/AppUtilStore";
import {ArtStockStore} from "../../store/reports/ArtStockStore";
import {IArt} from "../../../main/models/Art";
import * as moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

export const ArtStockListPDFPage = (rows: Element[]) => {

  return <table>
    <thead>
    <tr>
      <th className='id'></th>
      <th className='title'></th>
      <th className='technique'></th>
      <th className='edition'></th>
      <th className='cost '></th>
    </tr>
    </thead>
    <tbody>
    {
      rows
    }
    </tbody>
  </table>

}

export const ArtStockListPDF = (baseDate: Date, artStocks: IArt[], preview: boolean = true) => {
  const outputDate = moment().tz("Asia/Tokyo").format('YYYY年MM月DD日 hh:mm:ss');

  const data = _.reduce(artStocks, (a, artStock, key) => {

    a.costSum += artStock.cost;
    if(!a.authors[artStock.authorId]) {

      a.rows.push(<tr className={'authorRow'} key={`authorRow=${artStock.authorId}`}>
        <td colSpan={5} >{artStock.authorId ? artStock.author.name : '作家未設定'}</td>
      </tr>);
      a.authors[artStock.authorId] = artStock.author;
    }
    a.rows.push(<tr className='artRow' key={`art=${artStock.id}`}>
      <td className='id'>{artStock.id}</td>
      <td className='title'>
          {artStock.title}
      </td>
      <td className='technique'>{artStock.technique}</td>
      <td className='edition'>{artStock.edition}</td>
      <td className='cost '>{artStock.cost ? artStock.cost.toLocaleString() : ''}</td>
    </tr>);

    return a;
  }, {
    rows:[],
    authors: {},
    costSum: 0,
  });

  const headRowSize = 40;
  const headArtStockRows = _.take(data.rows, headRowSize);
  const tailChunkedArtStock = _.chunk(_.slice(data.rows, headRowSize), 42);

  return <div className={`artStockListPDFViewDocument ${preview ? 'artStockListPDFViewDocument--preview' : ''}`}>
    {
      <div className='sheet' key={`sheet-1`}>
        <div className="header">
            <div className="title-section">在庫リスト</div>
            <div className="summary-section">
              <span>原価合計 {data.costSum.toLocaleString()}</span>
              <span>　</span>
              <span>作品点数 {artStocks.length.toLocaleString()}</span>
            </div>
            <div className="desc-section">{moment(baseDate).format('YYYY年MM月DD日時点')}</div>
        </div>
        {ArtStockListPDFPage(headArtStockRows)}
        <span className='outputDate'>{outputDate} / {data.costSum}</span>
        <span className="pager">ページ 1/{tailChunkedArtStock.length + 1}</span>
      </div>
    }
    {
      tailChunkedArtStock.map((rows, index) => {
        return <div className='sheet' key={`sheet-${index}`}>
          {ArtStockListPDFPage(rows)}
          <span className='outputDate'>{outputDate}</span>
          <span className="pager">ページ {index + 2}/{tailChunkedArtStock.length + 1}</span>
        </div>
      })
    }
  </div>
};

export interface ArtStockComponentProps {
  routeStore?: RouteStore;
  appUtilStore?: AppUtilStore;
  artStockStore?: ArtStockStore;
}

@inject("routeStore", 'appUtilStore', 'artStockStore')
@observer
export class ArtStockComponent extends React.Component<ArtStockComponentProps, {}> {
  render() {


    return <div>

      <Grid>

        <Grid.Column floated='right' width={8}>
          <div style={{textAlign: "right"}}>
            <span>基準日: </span>

            <DatePicker
              className="form-control"
              dateFormat='yyyy/MM/dd'
              selected={this.props.artStockStore.baseDate}
              onChange={this.props.artStockStore.changeBaseDateDatePicker}
              shouldCloseOnSelect={false}
            />
            <Button onClick={this.props.artStockStore.refresh} icon="refresh"/>

            <Button onClick={() => {
              const baseDate = moment(this.props.artStockStore.baseDate).format('YYYY-MM-DD');
              window.open(`/api/pdf/stockReport?baseDate=${baseDate}`);
            }} icon="print"/>
          </div>
        </Grid.Column>
      </Grid>
      {
        this.props.artStockStore.visible && <div style={
          {
            height: this.props.appUtilStore.height - 128,
            overflowY: 'scroll',
          }
        } className="react-pdf A4" ref={(ref) => {
        }}>
          {ArtStockListPDF(this.props.artStockStore.baseDate, this.props.artStockStore.artStocks, true)}
        </div>
      }
    </div>
  }

}