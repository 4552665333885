import * as React from "react";

import {Provider} from "mobx-react";

import {EntityComponent} from "../components/EntityComponent";
import {EntityStore} from "../store/EntityStore";
import {RouteProvider} from "./RouteProvider";

export class EntityProvider extends React.Component<{}, {}> {

  entityStore: EntityStore;

  constructor(props) {
    super(props);
    this.entityStore = new EntityStore();
    console.log("EntityProvider:constructor", props);
  }

  render() {
    return <RouteProvider {...this.props}>
      <Provider entityStore={this.entityStore}>
        <EntityComponent />
      </Provider>
    </RouteProvider>
  }
}
