import * as React from "react";
import * as _ from "lodash";
import {IArt} from "../../../main/models/Art";

export interface IArtLabelPrint extends IArt {
  dummy?: boolean;
  qrImageUrl: string;
}

const ArtLabel = (art: IArtLabelPrint, i:number) => {

  return <div className={'artLabel'} key={'art' + i}>
    <div className={'artLabel__id'}>{art.id}</div>
    <div className={'artLabel__authorName'}>{art.author && art.author.name}</div>
    <div className={'artLabel__title'}>{art.title}</div>
    <div className={'artLabel__qr'}>
      <img className='artLabel__qr__img' src={`${art.qrImageUrl}`}
           width="100%"
           height="100%"/>
    </div>
    <div className={'artLabel__details'}>{art.technique}<br/>{art.edition}<br/>{art.since} {art.artSign && art.artSign.name} {art.reasonedNo}</div>
    <div className={'artLabel__note'}>{art.note}</div>
  </div>
};

const ArtLabelPage = (repeatArts: IArtLabelPrint[]) => {

  return _.chunk(repeatArts, 2 * 8).map((arts, i) => {

    return <div className="artLabelPDFViewPage" key={'page' + i}>
      {
        arts.map((art, i) => {
          if (art.dummy) {
            return <div className='artLabel' key={'dummy' + i}/>
          } else {
            return ArtLabel(art, i)
          }
        })
      }
      <div style={{clear: "both"}}/>
    </div>
  })

};

export const ArtLabelPDFView = (arts: IArt[], skip: number, copy: number, preview: boolean = true) => {

  const dummy = _.range(skip).map<IArtLabelPrint>((r) => {
    return {id: undefined, dummy: true, title: 'skip', author: {name: 'skip'}, qrImageUrl: ''}
  });

  const repeatArts = _(arts).map((art: IArtLabelPrint)=>{
    if(typeof window !== 'undefined'){
      art.qrImageUrl = `${window.location.origin}/api/qr?data=art${art.id}&size=10`
    }
    return _.range(copy).map(()=>{
      return art;
    })
  }).flatten().value();

  return <div className={`artLabelPDFViewDocument ${preview ? 'artLabelPDFViewDocument--preview' : ''}`}>
    { ArtLabelPage(dummy.concat(Array.from(repeatArts))) }
  </div>

};
