import {Treebeard} from "react-treebeard";
import {computed, observable} from "mobx";
import {agent} from "../utils/agent";
import {IPlan} from "../../main/models/Plan";
import {PlanTreeStore} from "./PlanTreeStore";
import Bluebird = require("bluebird");


export class ManagePlanStore {

  @observable.ref visible: boolean;
  @observable.ref plan: IPlan;
  @observable action: string;
  planTreeStore: PlanTreeStore;

  refresh: Function;

  constructor() {
    this.planTreeStore = new PlanTreeStore();
  }

  retrieve(planId: number) {
    return Bluebird.all(
      [
        this.planTreeStore.retrieve(),
        agent.get(`/rest/plans/${planId}`)
          .then((plan) => {
            this.plan = plan.body;
          })
      ]);
  }

  @computed
  get isInit(): boolean {
    return this.action === ':action';
  }

  @computed
  get isNew(): boolean {
    return this.action === 'new';
  }

  @computed
  get isEditing(): boolean {
    return this.action === 'edit';
  }

  @computed
  get isSales(): boolean {
    return this.action === 'sales';
  }

  @computed
  get isCost(): boolean {
    return this.action === 'cost';
  }

  @computed
  get isReport(): boolean {
    return this.action === 'report';
  }
}
