import * as React from "react";

import * as _ from "lodash";
import {IPlanArt} from "../../../main/models/PlanArt";
import {DateUtils} from "../../../utils/dateUtils";
import {constants} from "../../../defines/constants";
import {IPlanCost} from "../../../main/models/PlanCost";
import {IPlan} from "../../../main/models/Plan";
import * as moment from "moment-timezone";
import PLAN_COST_TYPES = constants.PLAN_COST_TYPES;
import getPlanCostLabel = constants.getPlanCostLabel;


const planReportSummary = (plan: IPlan) => {
  return <div>
    <table className='summary'>
      <thead>
      <tr>
        <th>売上</th>
        <th>下代</th>
        <th>&nbsp;</th>
        <th>仕入</th>
        <th>&nbsp;</th>
        <th>経費</th>
        <th>&nbsp;</th>
        <th>手数料</th>
        <th>&nbsp;</th>
        <th>利益</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{plan.planArts.length}</td>
        <td>{plan.sumOfDepositPrice.toLocaleString()}</td>
        <td>-</td>
        <td>{plan.sumOfSalesArtCost.toLocaleString()}</td>
        <td>-</td>
        <td>{plan.sumOfCostPrice.toLocaleString()}</td>
        <td>-</td>
        <td>{plan.sumOfChargePrice.toLocaleString()}</td>
        <td>=</td>
        <td>{(plan.sumOfDepositPrice - plan.sumOfSalesArtCost - plan.sumOfCostPrice - plan.sumOfChargePrice).toLocaleString()}</td>
      </tr>
      </tbody>
    </table>
  </div>
};

const planReportSubTotal = (plan: IPlan,
                            planArtsGroupByStaffName: _.Dictionary<IPlanArt[]>,
                            planCostsGroupByStaffName: _.Dictionary<IPlanCost[]>) => {

  const staffGroupedPlanData = _(planArtsGroupByStaffName)
    .keys()
    .concat(_.keys(planCostsGroupByStaffName))
    .uniq()
    .mapValues((key) => {
      return {
        "key": key,
        "planArts": planArtsGroupByStaffName[key],
        "planCosts": planCostsGroupByStaffName[key]
      }
    })
    .orderBy('key' as any) // FIXME: type error
    .mapKeys('key')
    .value();


  return <table>
    <thead>
    <tr>
      <th>販売スタッフ</th>
      <th>販売価格</th>
      <th>下代</th>
      <th>手数料</th>
      <th>仕入値</th>
      <th>{getPlanCostLabel('cache')}</th>
      <th>{getPlanCostLabel('card')}</th>
      <th>経費計</th>
    </tr>
    </thead>
    {
      _.map(staffGroupedPlanData, (planData, key) => {

        const {planArts = [], planCosts = []} = planData;

        return <tbody key={'tbody-detail' + key}>
        <tr>
          <td>{key}</td>
          <td className='currency'>{planArts.reduce((sum, planArt) => {
            return planArt.artResult.soldPrice + sum
          }, 0).toLocaleString()}</td>
          <td className='currency'>{planArts.reduce((sum, planArt) => {
            return planArt.artResult.depositPrice + sum
          }, 0).toLocaleString()}</td>
          <td className='currency'>{planArts.reduce((sum, planArt) => {
            return planArt.artResult.chargePrice + sum
          }, 0).toLocaleString()}</td>
          <td className='currency'>{planArts.reduce((sum, planArt) => {
            return planArt.artResult.art.cost + sum
          }, 0).toLocaleString()}</td>
          <td className='currency'>
            {
              planCosts.filter((planCost) => {
                return planCost.type === 'cache';
              }).reduce((sum, planCost) => {
                return sum + planCost.cost
              }, 0).toLocaleString()
            }
          </td>
          <td className='currency'>
            {
              planCosts.filter((planCost) => {
                return planCost.type === 'card';
              }).reduce((sum, planCost) => {
                return sum + planCost.cost
              }, 0).toLocaleString()
            }
          </td>
          <td className='currency'>
            {
              planCosts.reduce((sum, planCost) => {
                return sum + planCost.cost
              }, 0).toLocaleString()
            }
          </td>
        </tr>
        </tbody>
      })
    }
    <tfoot>
    <tr className='subtotal'>
      <th colSpan={1}>合計</th>
      <th className='currency'>{plan.planArts.reduce((sum, planArt) => {
        return planArt.artResult.soldPrice + sum
      }, 0).toLocaleString()}</th>
      <th className='currency'>{plan.planArts.reduce((sum, planArt) => {
        return planArt.artResult.depositPrice + sum
      }, 0).toLocaleString()}</th>
      <th className='currency'>{plan.planArts.reduce((sum, planArt) => {
        return planArt.artResult.chargePrice + sum
      }, 0).toLocaleString()}</th>
      <th className='currency'>{plan.planArts.reduce((sum, planArt) => {
        return planArt.artResult.art.cost + sum
      }, 0).toLocaleString()}</th>
      <th className='currency'>
        {
          plan.planCosts.filter((planCost) => {
            return planCost.type === 'cache';
          }).reduce((sum, planCost) => {
            return sum + planCost.cost
          }, 0).toLocaleString()
        }
      </th>
      <th className='currency'>
        {
          plan.planCosts.filter((planCost) => {
            return planCost.type === 'card';
          }).reduce((sum, planCost) => {
            return sum + planCost.cost
          }, 0).toLocaleString()
        }
      </th>
      <th className='currency'>
        {
          plan.planCosts.reduce((sum, planCost) => {
            return sum + planCost.cost
          }, 0).toLocaleString()
        }
      </th>
    </tr>
    </tfoot>
  </table>
};

const planSalesDetail = (plan:IPlan, planArts: IPlanArt[], isLast: boolean) => {
  return <table>
    <thead>
    <tr>
      <th>作品ID</th>
      <th>タイトル</th>
      <th>作家名</th>
      <th>ED</th>
      <th>販売価格</th>
      <th>下代</th>
      <th>手数料</th>
      <th>仕入値</th>
      <th>入金日</th>
      <th>締め日</th>
      <th>備考</th>
    </tr>
    </thead>
    {
      <tbody key={'tbody-detail'}>
      {
        planArts.map((planArt: IPlanArt, index) => {
          return <tr key={index}>
            <td>{planArt.artResult.art.id}</td>
            <td className='text'>{planArt.artResult.art.title}</td>
            <td>{planArt.artResult.art.author.name}</td>
            <td>{planArt.artResult.art.edition}</td>
            <td className='currency'>{planArt.artResult.soldPrice.toLocaleString()}</td>
            <td className='currency'>{planArt.artResult.depositPrice.toLocaleString()}</td>
            <td className='currency'>{planArt.artResult.chargePrice.toLocaleString()}</td>
            <td className='currency'>{planArt.artResult.art.cost.toLocaleString()}</td>
            <td>{"　"/*  空セル */}</td>
            <td>{"　"/*  空セル */}</td>
            <td className=''>{planArt.artResult.note}</td>
          </tr>
        })
      }
      </tbody>
    }
    {
      isLast && planSalesSummary(plan)
    }
  </table>

};


const planSalesSummary = (plan: IPlan) => {
  return <tfoot>
    <tr>
      <th colSpan={4}>合計</th>
      <th className='currency'>{plan.planArts.reduce((sum, planArt) => {
        return planArt.artResult.soldPrice + sum
      }, 0).toLocaleString()}</th>
      <th className='currency'>{plan.planArts.reduce((sum, planArt) => {
        return planArt.artResult.depositPrice + sum
      }, 0).toLocaleString()}</th>
      <th className='currency'>{plan.planArts.reduce((sum, planArt) => {
        return planArt.artResult.chargePrice + sum
      }, 0).toLocaleString()}</th>
      <th className='currency'>{plan.planArts.reduce((sum, planArt) => {
        return planArt.artResult.art.cost + sum
      }, 0).toLocaleString()}</th>
      <td>{"　"/*  空セル */}</td>
      <td>{"　"/*  空セル */}</td>
      <td>{"　"/*  空セル */}</td>
    </tr>
    </tfoot>

  };

const planCostsDetail = (plan: IPlan,
  records: any[], isLast: Boolean) => {
  return <table>
    <thead>
    <tr>
      <th>勘定科目</th>
      <th>詳細</th>
      <th>日付</th>
      <th>種類</th>
      <th>金額</th>
      <th>備考</th>
    </tr>
    </thead>
    <tbody>
      {records}
    </tbody>
    {
      isLast && planCostsSummary(plan)
    }
  </table>
};


const planCostsSummary = (plan: IPlan) => {
  return <tfoot>
    <tr>
      <th colSpan={4}>合計</th>
      <th className='currency'>{plan.planCosts.reduce((sum, planCost) => {
        return planCost.cost + sum;
      }, 0).toLocaleString()}</th>
      <td>{"　"/*  空セル */}</td>
    </tr>
    </tfoot>
};


const reportHeader = (plan: IPlan) => {
  return <div style={{marginBottom: "1cm"}}>
    <div className='title'>展示会 売上・経費明細書</div>
    <div>
      <div>イベント名: {plan.title}</div>
      <div>会期: {DateUtils.format(plan.startDate, 'YYYY/MM/DD')} 〜 {DateUtils.format(plan.endDate, 'YYYY/MM/DD')}</div>
      <div>会場: {plan.place && plan.place.name} {plan.detail}</div>
      <div>掛け率: {plan.priceRatio}</div>
    </div>
  </div>
}

export const PlanReportPDF = (plan: IPlan, preview: boolean = true) => {

  const containerClassName = 'planReportPDFViewDocument ' + (preview ? 'planReportPDFViewDocument--preview' : '');

  const planArtsGroupByStaffName = _.groupBy(plan.planArts, (planArt) => {
    return planArt.artResult.soldStaff && planArt.artResult.soldStaff.name || '未設定';
  });

  const planCostsGroupByStaffName = _.groupBy(plan.planCosts, (planCost) => {
    return planCost.staff && planCost.staff.name || '未設定';
  });

  const outputDate = moment().tz("Asia/Tokyo").format('YYYY年MM月DD日 hh:mm:ss');

  const chunkedPlanArts = _(plan.planArts).orderBy([
      (a) => {
        return a.artResult.art.id
      },
      (a) => {
        return a.artResult.art.author.authorKind
      },
      (a) => {
        return a.artResult.art.author.furigana
      }
    ]
  ).chunk(16).value();

  const sheets = [];

  sheets.push([
    planReportSummary(plan),
    <div style={{marginTop: "3cm"}}>販売サマリ</div>,
    planReportSubTotal(plan, planArtsGroupByStaffName, planCostsGroupByStaffName)
  ]);

  _(chunkedPlanArts).reduce((sheets, planArts, key)=>{
    sheets.push([
      <div>販売明細{_.size(chunkedPlanArts) > 1 && ` ${key + 1}/${_.size(chunkedPlanArts)}`}</div>,
      planSalesDetail(plan, planArts, _.size(chunkedPlanArts) === (key + 1)),
    ]);
    return sheets;
  }, sheets);


  const staffCostRecords = _(planCostsGroupByStaffName).reduce((a, planCosts: IPlanCost[], key) => {

    planCosts.reduce((a, planCost, index) => {
      a.push(<tr key={key + index}>
        <td>{planCost.accountItem.name}</td>
        <td className='longCell'>{planCost.details}</td>
        <td>{DateUtils.format(planCost.receiptDate)}</td>
        <td>{
          PLAN_COST_TYPES.find((type) => {
            return type.value === planCost.type
          }).label
        }</td>
        <td className='currency'>{planCost.cost.toLocaleString()}</td>
        <td className='longCell'>{planCost.note}</td>
      </tr>);
      return a;
    }, a);

    a.push(
      <tr className='subtotal' key={'subtotal' + key}>
        <td>{"　"/*  空セル */}</td>
        <th>スタッフ</th>
        <td>{key}</td>
        <th>小計</th>
        <td className='currency'>{planCosts.reduce((sum, planCost) => {
          return planCost.cost + sum
        }, 0).toLocaleString()}</td>
        <td>{"　"/*  空セル */}</td>
      </tr>
    )

    return a;
  }, []);

  const chunkedStaffCostRecords = _.chunk(staffCostRecords, 16);

  _(chunkedStaffCostRecords).reduce((sheets, planCosts, key)=>{
    sheets.push([
      <div>経費明細{_.size(chunkedStaffCostRecords) > 1 && ` ${key + 1}/${_.size(chunkedStaffCostRecords)}`}</div>,
      planCostsDetail(plan, planCosts, _.size(chunkedStaffCostRecords) === (key + 1)),
    ]);
    return sheets;
  }, sheets);

  return <div className={containerClassName}>
    {
      sheets.map((sheet, index)=>{

        return <div className='sheet' key={index}>
          {reportHeader(plan)}
          {sheet}
          <span className="outputDate">{outputDate}</span>
          <span className="pager">ページ {index + 1}/{sheets.length}</span>
        </div>
      })
    }
  </div>
}
