import * as React from "react";

import * as _ from "lodash";
import {EditorBase, HandleKeyWrapper} from "./EditorBase";
import {EditableFormatter, FormatterBase} from "./FormatterBase";
import {ColumnProps} from "../lib/VirtualizedDataGridDefinition";
import {StringUtils} from "../../../utils/string";
import Bluebird = require("bluebird");

export class NumberCellFormatter extends FormatterBase<any, any> {

  addFigure(value) {
    return _.isNumber(value) && !_.isNaN(value) ? value.toLocaleString() : '';
  }

  render() {
    return <EditableFormatter {...this.props} right value={this.addFigure(_.parseInt(this.props.value))}/>
  }
}

export interface NumberCellEditorProps {
  value?: any
  column?: ColumnProps
  onCommit?: Function
  onBlur?: any
}

export class NumberCellEditor extends EditorBase<NumberCellEditorProps, {}> {

  getValue(): any {
    let updated = {};
    let value = this.getInputNode().value;

    updated[this.props.column.key] = _.isEmpty(value) ?
      '' :
      _.toInteger(StringUtils.toHalfNumber(value));
    return updated;
  }

  render() {
    return <HandleKeyWrapper>
      <input
        ref={(node) => this.input = node}
        type="text"
        onBlur={this.props.onBlur}
        className="form-control"
        defaultValue={this.props.value}/>
    </HandleKeyWrapper>
  }
}
