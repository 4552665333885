import * as React from "react";
import * as _ from "lodash";
import * as moment from "moment";
import {ColumnProps} from "../lib/VirtualizedDataGridDefinition";
// import {StringUtils} from "../../../utils/string";
import Bluebird = require("bluebird");
import {columnGetter} from "../../utils/dataGrid";
import {StringUtils} from "../../../utils/string";

export interface DataGridViewColumnProps extends ColumnProps {
  style?: (args: { dependentValue?: any, value?: any }) => string
  autoSize?: boolean;
  fontSize?: number;
  ignoreAutoScale?: boolean;
  separate?: boolean
}

export interface DataGridViewProps {
  columns?: DataGridViewColumnProps[];
  rowsCount?: number;
  rowGetter?: (i: number) => any & { get?: (key: string) => any };
  exclude?: string[];
  header?: JSX.Element;
  rowMaxHeight?: number;
  tableFontSize?: number;
  showHeaderEachPage?: boolean;
  firstPageRowCount?: number;
  pageRowCount?: number;
  maxWidth?: number;
}


export class DataGridView<S> extends React.Component<DataGridViewProps, S> {

  tableRender(rowIndexes: number[]): JSX.Element {
    const MAX_WIDTH = this.props.maxWidth | 960;

    let autoWidthColumn: Array<DataGridViewColumnProps> = [];
    let sum = _.reduce(this.props.columns, (res, column) => {
      if (column.autoSize) {
        autoWidthColumn.push(column);
      }
      res += column.width;
      return res;
    }, 0);
    if (autoWidthColumn.length > 0) {
      let additionalWidth = (MAX_WIDTH - sum) / autoWidthColumn.length;

      _.each(autoWidthColumn, (column) => {
        column.width += additionalWidth;
      })

    }

    return <table>
      <thead>
      <tr>
        {
          this.props.columns.map((c) => {

            let style: any = {};
            if (c.width) {
              style.width = c.width;
            } else {
              c.width = 40;
            }
            let className = '';
            if (c.separate) {
              className = 'separate';
            }

            return !_.includes(this.props.exclude, c.key) &&
              <th style={style} key={c.key} className={className} dangerouslySetInnerHTML={{__html: c.name}}></th>
          })
        }
      </tr>
      </thead>
      <tbody>
      {
        rowIndexes.map((i) => {

          const row = this.props.rowGetter(i % this.props.rowsCount);
          return <tr key={i}>

            {
              this.props.columns.map((c: DataGridViewColumnProps) => {
                let value = columnGetter(row, c.key);
                if (c.valueFormatter) {
                  value = c.valueFormatter({dependentValue: row, value, index: i});
                } else {
                  value = columnGetter(row, c.key);
                }
                let style;
                if (c.style) {
                  style = c.style({value});
                }

                let className = '';
                let wrapStyle: any = {
                  overflow: 'hidden'
                };

                if(this.props.rowMaxHeight) {
                  wrapStyle.maxHeight = `${this.props.rowMaxHeight}px`;
                }

                let tdClassName = '';
                if (c.separate) {
                  tdClassName = 'separate';
                }

                if (c.ignoreAutoScale !== true) {
                  const baseFontSize = c.fontSize || this.props.tableFontSize || 10;
                  const strWidth = StringUtils.strWidth(value) * baseFontSize / 2;

                  if (strWidth >= Math.floor(c.width) * 2) {
                    wrapStyle.fontSize = Math.floor(baseFontSize / 3 * 2);
                    wrapStyle.maxHeight = wrapStyle.fontSize * 3;
                  }
                }

                return !_.includes(this.props.exclude, c.key) && <td key={c.key} className={tdClassName}>
                    <div className={className} style={wrapStyle}>
                      <div style={style}>{value}</div>
                    </div>
                  </td>
              })
            }
          </tr>
        })
      }
      </tbody>
    </table>
  }

  render() {
    let range = _.range(this.props.rowsCount * 1); // HACK: 複数ページ表示を試す時に増やす
    let firstRows = range.slice(0, this.props.firstPageRowCount);
    let nextRowsList: number[][] = [];
    if (this.props.pageRowCount) {
      nextRowsList = _.chunk(range.slice(this.props.firstPageRowCount), this.props.pageRowCount);
    }
    let maxPage = _.size(nextRowsList) + 1;

    return <div>
      <div>
        <div className="sheet">
          {this.props.header}
          {this.tableRender(firstRows)}
          <span className="outputDate">{moment().format('YYYY年MM月DD日')}</span>
          <span className="pager">ページ 1/{maxPage}</span>
        </div>
        {
          nextRowsList.length > 0 && nextRowsList.map((nextRows, i) => {
            return <div className="sheet" key={i}>
              {this.props.showHeaderEachPage ? this.props.header : null}
              {this.tableRender(nextRows)}
              <span className="outputDate">{moment().format('YYYY年MM月DD日')}</span>
              <span className="pager">ページ {i + 2}/{maxPage}</span>
            </div>
          })
        }
      </div>
    </div>

  }

}
