import * as React from "react";
import {Link, NavLink} from "react-router-dom";
import {matchPath} from "react-router";

import {observer} from "mobx-react";
import {AppUtilStore} from "../store/AppUtilStore";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu/Menu";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";


@observer(['appUtilStore'])
export class Nav extends React.Component<{ appUtilStore: AppUtilStore } & any, {}> {

  renderLink(path, label, options: any = {}) {
    return <NavLink exact className="item" to={path} activeClassName="">{label}</NavLink>
  }

  renderDropDown(path, label, options: any = {}) {
    return <NavLink exact className="item" to={path}>{label}</NavLink>
  }

  renderDropDownItem(url, label) {
    return <Dropdown.Item onClick={() => {
      this.props.history.push(url);
    }}>{label}</Dropdown.Item>
  }

  render() {

    // let isMaster = this.props.location.pathname.startsWith('/entity/');

    return <Menu fixed='top' pointing>
      {this.renderLink('/', 'トップ')}
      <Dropdown item text={'マスタ'}>
        <Dropdown.Menu>
          {this.renderDropDown('/entity/stocks?sort=-id', '仕入れ')}
          {this.renderDropDown('/entity/authors?sort=-priorityKey,-id', '作家')}
          {this.renderDropDown('/entity/places?sort=-priorityKey,-id', '開催地')}
          {this.renderDropDown('/entity/companies?sort=-priorityKey,-id', '企業')}
          {this.renderDropDown('/entity/artSigns?sort=-id', 'サイン')}
          {this.renderDropDown('/entity/accountItems?sort=-id', '勘定科目')}
          {this.renderDropDown('/entity/staffs?sort=-priorityKey,-id', 'スタッフ')}
          {this.renderDropDown('/entity/artCostItems?sort=-id', '作品経費項目')}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text="作品">
        <Dropdown.Menu>
          {this.renderDropDownItem('/entity/arts?scope=full&sort=-id', '作品リスト')}
          <Dropdown key="arts" text='作品' pointing='left' className='link item'>
            <Dropdown.Menu>
              {this.renderDropDownItem('/entity/arts?scope=full,isNotSold&sort=-id', '未売却')}
              {this.renderDropDownItem('/entity/arts?scope=full,isConsignment&sort=-id', '委託')}
              {this.renderDropDownItem('/entity/arts?scope=full,isSold&sort=-id', '売却済')}
              {this.renderDropDownItem('/entity/arts?scope=full,isNotSold,isStock&sort=-id', '在庫')}
              {this.renderDropDownItem('/entity/arts?scope=full,isNotSold,longTerm&sort=-id', '長期未出展')}
              {this.renderDropDownItem('/entity/arts?scope=full,isNotSold,isStock,longTerm&sort=-id', '長期未出展在庫')}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown key="artCosts" text='作品経費' pointing='left' className='link item'>
            <Dropdown.Menu>
              {this.renderDropDownItem('/entity/artCostReceipts?sort=-id', '作品経費伝票')}
              {this.renderDropDownItem('/entity/artCosts?scope=forRestList&sort=-id', '作品経費一覧')}
            </Dropdown.Menu>
          </Dropdown>
          {this.renderDropDownItem('/entity/plans?sort=-id', '企画リスト')}
          <Dropdown key="plans" text='企画' pointing='left' className='link item'>
            <Dropdown.Menu>
              {this.renderDropDownItem('/entity/plans?scope=scheduled&sort=-id', '予定')}
              {this.renderDropDownItem('/entity/plans?scope=ended&sort=-id', '終了')}
              {this.renderDropDownItem('/plans/:planId/arts/new', '企画出展')}
            </Dropdown.Menu>
          </Dropdown>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text="レポート">
        <Dropdown.Menu>
          {this.renderDropDownItem('/report/arts/stock', '在庫')}
          {this.renderDropDownItem('/entity/artResults?scope=forRestList&sort=-soldDate', '販売履歴')}
          {this.renderDropDownItem('/entity/planArts?scope=forRestListReadOnly', '出展リスト')}
          <Dropdown key="planArts" text='出展' pointing='left' className='link item'>
            <Dropdown.Menu>
              {this.renderDropDownItem('/entity/planArts?scope=forRestListReadOnly,leave', '取置')}
              {this.renderDropDownItem('/entity/planArts?scope=forRestListReadOnly,arrive', '現着')}
              {this.renderDropDownItem('/entity/planArts?scope=forRestListReadOnly,new', '新規')}
            </Dropdown.Menu>
          </Dropdown>
        </Dropdown.Menu>
      </Dropdown>
      <div className="ui item active">{this.props.appUtilStore.title}</div>
    </Menu>

  }

}
