import * as React from "react";
import {inject, observer, Provider} from "mobx-react";

import {Treebeard} from "react-treebeard";

import {RouteStore} from "./RouteProvider";
import {AppUtilStore} from "../store/AppUtilStore";
import {ArtDetailStore} from "../store/ArtDetailStore";
import {ArtDetailComponent} from "../components/ArtDetailComponent";


@inject("appUtilStore", "routeStore")
@observer
export class ArtDetailProvider extends React.Component<{ appUtilStore?: AppUtilStore, routeStore?: RouteStore }, {}> {

  artDetailStore: ArtDetailStore;

  constructor(props) {
    super(props);
    this.artDetailStore = new ArtDetailStore(props.routeStore, props.appUtilStore);

  }

  render() {
    return <Provider {...this}>
      <ArtDetailComponent/>
    </Provider>
  }

}
