import * as React from "react";
import {inject, observer} from "mobx-react";

import * as _ from "lodash";
import {RouteStore} from "../providers/RouteProvider";
import {AppUtilStore} from "../store/AppUtilStore";
import {VirtualizedDataGrid} from "./lib/VirtualizedDataGrid";
import {VirtualizedDataGridFilterEditor} from "./lib/VirtualizedDataGridFilterEditor";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";

import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu/Menu";
import {EntitiesStore} from "../store/EntitiesStore";

@inject("entitiesStore", "routeStore", 'appUtilStore')
@observer
export class EntitiesComponent extends React.Component<{ entitiesStore?: EntitiesStore, routeStore?: RouteStore, appUtilStore?: AppUtilStore }, {}> {

  bindCellKeyDownToStore(store: { updateByIndex: (number) => any }) {
    return (e: KeyboardEvent, rowIndex: number, columnIndex: number) => {
      if (e.keyCode === 13 && e.ctrlKey) {
        store.updateByIndex(rowIndex);
        return false;
      }
      return true;
    }
  }

  render() {
    const eStore = this.props.entitiesStore;
    return <div>
      {eStore.visible &&
      eStore.columns.length > 0 &&
      // this.props.routeStore.match.params.entity === this.props.entitiesStore.fetchEntityName &&
      <div>
        <div>
          <div>
            {
              !eStore.readOnly && !eStore.updateOnly &&
              <Button onClick={() => {
                eStore.addRow({});
              }}>行追加</Button>
            }
            <Button onClick={() => {
              eStore.filterOpen = !eStore.filterOpen
            }}>絞り込み</Button>

            {
              eStore.specificDefinition.filters &&
              <Button.Group basic>
                {
                  eStore.specificDefinition.filters.map((filter) => {
                    return <Button active={eStore.routeStore.location.search === `?${filter.query}`}
                                   key={filter.query}
                                   onClick={()=>{
                      const pageUrl = `/entity/${eStore.routeStore.match.params.entity}?${filter.query}`;
                      eStore.routeStore.history.push(pageUrl);
                    }} >{filter.label}</Button>
                  })
                }
              </Button.Group>
            }

          </div>
          <VirtualizedDataGridFilterEditor
            title="検索条件入力"
            open={this.props.entitiesStore.filterOpen}
            columns={eStore.columns}
            filters={this.props.entitiesStore.filters}
            onOK={(filters) => {
              eStore.filters = filters;
              eStore.filterOpen = !eStore.filterOpen;
              eStore.searchWithFilter();
            }}
            onClear={() => {
              eStore.filters = {};
              eStore.filterOpen = !eStore.filterOpen;
              // this.searchWithFilter();
            }}
            onCancel={() => {
              eStore.filterOpen = !eStore.filterOpen;
            }}
          />

        </div>

        <VirtualizedDataGrid
          updateRef={eStore.records}
          columns={eStore.columns}
          rowsCount={eStore.rowsCount}
          rowGetter={eStore.rowGetter}
          onGridRowsUpdated={eStore.onGridRowsUpdated}
          onCellKeyDown={this.bindCellKeyDownToStore(eStore)}
          onGridSort={eStore.onGridSort}
          onClearFilters={eStore.onClearFilters}
          sortColumn={eStore.sortColumn}
          sortDirection={eStore.sortDirection}
          fixedColumnCount={eStore.fixedColumnCount}
          height={this.props.appUtilStore.height - 150}
          onAddFilter={eStore.onAddFilter}
        />

        {eStore.pager.maxPage != 1 && <div>

          <Dropdown
            placeholder={'ページ'}
            upward
            compact
            selection
            options={
              Array.from(_(_.range(eStore.pager.maxPage)).map((page) => {
                return {key: "key" + page, value: page, text: (page + 1) + ''};
              }).value())
            }
            defaultValue={eStore.pager.currentPage}
            onChange={(event, dd) => {
              eStore.pager.handlePageClick(dd.value)
            }}
          />

          <Menu pagination>
            {

              _.map(eStore.pager.pages, (pageNum, i) => {
                if (pageNum === eStore.pager.currentPage) {
                  return <Menu.Item key={pageNum}
                                    name={pageNum + 1 + ''}
                                    active={true}/>
                } else if (pageNum === -1) {
                  return <Menu.Item key={'0' + i}
                                    name='・・・'/>
                } else {
                  return <Menu.Item key={pageNum}
                                    name={pageNum + 1 + ''}
                                    onClick={() => {
                                      eStore.pager.handlePageClick(pageNum);
                                    }}/>
                }
              })
            }
          </Menu>

        </div>
        }
      </div>
      }
    </div>
  }
}
