import * as React from "react";
import {inject, observer, Provider} from "mobx-react";
import {AppUtilStore} from "../store/AppUtilStore";
import {autorun} from "mobx";
import {RouteStore} from "./RouteProvider";
import {ManageStore} from "../store/costReceipt/ManageStore";
import {EditArtStore, RegisterNewArtStore} from "../store/costReceipt/RegisterArtStore";
import {ManageCostReceiptComponent} from "../components/ManageCostReceiptComponent";

@inject("appUtilStore", "routeStore")
@observer
export class ManageCostReceiptProvider extends React.Component<{ appUtilStore?: AppUtilStore, routeStore?: RouteStore }, {}> {

  manageStore: ManageStore;

  registerNewArtStore: RegisterNewArtStore;
  editArtStore: EditArtStore;

  constructor(props) {
    super(props);
    this.manageStore = new ManageStore(props.routeStore, props.appUtilStore);
    this.registerNewArtStore = new RegisterNewArtStore(this.manageStore, props.routeStore, props.appUtilStore);
    this.editArtStore = new EditArtStore(this.manageStore, props.routeStore, props.appUtilStore);

    autorun(async () => {
      await this.manageStore.retrieve();
      if (this.manageStore.isInit) {
        props.history.push(`/stocks/${props.match.params.stockId}/details`)
      } else if(this.manageStore.isNew) {
        await this.registerNewArtStore.retrieve();
      } else if(this.manageStore.isEdit) {
        await this.editArtStore.retrieve();
      }

    })
  }

  render() {
    return <Provider {...this}>
        <ManageCostReceiptComponent/>
      </Provider>
  }
}
