import {Treebeard} from "react-treebeard";
import {computed, observable} from "mobx";
import {IStockDetail} from "../../main/models/StockDetail";
import {RouteStore} from "../providers/RouteProvider";
import * as React from "react";
import * as _ from "lodash";
import {AppUtilStore} from "./AppUtilStore";
import {DataGridBaseStore} from "./dataGrid/DataGridBaseStore";
import {SemanticCOLORS, SemanticICONS} from "semantic-ui-react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";


type IRegisterStockDetail = IStockDetail & {
  _message?: string;
  isUpdated?: boolean;
  get?: (key: any) => any
}

export class StockDetailStore extends DataGridBaseStore<IRegisterStockDetail> {

  @observable.ref visible: boolean;

  @observable addRowsInputNum: number = 1;

  constructor(public routeStore: RouteStore, appUtilStore: AppUtilStore) {
    super(appUtilStore);
  }

  get entityPluralLabel(): string {
    return 'stockDetails';
  }

  get recordQuery(): string {
    return `?scope=forStockList&stockId=${this.stockId}&sort=id`;
  }

  get stockId() {
    return this.routeStore.match.params.stockId;
  }

  retrieve() {
    this.visible = false;
    return super.retrieve()
      .then(() => {
        this.visible = true;
      });
  }

  @computed
  get visibleAndOrderColumnKeys(): string[] {
    return [
      'action',  // system
      'title',
      'price',
      'detail',
      'detailArtCount',
      'detailArtCostSum',
      'detailSoldPriceSum',
      'soldArtCount',
      'soldCostSum',
      'soldPriceSum',
      'createdAt',
      'updatedAt',
    ];
  }

  getActionIconProp(dependentValues: any): { name: SemanticICONS, color: SemanticCOLORS } {
    const name: SemanticICONS = dependentValues.art &&
    dependentValues.art.artResult ?
      'gift' : 'list';

    const color: SemanticCOLORS = dependentValues.isUpdated ? 'red' : 'black';

    return {name, color};
  }

  getAdditionalCellActions(dependentValues): JSX.Element[] {
    const children = super.getAdditionalCellActions(dependentValues);
    children.push(
      <div key="editDetailArt" className="item" onClick={() => {
        this.routeStore.history.push(`/stocks/${this.stockId}/arts?stockDetailId=${dependentValues.id}`);
      }}>作品編集
      </div>
    );

    children.push(
      <Dropdown key="addArts" text='作品追加' pointing='left' className='link item'>
        <Dropdown.Menu>
          {_.map([1, 2, 3, 5, 10, 20], (i) => {
            return <Dropdown.Item key={i} onClick={() => {
              this.addArts(dependentValues, i);
            }}>{i}</Dropdown.Item>
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
    children.push(
      <div key="autoPriceDivide" className="item" onClick={() => {
        this.agent
          .post(`/rest/stockDetails/${dependentValues.id}/autoPriceDivide`)
          .then(() => {
            this.retrieve();
          })
      }}>原価按分</div>
    );
    return children;
  }

  addRows() {
    _.range(0, this.addRowsInputNum)
      .forEach(() => {
        this.addRow({stockId: this.stockId});
      })
  }

  addArts(v: IRegisterStockDetail, n: number) {

    const entityEndpoint = `/rest/arts/bulk`;

    const newArts = _.range(0, n).map(() => {
      return {
        title: v.title,
        stockDetailId: v.id,
        cost: v.price,
      }
    });

    const promise = this.agent.post(entityEndpoint, newArts);
    return promise
      .then((response) => {
        console.log(response);
        return this.retrieve()
      })

  }

}
