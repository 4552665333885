import * as React from "react";

import {inject, observer, Provider} from "mobx-react";
import {RouteStore} from "./RouteProvider";
import {EntitiesStore} from "../store/EntitiesStore";
import {EntitiesComponent} from "../components/EntitiesComponent";
import {AppUtilStore} from "../store/AppUtilStore";
import {autorun} from "mobx";


@inject("appUtilStore", "routeStore")
@observer
export class EntitiesProvider extends React.Component<{ appUtilStore?: AppUtilStore, routeStore?: RouteStore }, {}> {

  entitiesStore: EntitiesStore;

  constructor(props) {
    super(props);
    this.entitiesStore = new EntitiesStore(props.routeStore, props.appUtilStore);
    console.log("EntitiesProvider:constructor", props);

    autorun(() => {
      this.entitiesStore.retrieve();
    })
  }

  render() {
    return <Provider entitiesStore={this.entitiesStore}>
      <EntitiesComponent/>
    </Provider>
  }
}


