import * as React from "react";

import {Provider} from "mobx-react";

import {RouteProvider} from "./RouteProvider";
import {LabelPrintView} from "../components/art/LabelPrintView";

export class LabelPrintViewProvider extends React.Component<{}, {}> {

  constructor(props) {
    super(props);
    console.log("LabelPrintViewProvider:constructor", props);
  }

  render() {
    return <RouteProvider {...this.props}>
        <LabelPrintView />
    </RouteProvider>
  }
}


