import {createActionCell} from "../../components/Utilities";
import {SemanticCOLORS, SemanticICONS} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import * as React from "react";
import {EntitiesStore} from "../EntitiesStore";
import {IPlan} from "../../../main/models/Plan";
import {DEFAULT_REFERENCES_DEFINITION} from "../dataGrid/definitions";
import * as _ from "lodash";
import * as inflection from "inflection";

const plansActionCell = (entitiesStore: EntitiesStore, dependentValues: IPlan) =>{
  const actions = [<Dropdown key="plans" text='出展' pointing='left' className='link item'>
    <Dropdown.Menu>
      <Dropdown.Item key="addPlanArt" onClick={() => {
        entitiesStore.routeStore.history.push(`/plans/${dependentValues.id}/arts/new`);
      }}>追加</Dropdown.Item>
      <Dropdown.Item key="editPlanArt" onClick={() => {
        entitiesStore.routeStore.history.push(`/plans/${dependentValues.id}/arts/edit`);
      }}>編集</Dropdown.Item>
      <Dropdown.Item key="planArtResult" onClick={() => {
        entitiesStore.routeStore.history.push(`/plans/${dependentValues.id}/arts/sales`);
      }}>売上</Dropdown.Item>
      <Dropdown.Item key="editPlanCost" onClick={() => {
        entitiesStore.routeStore.history.push(`/plans/${dependentValues.id}/arts/cost`);
      }}>経費</Dropdown.Item>
      <Dropdown.Item key="showPlanReport" onClick={() => {
        entitiesStore.routeStore.history.push(`/plans/${dependentValues.id}/arts/report`);
      }}>レポート</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>]

  return actions;
};

const stocksActionCell = (entitiesStore: EntitiesStore, dependentValues) =>{
  return <Dropdown key="consignments" text='仕入れ' pointing='left' className='link item'>
    <Dropdown.Menu>
      <Dropdown.Item key="editStockDetails" onClick={() => {
        entitiesStore.routeStore.history.push(`/stocks/${dependentValues.id}/details`);
      }}>伝票編集</Dropdown.Item>
      <Dropdown.Item key="editStockDetailArts" onClick={() => {
        entitiesStore.routeStore.history.push(`/stocks/${dependentValues.id}/arts`);
      }}>作品編集</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
};

const artsActionCell = (entitiesStore: EntitiesStore, dependentValues) =>{
  const children = [];

  if(dependentValues.planArtCount > 0) {
    children.push(<div key="artHistory" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/entity/planArts?scope=forRestListReadOnly&artId=${dependentValues.id}`)
    }}>作品出展履歴</div>)
  }
  children.push(
    <Dropdown key="consignments" text='仕入れ' pointing='left' className='link item'>
      <Dropdown.Menu>
        <Dropdown.Item key="editStock" onClick={() => {
          const sd = dependentValues.stockDetail;
          entitiesStore.routeStore.history.push(`/stocks/${sd.stock.id}/details`);
        }}>伝票編集</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>);
  if(dependentValues.soldAt) {
    children.push(<div key="soldPlan" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/plans/${dependentValues.soldAt.planId}/arts/edit`)
    }}>売却企画</div>);
  }
  if(dependentValues.authorId) {
    children.push(<div key="editAuthor" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/entity/authors?id=${dependentValues.authorId}`)
    }}>作家編集</div>);
  }
  children.push(<div key="editArtCost" className="item" onClick={() => {
    entitiesStore.routeStore.history.push(`/artDetails/${dependentValues.id}/cost`)
  }}>作品経費編集</div>);

  return children;
};

const artCostsActionCell = (entitiesStore: EntitiesStore, dependentValues) => {
  const children = [
    <div key="editArt" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/entity/arts?scope=full&id=${dependentValues.art.id}`)
    }}>作品編集</div>,
    <div key="editArtCost" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/artDetails/${dependentValues.art.id}/cost`)
    }}>作品経費編集</div>
  ];

  if(dependentValues.artCostReceiptId) {
    children.push(<div key="editReceiptArtCost" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/artCostReceipts/${dependentValues.artCostReceiptId}/arts/edit`)
    }}>経費伝票編集</div>);
  }

  return children;
};

const placesActionCell = (entitiesStore: EntitiesStore, dependentValues) => {
  const children = [
    <div key="placePlan" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/entity/plans?placeId=${dependentValues.id}`)
    }}>開催地企画</div>,
  ];

  return children;
};

const artCostReceiptsActionCell = (entitiesStore: EntitiesStore, dependentValues) => {
  return [
    <div key="addArtCost" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/artCostReceipts/${dependentValues.id}/arts/new`)
    }}>経費追加</div>,
    <div key="editArtCost" className="item" onClick={() => {
      entitiesStore.routeStore.history.push(`/artCostReceipts/${dependentValues.id}/arts/edit`)
    }}>経費編集</div>
  ];
};

const planArtsActionCell = (entitiesStore: EntitiesStore, dependentValues) => {
  const actions = [
    <Dropdown key="arts" text='作品' pointing='left' className='link item'>
      <Dropdown.Menu>
        <Dropdown.Item key="editPlanArt" onClick={() => {
          entitiesStore.routeStore.history.push(`/entity/arts?scope=full&id=${dependentValues.artId}`)
        }}>編集</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>,
    <Dropdown key="plans" text='出展' pointing='left' className='link item'>
      <Dropdown.Menu>
        <Dropdown.Item key="editPlanArt" onClick={() => {
          entitiesStore.routeStore.history.push(`/plans/${dependentValues.planId}/arts/edit`);
        }}>編集</Dropdown.Item>
        <Dropdown.Item key="planArtResult" onClick={() => {
          entitiesStore.routeStore.history.push(`/plans/${dependentValues.planId}/arts/sales`);
        }}>売上</Dropdown.Item>
        <Dropdown.Item key="editPlanCost" onClick={() => {
          entitiesStore.routeStore.history.push(`/plans/${dependentValues.planId}/arts/cost`);
        }}>経費</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ];

  return actions;
};


const getReferenceActions = (entitiesStore: EntitiesStore, dependentValues) => {

  const currentSchema = entitiesStore.currentSchema;

  const referenceColumns = _(DEFAULT_REFERENCES_DEFINITION).keys().filter((k, v) => {
    return !_.isUndefined(currentSchema.properties[k]) &&
      !_.isUndefined(dependentValues[k]) &&
      !_.isUndefined(DEFAULT_REFERENCES_DEFINITION[k].reference)
  }).value();


  return !_.isEmpty(referenceColumns) &&
    <Dropdown key="refs" text='参照' pointing='left' className='link item'>
      <Dropdown.Menu>
        {_.map(referenceColumns, (defKey) => {
          const def = DEFAULT_REFERENCES_DEFINITION[defKey];
          const pluralModelName = inflection.pluralize(def.reference.modelName);
          const schema = entitiesStore.schemaDefinitions[pluralModelName];

          return <Dropdown.Item key={`reference-${defKey}`} onClick={() => {
            entitiesStore.routeStore.history.push(`/entity/${pluralModelName}?id=${dependentValues[defKey]}${def.reference.query}`);
          }}>{`${schema.title}`}</Dropdown.Item>
        })}
      </Dropdown.Menu>
    </Dropdown>;
};


export const getActionCell = (entitiesStore: EntitiesStore) => {

  if (entitiesStore.selectMode === true) {
    let actionCell = createActionCell(({dependentValues, value}) => {
      let isSelected = entitiesStore.hasIdOfSelected(dependentValues.id);
      let iconName: SemanticICONS = isSelected ? 'minus' : 'plus';
      let iconColor: SemanticCOLORS = isSelected ? 'black' : 'yellow';

      return <div className="system-action">
        <Icon color={iconColor} name={iconName} onClick={(event) => {
          entitiesStore.toggleSelectionId(dependentValues.id);
        }}/>
      </div>
    });

    actionCell.headerRenderer = () => {
      return <div style={{
        paddingTop: "8px",
        paddingLeft: "12px",
      }}>
        <i className={"plus icon"} onClick={(event) => {
          entitiesStore.appUtilStore.showElementFixed(event,
            <Dropdown icon={null}
                      ref={(node: any) => {
                        if (node) {
                          node.ref.current.click()
                        }
                      }}>
              <Dropdown.Menu>
                <div key="select" className="item" onClick={() => {
                  entitiesStore.selectMode = !entitiesStore.selectMode
                }}>選択モード
                </div>
                <div key="allSelect" className="item" onClick={() => {
                  entitiesStore.selectAll();
                }}>全選択
                </div>
                {
                  entitiesStore.routeStore.match.params.entity === 'arts' &&
                  <div key="printArt" className="item" onClick={() => {
                    entitiesStore.routeStore.history.push(`/arts/print?id=[${entitiesStore.selectedRecordIds.join(',')}]&scope=full&sort=-id`);
                  }}>ラベル印刷</div>
                }
              </Dropdown.Menu>
            </Dropdown>);
        }}/>
      </div>
    };

    return actionCell;
  } else {

    let actionCell = createActionCell(({dependentValues, value}) => {
        let iconName: SemanticICONS = 'list';
        let iconColor: SemanticCOLORS = 'black';
        if (entitiesStore.routeStore.match.params.entity === 'arts') {
          iconName = dependentValues.artResult ?
            'gift' : 'list';

          iconColor = dependentValues.artResult ?
            dependentValues.isUpdated ? 'red' : 'yellow' : 'black';

        } else if (entitiesStore.routeStore.match.params.entity === 'artCosts') {
          iconName = dependentValues.artCostReceiptId ?
            'clipboard' : 'list';
        } else {
          iconName = 'list';
          iconColor = dependentValues.isUpdated ? 'red' : 'black';
        }
        // const specs = this.getSpecificDefinition();
        const eStore = entitiesStore;

        const onClickSystemAction = (event) => {
          return entitiesStore.appUtilStore.showElementFixed(event,
            <Dropdown icon={null}
                      ref={(node: any) => {
                        if (node) {
                          node.ref.current.click()
                        }
                      }}>
              <Dropdown.Menu>
                {dependentValues.isUpdated &&
                <div key="save" className="item" onClick={() => {
                  entitiesStore.saveRow(dependentValues);
                }}>保存</div>}
                {
                  entitiesStore.routeStore.match.params.entity === 'plans' &&
                  plansActionCell(entitiesStore, dependentValues)
                }
                {
                  entitiesStore.routeStore.match.params.entity === 'stocks' &&
                  stocksActionCell(entitiesStore, dependentValues)
                }
                {
                  entitiesStore.routeStore.match.params.entity === 'arts' &&
                  artsActionCell(entitiesStore, dependentValues)
                }
                {
                  entitiesStore.routeStore.match.params.entity === 'artCosts' &&
                  artCostsActionCell(entitiesStore, dependentValues)
                }
                {
                  entitiesStore.routeStore.match.params.entity === 'places' &&
                  placesActionCell(entitiesStore, dependentValues)
                }
                {
                  entitiesStore.routeStore.match.params.entity === 'authors' &&
                  <div key="editAuthor" className="item" onClick={() => {
                    entitiesStore.routeStore.history.push(`/entity/arts?scope=full&authorId=${dependentValues.id}`)
                  }}>作家作品</div>
                }
                {
                  entitiesStore.routeStore.match.params.entity === 'planArts' &&
                  planArtsActionCell(entitiesStore, dependentValues)
                }
                {
                  entitiesStore.routeStore.match.params.entity === 'artCostReceipts' &&
                  artCostReceiptsActionCell(entitiesStore, dependentValues)
                }
                {
                  getReferenceActions(entitiesStore, dependentValues)
                }
                {
                  !eStore.readOnly && !eStore.updateOnly &&
                  <div key="copy" className="item" onClick={() => {
                    eStore.copyRow(dependentValues)
                  }}>コピー</div>
                }
                {
                  eStore.specificDefinition.deletable && eStore.canDeleteDependentValues(dependentValues) &&
                  <div key="delete" className="item" onClick={() => {
                    eStore.deleteRow(dependentValues)
                  }}>削除</div>
                }
              </Dropdown.Menu>

            </Dropdown>);

        };

        return <div className="system-action" onClick={onClickSystemAction}>
          <Icon color={iconColor} name={iconName}/>
        </div>
      }
    );

    actionCell.headerRenderer = () => {
      return <div style={{
        paddingTop: "8px",
        paddingLeft: "12px",
      }}>
        <i className={"list icon"} onClick={(event) => {
          if (entitiesStore.routeStore.match.params.entity === 'arts') {
            entitiesStore.appUtilStore.showElementFixed(event,
              <Dropdown icon={null}
                        ref={(node: any) => {
                          if (node) {
                            node.ref.current.click()
                          }
                        }}>
                <Dropdown.Menu>
                  <div key="save" className="item" onClick={() => {
                    entitiesStore.selectMode = !entitiesStore.selectMode
                  }}>選択モード
                  </div>
                </Dropdown.Menu>
              </Dropdown>);
          }

        }}/>
      </div>
    };

    return actionCell;
  }
}
