import * as moment from "moment";

moment.locale('ja', {
  weekdays: ["日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日"],
  weekdaysShort: ["日","月","火","水","木","金","土"],
});

export namespace DateUtils {
  export const format = (value, format = 'YYYY/MM/DD') => {
    return value ? moment(value).format(format) : '';
  };

  export const currentConsumption = (baseDate: Date): number => {

    const base = moment(baseDate)
    if(base.isBefore(moment('2019/10/01'))){
      return 1.08;
    } else {
      return 1.1;
    }


  }
}