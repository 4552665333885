import * as React from "react";

import {Treebeard} from "react-treebeard";
import {action, observable} from "mobx";
import {AppUtilStore} from "../store/AppUtilStore";
import {SAResponse, SuperagentPromise} from "../utils/agent";
import {IPlan} from "../../main/models/Plan";

let agent: SuperagentPromise;

export class PlanReportStore {
  _appUtilStore: AppUtilStore;
  get appUtilStore() {
    return this._appUtilStore;
  }

  set appUtilStore(value: AppUtilStore) {
    this._appUtilStore = value;
    agent = value.agent;
  }

  @observable planId: number;
  @observable.ref plan: IPlan;

  @action.bound
  retrieve(planId: number) {
    this.planId = planId;

    return agent.get(`/rest/plans/${planId}?scope=forReport`).then((res: SAResponse<IPlan>) => {
      this.plan = res.body;
    });
  }


}
