import * as React from "react";
import {inject, observer, Provider} from "mobx-react";

import * as _ from "lodash";

import {Treebeard} from "react-treebeard";

import {RouteProvider, RouteStore} from "../providers/RouteProvider";
import {observable} from "mobx";
import {agent, SAResponse} from "../utils/agent";

import {orderColumns, transformSchemaToArray} from "../utils/dataGrid";
import {IArt} from "../../main/models/Art";
import {JsonSchema} from "../../defines/jsonSchema";
import {IArtHistory} from "../../main/models/ArtHistory";
import {AppUtilStore} from "../store/AppUtilStore";
import {VirtualizedDataGrid} from "./lib/VirtualizedDataGrid";
import Bluebird = require("bluebird");
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";


@inject("artHistoryViewStore", "routeStore", "appUtilStore")
@observer
export class ArtHistoryViewComponent extends React.Component<{
  artHistoryViewStore?: ArtHistoryViewStore,
  routeStore?: RouteStore,
  appUtilStore?: AppUtilStore
}, {}> {

  grid: any;

  constructor(props) {
    super(props);
    console.log("ArtHistoryView", props);
  }

  customField = {
    createdAt: {},
    updatedAt: {},
    title: {
      width: 200
    },
  };

  invisibleField = [
    'id',
    'artId',
    'authorId',
    'planId',
    'version',
    'deletedAt',
    'createdById',
    'updatedById',
    'deletedById',
    'createdAt',
    'updatedAt',
    'password',
    'planArtStatusKey',
    'planArtStatus.key',
    'plan.planYear',
    'plan.placeId'
  ];

  getColumns() {

    const propertiesArray = transformSchemaToArray(
      {
        jsonSchemas: this.props.artHistoryViewStore.artHistorySchema.properties,
        exclude: this.invisibleField
      }
    );

    propertiesArray.push({key: "createdAt", type: "string", title: '操作日時'});
    transformSchemaToArray({
      jsonSchemas: this.props.artHistoryViewStore.planArtStatusSchema.properties,
      prefix: 'planArtStatus.',
      target: propertiesArray,
      exclude: this.invisibleField
    });

    transformSchemaToArray({
      jsonSchemas: this.props.artHistoryViewStore.planSchema.properties,
      prefix: 'plan.',
      target: propertiesArray,
      exclude: this.invisibleField
    });

    propertiesArray.push({key: "plan.place.name", type: "string", title: '開催地名'});
    propertiesArray.push({key: "plan.place.company.name", type: "string", title: '企業'});

    transformSchemaToArray({
      jsonSchemas: this.props.artHistoryViewStore.userSchema.properties,
      prefix: 'createdBy.',
      target: propertiesArray,
      exclude: this.invisibleField
    });


    const defaultColumns = _.map(propertiesArray, (v, i) => {
      return {
        key: v.key,
        editable: false,
        resizable: true,
        sortable: true,
        name: v.title,
        width: 100,
        required: false
      }
    });

    let actionSpecificColumn = [];

    actionSpecificColumn.push({
      key: "action",
      name: "action",
      cellClass: 'for-action-cell',
      getRowMetaData: (row) => row,
      formatter: ({dependentValues}: { dependentValues: IArtHistory & { isUpdated?: boolean } }) => {

        let iconName = dependentValues.planArtStatusKey === 'sold' ? 'gift' : 'list';
        let iconColor = dependentValues.planArtStatusKey === 'sold' &&
        this.props.artHistoryViewStore.art.soldAt &&
        this.props.artHistoryViewStore.art.soldAt.planId === dependentValues.planId ?
          'yellow' : 'black';

        return <Dropdown item icon={{color: iconColor, name: iconName}}>
          <Dropdown.Menu>
            <div className="item" onClick={() => {
              this.props.routeStore.history.push(`/entity/arts?scope=full&id=${dependentValues.artId}`)
            }}>作品編集
            </div>
            {
              dependentValues.planId &&
              <div className="item" onClick={() => {
                this.props.routeStore.history.push(`/plans/${dependentValues.planId}/arts/edit`)
              }}>企画を見る</div>
            }
          </Dropdown.Menu>
        </Dropdown>

      },
      width: 50,
      headerRenderer: () => {
        return <i className={"list icon"}/>
      }
    });

    const cellSpecificTransformed = _.map(defaultColumns, (v, i) => {
        if (this.customField[v.key]) {
          return _.defaults({}, this.customField[v.key], v);
        }
        return _.defaults({}, v);
      }
    ).concat(actionSpecificColumn);
    const order = ['action'];
    return orderColumns(cellSpecificTransformed, order);
  }

  rowGetter(i) {
    let artHistory: IArtHistory & { get?: Function } = this.props.artHistoryViewStore.artHistories[i];
    return artHistory;
  }

  onGridSort(sortColumn, sortOrder) {
    if (sortOrder === 'NONE') {
      sortColumn = 'id';
    }
    this.props.artHistoryViewStore.artHistories = _.orderBy(this.props.artHistoryViewStore.artHistories, [sortColumn], [sortOrder.toLowerCase()])
  }

  render() {

    return <div>
      {this.props.artHistoryViewStore.visible &&
      <div>
        <Segment.Group horizontal>
          <Segment>
            <span>
            <span><h3>作品ID: {this.props.artHistoryViewStore.art.id} {this.props.artHistoryViewStore.art.title} {this.props.artHistoryViewStore.art.author.name}</h3></span>
            <span><h4>{this.props.artHistoryViewStore.art.status} {this.props.artHistoryViewStore.art.technique}</h4></span>
            </span>
          </Segment>
        </Segment.Group>

        <div>
          <VirtualizedDataGrid
            ref={(grid) => {
              this.grid = grid;
            }}
            updateRef={this.props.artHistoryViewStore.artHistories}
            columns={this.getColumns()}
            rowsCount={this.props.artHistoryViewStore.artHistories.length}
            rowGetter={this.rowGetter.bind(this)}
            onGridSort={this.onGridSort.bind(this)}
            fixedColumnCount={1}
            height={this.props.appUtilStore.height - 200}
          />

        </div>
      </div>
      }
    </div>
  }

}

export class ArtHistoryViewStore {
  refresh: () => void;
  @observable.ref art: IArt;
  @observable.ref artHistorySchema: JsonSchema;
  @observable.ref artSchema: JsonSchema;
  @observable.ref planSchema: JsonSchema;
  @observable.ref planArtStatusSchema: JsonSchema;
  @observable.ref userSchema: JsonSchema;
  @observable.ref artHistories: Array<IArtHistory> = [];
  @observable.ref visible: boolean;

}

@inject("appUtilStore")
@observer
export class ArtHistoryViewProvider extends React.Component<any, {}> {

  artHistoryViewStore: ArtHistoryViewStore;

  schemaPromises: Bluebird<[SAResponse<JsonSchema>, SAResponse<JsonSchema>, SAResponse<JsonSchema>, SAResponse<JsonSchema>, SAResponse<JsonSchema>]>;

  constructor(props) {
    super(props);
    this.artHistoryViewStore = new ArtHistoryViewStore();
    this.artHistoryViewStore.refresh = this.refresh.bind(this);
    console.log("ArtHistoryViewProvider:constructor", props);
    this.schemaPromises = Bluebird.all(
      [
        agent.getc(`/rest/artHistories.schema`),
        agent.getc(`/rest/arts.schema`),
        agent.getc(`/rest/plans.schema`),
        agent.getc(`/rest/planArtStatuses.schema`),
        agent.getc(`/rest/users.schema`)
      ])
  }

  retrievePlan(props) {
    if (!_.isNaN(_.parseInt(props.match.params.artId))) {
      this.artHistoryViewStore.visible = false;
      props.appUtilStore.dimmerActive = true;

      return this.schemaPromises
        .then(([artHistorySchema, artSchema, planSchema, planArtStatusSchema, userSchema]) => {
          this.artHistoryViewStore.artHistorySchema = artHistorySchema.body;
          this.artHistoryViewStore.artSchema = artSchema.body;
          this.artHistoryViewStore.planSchema = planSchema.body;
          this.artHistoryViewStore.planArtStatusSchema = planArtStatusSchema.body;
          this.artHistoryViewStore.userSchema = userSchema.body;
        })
        .then(() => {

          let retrievePlanPromises = [];
          retrievePlanPromises.push();

          return Bluebird.all(
            [
              agent.get<IArt>(`/rest/arts/${props.match.params.artId}?scope=full`),
              agent.get<IArtHistory[]>(`/rest/artHistories?sort=-id&scope=full&artId=${props.match.params.artId}`)
            ])
            .then(([art, artHistories]) => {
              this.artHistoryViewStore.art = art.body;
              this.artHistoryViewStore.artHistories = artHistories.body;
              this.artHistoryViewStore.visible = true;
            })
        })
        .finally(() => {
          props.appUtilStore.dimmerActive = false;
        })

    } else {
      props.history.push('/entity/arts?scope=full&sort=-id')
    }
  }

  refresh() {
    return this.retrievePlan(this.props);
  }

  receiveProps(props) {
    this.retrievePlan(props);
  }

  componentDidMount() {
    this.receiveProps(this.props);
  }

  componentWillUpdate(props) {
    this.receiveProps(props);
  }

  render() {
    return <RouteProvider {...this.props}>
      <Provider artHistoryViewStore={this.artHistoryViewStore}>
        <ArtHistoryViewComponent />
      </Provider>
    </RouteProvider>
  }
}
