import * as React from "react";
import * as _ from "lodash";

import {action, computed, observable, runInAction, transaction} from "mobx";
import {JsonSchema} from "../../../defines/jsonSchema";
import {adjustColumnsWidth, orderColumns} from "../../utils/dataGrid";
import {DataGridViewColumnProps} from "../../components/dataGrid/DataGridView";
import * as inflection from "inflection";
import {
  autoCompleteEditor,
  autoCompleteFormatter,
  convertDropdownItem,
  createActionCell,
  jsonSchemaToColumnProps,
  JsonSchemeScope,
  scopeColumnProps,
  SYSTEM_INVISIBLE_FIELD
} from "../../components/Utilities";
import {AppUtilStore} from "../AppUtilStore";
import {SAResponse} from "../../utils/agent";
import {ColumnProps, GridRowsUpdatedEvent} from "../../components/lib/VirtualizedDataGridDefinition";
import {SemanticCOLORS, SemanticICONS} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {IUserTransactionModel} from "../../../main/models/base/UserTransactionModel";
import {SortTypes} from "../../components/lib/VirtualizedDataGrid";
import {SemanticDropdownEditorProps} from "../../components/dataGrid/SemanticDropdown";
import {DEFAULT_REFERENCES_DEFINITION, MODEL_SPECIFIC_DEFINITION, SPECIFIC_DEFINITION} from "./definitions";
import Bluebird = require("bluebird");

export class DataGridBaseStore<T extends IUserTransactionModel & { isUpdated?: boolean }> {

  get agent() {
    return this.appUtilStore.agent;
  }

  constructor(
    public appUtilStore: AppUtilStore,
  ) {
  }

  @observable.ref records: Array<T> = [];
  @observable.ref schemaDefinitions: { [index: string]: JsonSchema } = {};
  @observable.ref schemaSuggestionsOriginal: { [index: string]: any } = {};
  @observable.ref schemaSuggestions: { [index: string]: SemanticDropdownEditorProps } = {};

  @observable sortColumn: string;
  @observable sortDirection: SortTypes;

  @computed
  get schemas() {
    return this.agent.getc(`/rest/db.schema`)
      .then((recordSchemas: SAResponse<{ [index: string]: JsonSchema }>) => {
        this.schemaDefinitions = recordSchemas.body;
      });
  }

  get scopeList(): JsonSchemeScope[] {
    const extractScope = (scope: JsonSchemeScope): JsonSchemeScope[] => {
      return _.reduce(scope.includes, (schemas, include) => {
        return schemas.concat(extractScope(include));
      }, [scope]);
    };
    return extractScope(this.schemaScope);
  }

  @computed
  get schemaScope(): JsonSchemeScope {

    const schemeScopeWalker = (scope: JsonSchemeScope, each: (scope: JsonSchemeScope) => void) => {
      each(scope);
      _.forEach(scope.includes, (scope) => {
        schemeScopeWalker(scope, each)
      });
    };

    const _schemaScope = this.customSchemaScope({
      modelName: this.entityPluralLabel,
      includes: []
    });

    schemeScopeWalker(_schemaScope, (scope) => {
      const schemaName = scope.modelName || inflection.pluralize(scope.prefix);
      scope.schema = this.schemaDefinitions[schemaName];
    });

    return _schemaScope;
  }

  customSchemaScope(schemaScope: JsonSchemeScope) {
    return schemaScope;
  }

  get entityPluralLabel(): string {
    return '';
  }

  get recordQuery(): string {
    return '';
  }

  retrieveSuggestion() {
    const props = _.filter(this.specifiedColumnProps, (prop, key) => {
      return !_.isUndefined(prop.schema);
    });

    return Bluebird.all(_.map(props, (prop) => {
        return this.agent.get(prop.schema.endpoint).then((suggestions) => {
          this.schemaSuggestionsOriginal[prop.key] = suggestions.body;
          this.schemaSuggestions[prop.key] = _.assign({},
            prop.schema,
            {
              suggestions: _.map(suggestions.body, (autoCompleteEntity: any) => {
                return convertDropdownItem(autoCompleteEntity, prop.schema.labelKey, prop.schema.valueKey);
              })
            });
        })
      })
    );
  }


  @computed
  get currentSchema() {
    return this.schemaDefinitions[this.entityPluralLabel];
  }

  retrieve(): Bluebird<any> {

    const schemaPromise = this.schemas
      .then(() => {
        return this.retrieveSuggestion();
      });

    const recordsPromise = this.agent.get(`/rest/${this.entityPluralLabel}${this.recordQuery}`)
      .then((records: SAResponse<T[]>) => {
        this.records = records.body;
        return records;
      });

    return Bluebird.all([schemaPromise, recordsPromise])
      .then((res) => {
        return res[1]; // return recordsPromise.result
      });

  }

  @action.bound
  addRow(entity: T & { createdAt?: Date, updatedAt?: Date }) {
    entity.isUpdated = true;
    delete entity.createdAt;
    delete entity.updatedAt;
    this.records = [entity, ...this.records];
  }

  @action.bound
  removeRow(target) {
    this.records = _.filter(this.records, (record) => {
      return record !== target;
    });
  }

  @action.bound
  deleteRow(entity) {
    if (entity.id) {
      this.agent.del(`/rest/${this.entityPluralLabel}/${entity.id}?version=${entity.version}`)
        .then(() => {
          this.removeRow(entity);
        });
    } else {
      this.removeRow(entity);
    }
  }

  @action.bound
  copyRow(srcRow) {
    const newRow: any = JSON.parse(JSON.stringify(srcRow));
    SYSTEM_INVISIBLE_FIELD.forEach((key) => {
      delete newRow[key];
    });
    ['id', 'updatedAt', 'createdAt'].forEach((key) => {
      delete newRow[key];
    });
    this.addRow(newRow);
  }


  @action.bound
  saveRow(dependentValues) {

    console.log("saveRow", dependentValues);
    if (!dependentValues.isUpdated) {
      return;
    }

    const entityIndex = this.records.indexOf(dependentValues);

    const record: any = _.mapValues(this.records[entityIndex], (value, key) => {
      if (_.isString(value) && _.isEmpty(value)) {
        return null;
      } else {
        return value;
      }
    });

    let promise;
    if (!record.id) {
      promise = this.agent.post(`/rest/${this.entityPluralLabel}`, record);
    } else {
      promise = this.agent.put(`/rest/${this.entityPluralLabel}/${record.id}`, record);
    }

    return promise
      .then((response) => {
        this.records = _.map(this.records, (updatedRecord) => {
          if (updatedRecord === dependentValues) {
            return _.assign({}, updatedRecord, response.body, {isUpdated: false});
          } else {
            return updatedRecord;
          }
        })
      })
  }

  saveRows() {
    const entityEndpoint = `/rest/${this.entityPluralLabel}/bulk`;
    const promise = this.agent.post(entityEndpoint, this.records.filter((r) => {
      return r.isUpdated;
    }));
    return promise
      .then((response) => {
        return this.retrieve()
      })
  }

  updateByIndex(index: number) {
    return this.saveRow(this.rowGetter(index));
  }

  additionalOnGridRowsUpdated(e: GridRowsUpdatedEvent): any {
    return {isUpdated: true}
  }

  @action.bound
  onGridRowsUpdated(e: GridRowsUpdatedEvent) {
    let srcRow = this.records[e.toRow];
    if (('' + srcRow[e.cellKey]) !== ('' + e.updated[e.cellKey])) {
      runInAction(() => {
        this.records = _.map(this.records, (entity) => {
          if (entity === srcRow) {
            const additionalUpdateData = this.additionalOnGridRowsUpdated(e);
            return _.assign({}, entity, e.updated, additionalUpdateData);
          } else {
            return entity;
          }
        })
      })
    }
  }

  @action.bound
  onGridSort(sortColumn: string, sortDirection: SortTypes) {
    this.virtualOnGridSort(sortColumn, sortDirection);
  }

  virtualOnGridSort(sortColumn: string, sortDirection: SortTypes) {
    if (sortDirection === 'NONE') {
      sortColumn = 'id';
    }
    transaction(() => {
      this.sortColumn = sortColumn;
      this.sortDirection = sortDirection;
      this.records = _.orderBy(this.records, [this.sortColumn], [this.sortDirection.toLowerCase() as boolean|"asc"|"desc"])
    })
  }

  @computed
  get computeRows() {
    return this.records;
  }

  @action.bound
  rowGetter(i) {
    return this.computeRows[i];
  }

  @computed
  get rowsCount(): number {
    return this.computeRows.length;
  }

  @computed
  get defaultColumnKeys(): string[] {

    const specs = this.specificDefinition;
    if (specs.fields) {
      return specs.fields.map((field) => {
        return field.key
      })

    } else {
      const entityColumnProps = jsonSchemaToColumnProps(
        {
          schema: this.currentSchema,
          exclude: SYSTEM_INVISIBLE_FIELD
        }
      );
      entityColumnProps.push({key: 'action'});
      return orderColumns(entityColumnProps, ["id", "action"])
        .map((p) => p.key);
    }
  }

  @computed
  get visibleAndOrderColumnKeys(): string[] {

    const specs = this.specificDefinition;
    if (specs.fields) {
      return specs.fields.map((field) => {
        return field.key
      })

    } else {
      const entityColumnProps = jsonSchemaToColumnProps(
        {
          schema: this.currentSchema,
          exclude: SYSTEM_INVISIBLE_FIELD
        }
      );
      entityColumnProps.push({key: 'action'});
      return orderColumns(entityColumnProps, ["id", "action"])
        .map((p) => p.key);
    }

  }

  @computed
  get specifiedColumnProps(): { [index: string]: ColumnProps } {
    // this.currentSchema.properties
    const columnProps = {
      'id': {
        key: 'id',
        width: 80,
        editable: false,
        editor: undefined,
        formatter: ({dependentValues, value}) => {
          let textColor = dependentValues.isUpdated ? 'red' : '';
          return <div className='system-id'>
            <div className={"ui text " + textColor}>{dependentValues.isUpdated ? '*' : ''} {value}</div>
          </div>
        },
      }
    };

    const currentSchema = this.currentSchema;

    _(DEFAULT_REFERENCES_DEFINITION)
      .keys()
      .filter((k) => {
        return !_.isUndefined(currentSchema.properties[k])
      })
      .each((k) => {
        columnProps[k] = _.assign({}, DEFAULT_REFERENCES_DEFINITION[k]);
        columnProps[k].key = k;
      });

    const modelSpecificDefinition = MODEL_SPECIFIC_DEFINITION[this.entityPluralLabel];

    _(modelSpecificDefinition)
      .keys()
      .filter((k) => {
        return !_.isUndefined(currentSchema.properties[k])
      })
      .each((k) => {
        columnProps[k] = _.assign({}, columnProps[k], _.clone(modelSpecificDefinition[k]))
        columnProps[k].key = k;
      });


    return columnProps;
  }

  getActionIconProp(dependentValues: any): { name: SemanticICONS, color: SemanticCOLORS } {
    let name: SemanticICONS = 'list';
    let color: SemanticCOLORS = dependentValues.isUpdated ? 'red' : 'black';

    return {name, color};
  }

  canSaveDependentValues(dependentValues: any): boolean {
    return dependentValues.isUpdated;
  }

  canDeleteDependentValues(dependentValues: any): boolean {
    return !dependentValues.isUpdated;
  }

  getAdditionalCellActions(dependentValues): JSX.Element[] {
    return []
  }

  getGridActionCell({dependentValues}) {

    return <div className="system-action">
      <Icon {...this.getActionIconProp(dependentValues)} onClick={(event) => {
        this.appUtilStore.showElementFixed(event,
          <Dropdown icon={null}
                    ref={(node: any) => {
                      if (node) {
                        node.ref.current.click()
                      }
                    }}>
            <Dropdown.Menu key="action">
              {
                this.canSaveDependentValues(dependentValues) &&
                <div key="save" className="item" onClick={() => {
                  this.saveRow(dependentValues)
                }}>保存</div>
              }
              {
                this.getAdditionalCellActions(dependentValues)
              }
              {
                this.canDeleteDependentValues(dependentValues) &&
                <div key="delete" className="item" onClick={() => {
                  this.deleteRow(dependentValues)
                }}>削除</div>
              }
            </Dropdown.Menu>
          </Dropdown>
        )
      }}>
      </Icon>
    </div>

  }

  getSpecificLocation() {
    return this.entityPluralLabel;
  }

  get additionalColumnProps(): ColumnProps[] {
    return [];
  }

  @computed
  get specificDefinition() {
    return SPECIFIC_DEFINITION[this.getSpecificLocation()] || {};
  }

  createActionCell() {

    return createActionCell(({dependentValues, value}: {
        dependentValues: T
          & { isUpdated: boolean }, value: any
      }) => {
        return this.getGridActionCell({dependentValues});
      }
    )
  }

  transformBySpecificDefinition(_entityColumnProps: ArrayLike<ColumnProps>) {
    const specs = _.cloneDeep(this.specificDefinition);
    const entityColumnProps = Array.from(_entityColumnProps);

    if (specs.fields) {

      const existsProps = _.keyBy(entityColumnProps, 'key');

      specs.fields.forEach((field) => {
        if (existsProps[field.key]) {
          _.assign(existsProps[field.key], field)
        } else {
          entityColumnProps.push(_.assign({}, field));
        }
      })
    }
    const filteredProps = _.filter(entityColumnProps, (prop) => {
      return !_.includes(specs.excludeFields, prop.key)
    });

    if (specs.readOnly) {
      filteredProps.forEach((prop) => {
        prop.editable = false;
        prop.editor = undefined;
      });
    }

    return filteredProps;
  }

  getColumns() {
    const propertiesArray = scopeColumnProps(this.schemaScope);

    propertiesArray.push(this.createActionCell());

    const filtered = _(propertiesArray)
      .union(this.additionalColumnProps)
      .filter((prop) => {
        return _.includes(this.visibleAndOrderColumnKeys, prop.key)
      })
      .map((prop) => {
        if (this.specifiedColumnProps[prop.key]) {
          const specificProp = this.specifiedColumnProps[prop.key];
          _.forIn(specificProp, (value, key) => {
            prop[key] = value;
          });
        }
        if (this.schemaSuggestions[prop.key]) {
          prop.editor = autoCompleteEditor(this.schemaSuggestions[prop.key]);
          prop.formatter = autoCompleteFormatter(this.schemaSuggestions[prop.key]);
        }
        return prop;
      })
      .value();

    const transformedColumnProps = this.transformBySpecificDefinition(filtered);

    adjustColumnsWidth(transformedColumnProps, this.rowsCount, this.rowGetter);

    return orderColumns(transformedColumnProps, this.visibleAndOrderColumnKeys);
  }

  @computed
  get columns(): Array<DataGridViewColumnProps> {
    return this.getColumns();
  }
}
