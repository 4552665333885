import * as React from "react";

import {inject, observer, Provider} from "mobx-react";
import {RouteStore} from "./RouteProvider";
import {EntitiesStore} from "../store/EntitiesStore";
import {EntitiesComponent} from "../components/EntitiesComponent";
import {AppUtilStore} from "../store/AppUtilStore";
import {autorun} from "mobx";
import {ArtStockStore} from "../store/reports/ArtStockStore";
import {ArtStockComponent} from "../components/reports/ArtStockComponent";


interface MatchParams {
  entity: string;
  type: string;
}

@inject("appUtilStore", "routeStore")
@observer
export class ReportsProvider extends React.Component<{ appUtilStore?: AppUtilStore, routeStore?: RouteStore<MatchParams> }, {}> {


  artStockStore: ArtStockStore;

  constructor(props) {
    super(props);
    this.artStockStore = new ArtStockStore(this.props.routeStore, this.props.appUtilStore);
  }

  render() {
    return <Provider {...this}>
      <ArtStockComponent/>
    </Provider>
  }
}


