import * as React from "react";
import {observer, Provider} from "mobx-react";

import * as _ from "lodash";

import {Treebeard} from "react-treebeard";

import {RouteProvider, RouteStore} from "./RouteProvider";
import {RegisterPlanArtStore} from "../store/RegisterPlanArtStore";
import {PlanCostStore} from "../store/PlanCostStore";
import {ManagePlanStore} from "../store/ManagePlanStore";
import {AppUtilStore} from "../store/AppUtilStore";
import {ManagePlanComponent} from "../components/ManagePlanComponent";
import {PlanArtResultStore} from "../store/PlanArtResultStore";
import {PlanReportStore} from "../store/PlanReportStore";
import Bluebird = require("bluebird");


@observer(["appUtilStore"])
export class ManagePlanProvider extends React.Component<{ appUtilStore?: AppUtilStore } & RouteStore, {}> {

  managePlanStore: ManagePlanStore;

  registerPlanArtStore: RegisterPlanArtStore;
  planCostStore: PlanCostStore;
  planArtResultStore: PlanArtResultStore;
  planReportStore: PlanReportStore;

  constructor(props) {
    super(props);
    this.managePlanStore = new ManagePlanStore();
    this.managePlanStore.planTreeStore.appUtilStore = props.appUtilStore;
    this.managePlanStore.refresh = () => {
      return this.retrieve(this.props);
    };

    this.registerPlanArtStore = new RegisterPlanArtStore(this.managePlanStore, props.appUtilStore);
    this.registerPlanArtStore.routeStore = props;

    this.planCostStore = new PlanCostStore(props.appUtilStore);

    this.planArtResultStore = new PlanArtResultStore(props.appUtilStore);

    this.planReportStore = new PlanReportStore();
    this.planReportStore.appUtilStore = props.appUtilStore;

    console.log("ManagePlanProvider:constructor", props);
  }

  retrieve(props) {
    let planId = props.match.params.planId;
    this.managePlanStore.visible = false;
    if (!_.isNaN(_.parseInt(planId))) {
      props.appUtilStore.dimmerActive = true;
      let retrieves: any[] = [
        this.managePlanStore.retrieve(planId)
          .then(() => {

            let addtionalTitle = '';
            if (this.managePlanStore.isCost) {
              addtionalTitle = "経費編集";
            } else if (this.managePlanStore.isSales) {
              addtionalTitle = "売上編集";
            } else if (this.managePlanStore.isReport) {
              addtionalTitle = "レポート";
            } else if (this.managePlanStore.isEditing) {
              addtionalTitle = "作品編集";
            } else if (this.managePlanStore.isNew) {
              addtionalTitle = "作品追加";
            }

            props.appUtilStore.title = `${addtionalTitle}【${this.managePlanStore.plan.title}】`;
          })
      ];

      if (this.managePlanStore.isCost) {
        this.planCostStore.planId = planId;
        retrieves.push(this.planCostStore.retrieve());
      } else if (this.managePlanStore.isSales) {
        this.planArtResultStore.planId = planId;
        retrieves.push(this.planArtResultStore.retrieve());
      } else if (this.managePlanStore.isReport) {
        retrieves.push(this.planReportStore.retrieve(planId));
      } else if (this.managePlanStore.isEditing || this.managePlanStore.isNew) {
        this.registerPlanArtStore.planId = planId;
        retrieves.push(this.registerPlanArtStore.retrieve());
      }

      return Bluebird.all(retrieves)
        .finally(() => {
          this.managePlanStore.visible = true;
          props.appUtilStore.dimmerActive = false;
        });
    } else {
      this.managePlanStore.plan = undefined;
      this.managePlanStore.planTreeStore.openModal = true;
      this.managePlanStore.planTreeStore.retrieve();
    }
  }


  componentDidMount() {
    this.routeStoreUpdated(this.props);
  }

  componentWillReceiveProps(props) {
    this.routeStoreUpdated(props);
  }

  routeStoreUpdated(props) {
    console.log("ManagePlanProvider#receiveProps", props);
    if (props.match.params.action === ':action') {
      props.history.push(`/plans/${props.match.params.planId}/arts/new`)
    }
    this.managePlanStore.action = props.match.params.action;
    return this.retrieve(props);
  }

  render() {
    return <RouteProvider {...this.props}>
      <Provider
        managePlanStore={this.managePlanStore}
        planTreeStore={this.managePlanStore.planTreeStore}
        registerPlanArtStore={this.registerPlanArtStore}
        planCostStore={this.planCostStore}
        planArtResultStore={this.planArtResultStore}
        planReportStore={this.planReportStore}>
        <ManagePlanComponent/>
      </Provider>
    </RouteProvider>
  }
}
