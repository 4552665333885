import * as React from "react";
import * as _ from "lodash";

import {JsonSchema, PropertySchema} from "../../defines/jsonSchema";
import {DatePickerEditor, DatePickerFormatter} from "./dataGrid/DatePicker";
import {NumberCellEditor, NumberCellFormatter} from "./dataGrid/NumberCell";
import {SimpleTextEditor} from "./dataGrid/SimpleTextEditor";
import {EditableFormatter} from "./dataGrid/FormatterBase";
import {ColumnProps, ReactElementTypes} from "./lib/VirtualizedDataGridDefinition";
import {DateTimeFormatter} from "./dataGrid/DateTime";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {SemanticDropdownEditor, SemanticDropdownFormatter} from "./dataGrid/SemanticDropdown";


const ColumnNameMap = {
  'createdAt': '作成日時',
  'updatedAt': '更新日時',
  'deletedAt': '削除日時'
};

export const SYSTEM_INVISIBLE_FIELD = [
    'planYear',
    'version',
    'deletedAt',
    'createdById',
    'updatedById',
    'deletedById',
  ];

export interface JsonSchemeScope  {
  prefix?: string;
  modelName?: string;
  schema?: JsonSchema;
  includes?: JsonSchemeScope[];
  exclude?: string[]
}

export const scopeColumnProps = (hoge: JsonSchemeScope, prefixes: string[] = [], target:ColumnProps[] = []): ColumnProps[] => {
  const propertiesArray = jsonSchemaToColumnProps({
    schema: hoge.schema,
    prefix: `${prefixes.map((p)=> p + '.').join('')}`,
    target: target,
    exclude: hoge.exclude
  });
  _.forEach(hoge.includes, (include)=>{
    return scopeColumnProps(include, prefixes.concat(include.prefix), propertiesArray);
  });
  return target;
};

export const jsonSchemaToColumnProps = (options: { schema: JsonSchema, prefix?: string, target?: ColumnProps[], exclude?: string[] }): ColumnProps[] => {
  _.defaults(options, {prefix: '', target: [], exclude: []});

  return _.reduce(options.schema.properties, (r, v: PropertySchema, key: string) => {
    let fullKey = options.prefix + key;

    if (!_.includes(options.exclude, key) && !_.includes(options.exclude, fullKey)) {
      let column: ColumnProps = {} as any;
      column.key = fullKey;
      column.type = v.type;
      column.format = v.format;
      column.name = ColumnNameMap[key] ? ColumnNameMap [key] : v.title;
      column.sortable = !v.virtual;
      column.required = _.includes(options.schema.required, key);

      if (!v.readOnly && _.isEmpty(options.prefix)) {
        column.filterable = false;
        column.editable = !v.readOnly;
        if (v.format === 'date') {
          column.editor = <DatePickerEditor/>;
          column.formatter = <DatePickerFormatter/>;
        } else if (v.format === 'int32' || v.format === 'int64') {
          column.editor = <NumberCellEditor/>;
          column.formatter = <NumberCellFormatter/>;
        } else {
          column.editor = <SimpleTextEditor/>;
          column.formatter = <EditableFormatter/>;
        }
        column.filterable = true;
        column.resizable = true;
      } else {
        if (v.format === 'date') {
          column.formatter = <DatePickerFormatter/>;
        } else if (v.format === 'int32' || v.format === 'int64') {
          column.formatter = <NumberCellFormatter/>;
        } else if(v.format === 'date-time') {
          column.formatter = <DateTimeFormatter/>;
          column.width = 130;
        }
      }

      r.push(column);
    }
    return r;
  }, options.target);
};


export const createActionCell = (formatter: (args: { dependentValues?: any, value?: any, column?: any }) => ReactElementTypes): ColumnProps => {
  return {
    key: "action",
    name: "action",
    locked: true,
    cellClass: 'for-action-cell',
    formatter: formatter,
    width: 50,
    headerRenderer: () => {
      return <div style={{
        paddingTop: "8px",
        paddingLeft: "12px",
      }}>
        <i className={"list icon"}/>
      </div>
    }
  }
};

export const valueGetter = function (v, getter: any) {
  let value;
  if (_.isFunction(getter)) {
    value = getter(v);
  } else {
    value = v[getter];
  }
  return value;
}

export const convertDropdownItem = function(target: any, labelKey: any = 'name', valueKey: any = 'id'): DropdownItemProps {
  let label = valueGetter(target, labelKey);
  let value = valueGetter(target, valueKey);
  return {key: value, value: value, text: label};
}




export function autoCompleteEditor(props: any) {
  return <SemanticDropdownEditor {...props}/>
}

export function autoCompleteFormatter(props: any) {
  return <SemanticDropdownFormatter  {...props} />
}
