import * as Bluebird from "bluebird";

import * as superagent from "superagent";

export interface SAResponse<T> {
  body: T;
  headers: { [index: string]: string }
}

export interface SuperagentPromise {
  // method, url form with `then`
  (method: string, url: string): Bluebird<any>;

  // helper functions: options, head, get, post, put, patch, del
  del(url: string, data?: any): Bluebird<any>;
  del(url: string, data?: any, schema?: any): Bluebird<any>;
  get(url: string, data?: any): Bluebird<any>;
  get(url: string, data?: any, schema?: any): Bluebird<any>;
  get<T>(url: string, data?: any): Bluebird<SAResponse<T>>;
  get<T>(url: string, data?: any, schema?: any): Bluebird<SAResponse<T>>;
  head(url: string, data?: any): Bluebird<any>;
  options(url: string, data?: any): Bluebird<any>;
  patch(url: string, data?: any): Bluebird<any>;
  post(url: string, data?: any): Bluebird<any>;
  post(url: string, data?: any, schema?: any): Bluebird<any>;
  put(url: string, data?: any): Bluebird<any>;
  put(url: string, data?: any, schema?: any): Bluebird<any>;

  // cutome method;
  getc<T>(url: string, data?: any): Bluebird<SAResponse<T>>;
  getc(url: string, data?: any): Bluebird<any>;
  getc(url: string, data?: any, schema?: any): Bluebird<any>;
}

export const agent: SuperagentPromise = require('superagent-promise')(superagent, Bluebird);

let cache = {};

agent.getc = (url: string, data?: any): Bluebird<any> => {
  if (!cache[url]) {
    cache[url] = new Bluebird((resolve, reject) => {
      agent
        .get(url, data)
        .then(resolve)
        .catch((reason) => {
          reject(reason);
          cache = {};
        });
    });

  }
  return cache[url];
};
