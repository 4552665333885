import * as React from "react";
import {RouteProvider} from "../providers/RouteProvider";
import {PlanCalendarProvider} from "../providers/PlanCalendarProvider";

export class Index extends React.Component<any, any> {

  render() {
    console.log("Index#render", this.props)
    return <RouteProvider {...this.props}>
      <PlanCalendarProvider />
    </RouteProvider>

  }

}
