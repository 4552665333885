import * as React from "react";
import {inject, observer, Provider} from "mobx-react";
import {PlanCalendarComponent} from "../components/PlanCalendarComponent";
import {PlanCalendarStore} from "../store/PlanCalendarStore";
import {AppUtilStore} from "../store/AppUtilStore";

@inject("appUtilStore")
@observer
export class PlanCalendarProvider extends React.Component<{ appUtilStore?: AppUtilStore }, {}> {

  planCalendarStore: PlanCalendarStore;

  constructor(props) {
    super(props);
    this.planCalendarStore = new PlanCalendarStore(props.appUtilStore);
  }

  render() {
    return <Provider
      planCalendarStore={this.planCalendarStore}>
      <PlanCalendarComponent />
    </Provider>
  }
}
