import {computed, observable} from "mobx";
import {SAResponse} from "../../utils/agent";
import {RouteStore} from "../../providers/RouteProvider";
import * as React from "react";
import {AppUtilStore} from "../AppUtilStore";
import {IArtCostReceipt} from "../../../main/models/ArtCostReceipt";


export class ManageStore {

  @observable.ref visible: boolean;
  @observable.ref artCostReceipt: IArtCostReceipt;

  get agent() {
    return this.appUtilStore.agent;
  }

  get action() {
    return this.routeStore.match.params.action;
  }

  get artCostReceiptId() {
    return this.routeStore.match.params.artCostReceiptId;
  }

  constructor(public routeStore: RouteStore, private appUtilStore: AppUtilStore) {
  }

  retrieve() {
    this.visible = false;

    return this.agent.get(`/rest/artCostReceipts/${this.artCostReceiptId}`)
      .then((artCostReceipt: SAResponse<IArtCostReceipt>) => {
        this.artCostReceipt = artCostReceipt.body;

        if (this.isEdit) {
          this.appUtilStore.title = this.artCostReceipt.title + "経費伝票作品編集";
        } else if (this.isNew) {
          this.appUtilStore.title = this.artCostReceipt.title + "経費伝票作品追加";
        }

        this.visible = true;
      });

  }

  @computed
  get isInit(): boolean {
    return this.action === ':action';
  }

  @computed
  get isEdit(): boolean {
    return this.action === 'edit';
  }

  @computed
  get isNew(): boolean {
    return this.action === 'new';
  }

}
