import * as React from "react";

import * as _ from "lodash";

import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import MultiGrid from "react-virtualized/dist/commonjs/MultiGrid";
import ArrowKeyStepper from "react-virtualized/dist/commonjs/ArrowKeyStepper";
import {ColumnProps, FilterEvent, GridRowsUpdatedEvent} from "./VirtualizedDataGridDefinition";
import {VirtualizedDataGridCell} from "./VirtualizedDataGridCell";

export type SortTypes = ('NONE' | 'ASC' | 'DESC');

export class VirtualizedDataGrid extends React.Component<{
  updateRef?: any,
  columns: Array<ColumnProps>
  rowsCount: number
  rowGetter: (i: number) => any
  onGridSort?: (sortColumn: string, sortOrder: SortTypes) => void
  onGridRowsUpdated?: (e: GridRowsUpdatedEvent) => void
  onCellKeyDown?: (e: KeyboardEvent, rowIndex: number, columnIndex: number) => any
  fixedColumnCount: number
  height: number
  onAddFilter?: (e: FilterEvent) => any
  onClearFilters?: () => void
  sortDirection?: SortTypes,
  sortColumn?: string
}, {
  scrollToColumn: number,
  scrollToRow: number,
  fixedRowCount: number,
  updateKey: number,
}> {

  constructor(props) {
    super(props);
    this.state = {
      scrollToColumn: 0,
      scrollToRow: 0,
      fixedRowCount: 1,
      updateKey: 0,
    };

  }

  headerCellRenderer({
                       columnIndex, // Horizontal (column) index of cell
                       isVisible,   // This cell is visible within the grid (eg it is not an overscanned cell)
                       key,         // Unique key within array of cells
                       parent,      // Reference to the parent Grid (instance)
                       rowIndex,    // Vertical (row) index of cell
                       style        // Style object to be applied to cell (to position it);
                                    // This must be passed through to the rendered cell element.
                     }) {

    let column: ColumnProps = this.props.columns[columnIndex];

    // if (column.width) {
    //   if (!this.cache.has(rowIndex, columnIndex)) {
    //     this.cache.set(rowIndex, columnIndex, column.width, this.props.height);
    //   }
    // }

    let className = "ReactVirtualized__Grid__innerScrollContainer__Header__Cell";
    if (rowIndex === parent.props.fixedRowCount - 1) {
      className += " ReactVirtualized__Grid__innerScrollContainer__Header__Cell--LastRow"
    }
    if (!column.headerRenderer) {
      className += " ReactVirtualized__Grid__innerScrollContainer__Header__Cell--DefaultRenderer"
    }
    if (parent.props.fixedColumnCount > 0) {
      if (columnIndex === parent.props.fixedColumnCount - 1) {
        className += " ReactVirtualized__Grid__innerScrollContainer__Header__Cell--LastColumn"
      } else if (columnIndex === parent.props.fixedColumnCount) {
        className += " ReactVirtualized__Grid__innerScrollContainer__Header__Cell--FixedFirstColumn"
      }
    }
    if (columnIndex === this.props.columns.length - 1) {
      className += " ReactVirtualized__Grid__innerScrollContainer__Body__Cell--LastColumn"
    }

    let iconClass = null;
    if (this.props.sortColumn === column.key) {
      if (this.props.sortDirection === 'ASC') {
        iconClass = "sort alphabet ascending";
      } else if (this.props.sortDirection === 'DESC') {
        iconClass = "sort alphabet descending";
      }
    }

    return <div className={className} key={key}
                onClick={() => {
                  console.log("click")
                  if (column.sortable && this.props.onGridSort) {

                    let sortTypes: Array<SortTypes> = ['ASC', 'DESC'];
                    let next = {
                      sortDirection: this.props.sortDirection,
                      sortColumn: this.props.sortColumn
                    };

                    if (next.sortColumn !== column.key) {
                      next.sortColumn = column.key;
                      next.sortDirection = 'DESC';
                    } else {
                      next.sortDirection = sortTypes[(sortTypes.indexOf(next.sortDirection) + 1) % 2];
                    }

                    this.props.onGridSort(next.sortColumn, next.sortDirection);
                  }
                }}
                style={style}>
      <div>{column.headerRenderer ?
        column.headerRenderer({column: column}) :
        column.name}</div>

      <div className="ui right label">
        <i className={"icon " + iconClass}/>
      </div>
    </div>
  }

  bodyCellRenderer({
                     columnIndex, // Horizontal (column) index of cell
                     isVisible,   // This cell is visible within the grid (eg it is not an overscanned cell)
                     key,         // Unique key within array of cells
                     parent,      // Reference to the parent Grid (instance)
                     rowIndex,    // Vertical (row) index of cell
                     style,        // Style object to be applied to cell (to position it);
                     // This must be passed through to the rendered cell element.
                   }) {

    let column = this.props.columns[columnIndex];
    if (!column) {
      return null;
    }
    let rowNum = rowIndex - parent.props.fixedRowCount;
    let dependentValues = this.props.rowGetter(rowNum) || {};
    let ref = null;
    let className = "ReactVirtualized__Grid__innerScrollContainer__Body__Cell";
    if (this.state.scrollToRow === rowIndex && this.state.scrollToColumn === columnIndex) {
      className += " ReactVirtualized__Grid__innerScrollContainer__Body__Cell--selected";
      ref = (node: any) => {
        if (node) {
          node.focus();
        }
      }
    }
    if (rowIndex === parent.props.fixedRowCount) {
      className += " ReactVirtualized__Grid__innerScrollContainer__Body__Cell--TopRow"
    }

    if (rowIndex === parent.props.rowCount - 1) {
      className += " ReactVirtualized__Grid__innerScrollContainer__Body__Cell--LastRow"
    }

    if (parent.props.fixedColumnCount > 0) {
      if (columnIndex === parent.props.fixedColumnCount - 1) {
        className += " ReactVirtualized__Grid__innerScrollContainer__Body__Cell--LastColumn"
      } else if (columnIndex === parent.props.fixedColumnCount) {
        className += " ReactVirtualized__Grid__innerScrollContainer__Body__Cell--FixedFirstColumn"
      }
    }
    if (columnIndex === this.props.columns.length - 1) {
      className += " ReactVirtualized__Grid__innerScrollContainer__Body__Cell--LastColumn"
    }

    return this.props.rowsCount > 0 &&
      <VirtualizedDataGridCell
        key={key}
        className={className}
        _ref={ref}
        _key={key}
        style={style}
        fixedRowCount={parent.props.fixedRowCount}
        fixedColumnCount={parent.props.fixedColumnCount}
        rowIndex={rowIndex}
        column={column}
        columnIndex={columnIndex}
        dependentValues={dependentValues}
        onSelectCell={this.selectCell.bind(this)}
        onGridRowsUpdated={this.props.onGridRowsUpdated}
        onCellKeyDown={this.props.onCellKeyDown}
      />

  }

  cellRenderer(props) {

    if (props.parent.props.fixedRowCount > props.rowIndex) {
      if (props.rowIndex === 0) {
        return this.headerCellRenderer(props);
      }

    } else {
      return this.bodyCellRenderer(props);
    }
  }

  selectCell({scrollToColumn, scrollToRow}) {
    console.log("selectCell", scrollToColumn, scrollToRow);
    // if (scrollToColumn < this.props.fixedColumnCount) {
    //   scrollToColumn = this.state.scrollToColumn;
    // }
    if (scrollToRow < this.state.fixedRowCount) {
      scrollToRow = this.state.scrollToRow;
    }
    this.setState({scrollToColumn, scrollToRow});
  }

  componentWillUpdate(nextProps) {
    console.log("componentWillUpdate", nextProps)
    // let needCacheClear: boolean;
    // if (this.props.columns.length === nextProps.columns.length) {
    //   _.each(this.props.columns, (column, index) => {
    //     let nextColumn = nextProps.columns[index];
    //
    //     if (column.width !== nextColumn.width) {
    //       needCacheClear = true;
    //     }
    //   });
    // } else {
    //   needCacheClear = true;
    // }
    //
    // if (needCacheClear) {
    //   this.cache.clearAll();
    // }
  }

  render() {
    console.log("render", this)
    return <div><ArrowKeyStepper
      columnCount={this.props.columns.length}
      isControlled={true}
      onScrollToChange={this.selectCell.bind(this)}
      mode={"cells"}
      rowCount={this.props.rowsCount + this.state.fixedRowCount}
      scrollToColumn={this.state.scrollToColumn}
      scrollToRow={this.state.scrollToRow}>
      {({onSectionRendered, scrollToColumn, scrollToRow}) => {

        return <AutoSizer disableHeight>
          {({width}) => {
            console.log("AutoSizer#render", this);
            return <div className="VirtualizedDataGrid">
              <MultiGrid
                onSectionRendered={onSectionRendered}
                columnWidth={({index}) => {
                  return _.min([this.props.columns[index].width, 250]);
                }}
                cellRenderer={this.cellRenderer.bind(this)}
                columnCount={this.props.columns.length}
                fixedColumnCount={this.props.fixedColumnCount}
                fixedRowCount={this.state.fixedRowCount}
                height={this.props.height}
                rowHeight={36}
                rowCount={this.props.rowsCount === 0 ? 1 + this.state.fixedRowCount : this.props.rowsCount + this.state.fixedRowCount}
                width={width}
                scrollToColumn={scrollToColumn}
                scrollToRow={scrollToRow}
                overscanRowCount={5}
                overscanColumnCount={2}
                tabIndex={null}
                updateRef={this.props.updateRef}
                updateKey={this.state.updateKey}
              />
            </div>
          }}
        </AutoSizer>
      }}
    </ArrowKeyStepper>
    </div>
  }
}

