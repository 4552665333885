import {Treebeard} from "react-treebeard";
import {observable} from "mobx";
import {RouteStore} from "../providers/RouteProvider";
import {IArt} from "../../main/models/Art";
import * as React from "react";
import {AppUtilStore} from "./AppUtilStore";
import {DataGridBaseStore} from "./dataGrid/DataGridBaseStore";
import {JsonSchemeScope} from "../components/Utilities";
import {SemanticCOLORS, SemanticICONS} from "semantic-ui-react";


type IRegisterArt = IArt & {
  _message?: string;
  get?: (key: any) => any
}

export class StockDetailArtStore extends DataGridBaseStore<IRegisterArt> {

  @observable.ref visible: boolean;

  constructor(public routeStore: RouteStore, appUtilStore: AppUtilStore) {
    super(appUtilStore);
  }

  customSchemaScope(schemaScope: JsonSchemeScope) {
    schemaScope.includes.push(
      {
        prefix: 'author',
      },
    );
    schemaScope.includes.push(
      {
        prefix: 'artResult',
      },
    );
    schemaScope.includes.push(
      {
        prefix: 'stockDetail',
        includes: [
          {
            prefix: 'stock',
          }
        ]
      },
    );
    return schemaScope;
  }

  get entityPluralLabel(): string {
    return 'arts';
  }

  get recordQuery(): string {
    const params = [
      `scope=full`,
      `$stockDetail.stock.id$=${this.stockId}`,
      `sort=id`,
    ];
    return `?` + (window.location.search || '?').substring(1).split('&').concat(params).join('&')
  }

  get stockId() {
    return this.routeStore.match.params.stockId;
  }

  retrieve() {
    this.visible = false;
    return super.retrieve()
      .then(() => {
        this.visible = true;
      });
  }

  getActionIconProp(dependentValues: any): { name: SemanticICONS, color: SemanticCOLORS } {
    const name: SemanticICONS = dependentValues.art &&
    dependentValues.art.artResult ?
      'gift' : 'list';

    const color: SemanticCOLORS = dependentValues.isUpdated ? 'red' : 'black';

    return {name, color};
  }

  getAdditionalCellActions(dependentValues): JSX.Element[] {
    const children = super.getAdditionalCellActions(dependentValues);
    children.push(
      <div key="artHistory" className="item" onClick={() => {
        this.routeStore.history.push(`/entity/planArts?scope=forRestListReadOnly&artId=${dependentValues.id}`)
      }}>作品履歴</div>
    );
    return children;
  }
}
