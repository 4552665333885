import * as React from "react";
import {observer} from "mobx-react";

import * as _ from "lodash";

import {RouteStore} from "../providers/RouteProvider";
import {AppUtilStore} from "../store/AppUtilStore";
import {VirtualizedDataGrid} from "./lib/VirtualizedDataGrid";
import {DateUtils} from "../../utils/dateUtils";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import {EditArtStore, RegisterNewArtStore} from "../store/costReceipt/RegisterArtStore";
import {ManageStore} from "../store/costReceipt/ManageStore";

@observer(["manageStore",
  "registerNewArtStore",
  "editArtStore",
  "routeStore",
  "appUtilStore",
])
export class ManageCostReceiptComponent extends React.Component<{
  manageStore?: ManageStore,
  registerNewArtStore?: RegisterNewArtStore;
  editArtStore?: EditArtStore;
  routeStore?: RouteStore,
  appUtilStore?: AppUtilStore,
}, {}> {

  bindCellKeyDownToStore(store: { updateByIndex: (number) => any }) {
    return (e: KeyboardEvent, rowIndex: number, columnIndex: number) => {
      if (e.keyCode === 13 && e.ctrlKey) {
        store.updateByIndex(rowIndex);
        return false;
      }
      return true;
    }
  }

  grid: any;

  constructor(props) {
    super(props);
    console.log("RegisterPlanArt", props);
  }

  promiseCaches = {};


  render() {
    let toolbar: JSX.Element = null;
    if (this.props.manageStore.isEdit) {
      toolbar = !_.isUndefined(this.props.manageStore.artCostReceipt) && <Grid>
        <Grid.Column floated='right' width={11}>
          <div style={{textAlign: "right"}}>
            <Button floated="right" onClick={() => {
              this.props.editArtStore.saveRows()
            }} icon="save"/>
          </div>
        </Grid.Column>
      </Grid>;
    } else if (this.props.manageStore.isNew) {
      toolbar = !_.isUndefined(this.props.manageStore.artCostReceipt) &&
        <Grid>
          <Grid.Column floated='left' width={5}>
            <span>作品ID: </span>
            <Input onKeyDown={(e) => {
              let promise = this.props.registerNewArtStore.addArtKeyDown(e);
              if (promise) {
                e.persist();
                promise.finally(() => {
                  e.target.focus();
                });
              }
            }}/>
          </Grid.Column>

          <Grid.Column floated='right' width={11}>
            <div style={{textAlign: "right"}}>
              <Button floated="right"
                      onClick={this.props.registerNewArtStore.registerArtCosts} icon="save"/>
            </div>
          </Grid.Column>
        </Grid>
    }

    const tabChange = (action) => {
      if (this.props.manageStore.action !== action) {
        this.props.routeStore.history.push(`/artCostReceipts/${this.props.manageStore.artCostReceipt.id}/arts/${action}`)
      }
    };

    return <div>
      <Segment.Group horizontal>
        <Segment>
          {!_.isUndefined(this.props.manageStore.artCostReceipt) &&
            <span>
            <span><h3>{this.props.manageStore.artCostReceipt.title} {this.props.manageStore.artCostReceipt.note}</h3></span>
            <span><h4>{DateUtils.format(this.props.manageStore.artCostReceipt.receiptDate)}</h4></span>
            </span>
          }
        </Segment>
        <Segment textAlign='right' className='button container no-border'>
          <Button onClick={() => {
            this.props.routeStore.history.push(`/entity/artCostReceipts?id=${this.props.manageStore.artCostReceipt.id}`)
          }} icon="list"/>
        </Segment>
      </Segment.Group>

      {this.props.manageStore.visible &&
        <div>
          <div className="ui top attached tabular menu">
            <a className={(this.props.manageStore.isNew ? 'active' : '') + " item"}
               onClick={tabChange.bind(this, 'new')}>追加
            </a>
            <a className={(this.props.manageStore.isEdit ? 'active' : '') + " item"}
               onClick={tabChange.bind(this, 'edit')}>編集
            </a>
          </div>

          <div className="ui bottom attached active tab segment" style={{display: 'block'}}>
            {this.props.manageStore.isNew &&
            this.props.registerNewArtStore.visible &&
            this.props.registerNewArtStore.records &&
              <div>
                {toolbar}

                <VirtualizedDataGrid
                  updateRef={this.props.registerNewArtStore.records}
                  columns={this.props.registerNewArtStore.columns}
                  rowsCount={this.props.registerNewArtStore.rowsCount}
                  rowGetter={this.props.registerNewArtStore.rowGetter}
                  onGridRowsUpdated={this.props.registerNewArtStore.onGridRowsUpdated}
                  onCellKeyDown={this.bindCellKeyDownToStore(this.props.registerNewArtStore)}
                  fixedColumnCount={4}
                  onGridSort={this.props.registerNewArtStore.onGridSort}
                  sortColumn={this.props.registerNewArtStore.sortColumn}
                  sortDirection={this.props.registerNewArtStore.sortDirection}
                  height={this.props.appUtilStore.height - 300}
                />

              </div>}

            {this.props.manageStore.isEdit &&
            this.props.editArtStore.visible &&
            this.props.editArtStore.records &&
              <div>
                {toolbar}

                <VirtualizedDataGrid
                  updateRef={this.props.editArtStore.records}
                  columns={this.props.editArtStore.columns}
                  rowsCount={this.props.editArtStore.rowsCount}
                  rowGetter={this.props.editArtStore.rowGetter}
                  onGridRowsUpdated={this.props.editArtStore.onGridRowsUpdated}
                  onCellKeyDown={this.bindCellKeyDownToStore(this.props.editArtStore)}
                  fixedColumnCount={4}
                  onGridSort={this.props.editArtStore.onGridSort}
                  sortColumn={this.props.editArtStore.sortColumn}
                  sortDirection={this.props.editArtStore.sortDirection}
                  height={this.props.appUtilStore.height - 300}
                />

              </div>}
          </div>

        </div>}

    </div>
  }
}
