import * as React from "react";
import * as _ from "lodash";

import {Treebeard} from "react-treebeard";
import {computed, observable} from "mobx";
import {JsonSchemeScope} from "../components/Utilities";
import {IArtResult} from "../../main/models/ArtResult";
import {DataGridBaseStore} from "./dataGrid/DataGridBaseStore";
import {GridRowsUpdatedEvent} from "../components/lib/VirtualizedDataGridDefinition";
import {IStaff} from "../../main/models/Staff";


export class PlanArtResultStore extends DataGridBaseStore<IArtResult> {

  @observable planId: number;

  customSchemaScope(schemaScope: JsonSchemeScope) {
    schemaScope.includes.push(
      {
        prefix: 'art',
        includes: [{
          prefix: 'author',
        }]
      }
    );
    return schemaScope;
  }

  get entityPluralLabel(): string {
    return 'artResults';
  }

  get recordQuery(): string {
    return `?scope=forRestList&sort=-id&count=1000&$planArt.planId$=${this.planId}`;
  }

  additionalOnGridRowsUpdated(e: GridRowsUpdatedEvent): any {
    const additional: any = super.additionalOnGridRowsUpdated(e);
    const srcRow = this.records[e.toRow];
    if (e.cellKey === 'priceRatio') {
      const newValue = e.updated[e.cellKey];
      additional.depositPrice = Math.floor(srcRow.soldPrice * newValue / 100);
      additional.chargePrice = Math.floor(srcRow.soldPrice * srcRow.chargeRatio / 100);
    } else if (e.cellKey === 'soldPrice') {
      const newValue = e.updated[e.cellKey];
      additional.depositPrice = Math.floor(srcRow.priceRatio * newValue / 100);
      additional.chargePrice = Math.floor(newValue * srcRow.chargeRatio / 100);
    } else if (e.cellKey === 'depositPrice') {
      const newValue = e.updated[e.cellKey];
      additional.priceRatio = Math.floor(newValue / srcRow.soldPrice * 100);
      additional.chargePrice = Math.floor(srcRow.soldPrice * srcRow.chargeRatio / 100);
    } else if (e.cellKey === 'soldStaffId') {
      console.log('soldStaffId', e)
      const foundStaff: IStaff = _.find(this.schemaSuggestionsOriginal[e.cellKey], (schemaSuggestion) => {
        return e.updated[e.cellKey] === schemaSuggestion.id;
      });
      if (foundStaff) {
        additional.chargeRatio = foundStaff.chargeRatio;
      } else {
        additional.chargeRatio = 0;
      }
      additional.chargePrice = Math.floor(srcRow.soldPrice * additional.chargeRatio / 100);
    } else if (e.cellKey === 'chargeRatio') {
      const newValue = e.updated[e.cellKey];
      additional.chargePrice = Math.floor(srcRow.soldPrice * newValue / 100);
    }else if (e.cellKey === 'chargePrice') {
      const chargePrice = e.updated[e.cellKey];
      additional.chargeRatio = Math.floor(chargePrice / srcRow.soldPrice * 100);
    }
    return additional
  }

  @computed
  get visibleAndOrderColumnKeys(): string[] {
    return [
      'action',
      'artId',
      'soldDate',
      'soldStaffId',
      'soldPrice',
      'priceRatio',
      'depositPrice',
      'chargeRatio',
      'chargePrice',
      'art.title',
      'art.author.name',
      'note',
      'createdAt',
      'updatedAt'
    ];
  }

}
