import * as _ from "lodash";

import {PropertySchema} from "../../defines/jsonSchema";
import {ColumnProps} from "../components/lib/VirtualizedDataGridDefinition";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {SemanticDropdownEditor} from "../components/dataGrid/SemanticDropdown";

export interface ElementCreator {
  (arg: { dependentValue?: any, value?: any, column?: ColumnProps }): JSX.Element | Element;
}


export interface ColumnPropertySchema extends PropertySchema {
  key: string;
}

export const transformSchemaToArray = (options: { jsonSchemas: { [index: string]: PropertySchema }, prefix?: string, target?: ColumnPropertySchema[], exclude?: string[] }): ColumnPropertySchema[] => {
  _.defaults(options, {jsonSchemas: {}, prefix: '', target: [], exclude: []});
  return _.transform(options.jsonSchemas, (r, n: any, key) => {
    let fullKey = options.prefix + key;
    if (!_.includes(options.exclude, key) && !_.includes(options.exclude, fullKey)) {
      n.key = fullKey;
      r.push(n);
    }
  }, options.target);
};

export const columnGetter = (row: any, key: string) => {
  return key
    .split('.')
    .reduce((o, i) => {
      return o ? o[i] : row[key];
    }, row)
};


export const adjustColumnsWidth = (columns: ColumnProps[],
                                   rowsCount: number,
                                   rowGetter: (i) => any,
                                   autoCompleteFields?: any) => {

  _.each(columns, (column) => {
    if (!column.width) {

      let maxColumnLen = _.max([(column.name || '').length, 5]); // default size effected
      if (column.editable && autoCompleteFields && autoCompleteFields[column.key]) {
        const autoCompleteField = autoCompleteFields[column.key];
        _.forEach(autoCompleteField.schema.suggestions, (suggestion: DropdownItemProps) => {
          maxColumnLen = _.max([maxColumnLen, (suggestion.text + '').length]);
        });
      } else if (column.editor && column.editor.type === SemanticDropdownEditor) {
        const dropdownEditor = column.editor as any as SemanticDropdownEditor;
        _.forEach(dropdownEditor.props.suggestions, (suggestion: DropdownItemProps) => {
          maxColumnLen = _.max([12, maxColumnLen, (suggestion.text + '').length]);
        });
      } else {
        for (let i = 0; i < rowsCount; i++) {
          const row = rowGetter(i);
          maxColumnLen = _.max([maxColumnLen, (columnGetter(row, column.key) || '').length]);
        }
      }
      column.width = maxColumnLen * 14 + 30;
    }

  });
}

export const orderColumns = (cellSpecificTransformed: ArrayLike<ColumnProps>, order: string[]) => {
  return _.orderBy(cellSpecificTransformed, (v1) => {
    return _.includes(order, v1.key) ? order.indexOf(v1.key) : Number.MAX_VALUE;
  });
};

export const getRowMetadata = (row) => row;
