import * as React from "react";

import * as BigCalendarImported from "react-big-calendar";

import * as moment from "moment";
import {observer} from "mobx-react";
import {PlanCalendarStore} from "../store/PlanCalendarStore";

import * as mobx from "mobx";
import {AppUtilStore} from "../store/AppUtilStore";
import {RouteStore} from "../providers/RouteProvider";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";

// FIXME; There is different default oaaaf react-big-calendar loading (?) system at node12 or wepackc4 ?
const BigCalendar = BigCalendarImported.default ? BigCalendarImported.default : BigCalendarImported;
const localizer = BigCalendar.momentLocalizer(moment);

@observer(["planCalendarStore", "appUtilStore", "routeStore"])
export class PlanCalendarComponent extends React.Component<{
  planCalendarStore?: PlanCalendarStore
  appUtilStore?: AppUtilStore
  routeStore?: RouteStore
}, any> {

  showDropdown(dependentValues, event) {

    return this.props.appUtilStore.showElementFixed(event,
      <Dropdown icon={null}
                ref={(node: any) => {
                  if (node) {
                    node.ref.current.click()
                  }
                }}>
        <Dropdown.Menu>
          <Dropdown.Item key="editPlan" onClick={() => {
            this.props.routeStore.history.push(`/entity/plans?id=${dependentValues.id}`);
          }}>編集</Dropdown.Item>
          <Dropdown key="plans" text='出展' pointing='left' className='link item'>
            <Dropdown.Menu>
              <Dropdown.Item key="addPlanArt" onClick={() => {
                this.props.routeStore.history.push(`/plans/${dependentValues.id}/arts/new`);
              }}>追加</Dropdown.Item>
              <Dropdown.Item key="editPlanArt" onClick={() => {
                this.props.routeStore.history.push(`/plans/${dependentValues.id}/arts/edit`);
              }}>編集</Dropdown.Item>
              <Dropdown.Item key="editPlanCost" onClick={() => {
                this.props.routeStore.history.push(`/plans/${dependentValues.id}/arts/cost`);
              }}>経費</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </Dropdown.Menu>

      </Dropdown>
    )
  }

  render() {
    let colors = [
      'firebrick',
      'indigo',
      'orchid',
      'peru',
      'sandybrown',
      'tomato',
      'mediumorchid',
      'yellowgreen',
      'midnightblue',
    ];
    return <div>
      <BigCalendar
        style={{height: 700}}
        views={['month']}
        localizer={localizer}
        startAccessor='startDate'
        endAccessor='endDate'
        date={this.props.planCalendarStore.currentDate}
        onSelectEvent={this.showDropdown.bind(this)}
        onNavigate={this.props.planCalendarStore.onNavigate}
        events={mobx.toJS(this.props.planCalendarStore.events)}
        eventPropGetter={(event: any,
                          start: Date,
                          end: Date,
                          isSelected: boolean) => {

          if (!event.color) {
            event.color = colors.pop()
          }
          return {
            style: {
              backgroundColor: event.color
            }
          }

        }}
      />
    </div>
  }
}
