import * as React from "react";

import {observer, Provider} from "mobx-react";
import {computed, observable} from "mobx";
import * as _ from "lodash";

export class RouteStore<M = any> {
  @observable.ref match: M = {} as M;
  @observable.ref location: any = {};
  @observable.ref history: any = {};


  @computed
  get query(): any {
    const location = this.location;
    return location.search ? _.reduce(location.search.split('?')[1].split('&'), (result, val) => {
      const equals = val.split('=');
      if (equals.length = 2) {
        result[equals[0]] = equals[1];
      }
      return result;
    }, {}) : {};
  }
}

@observer
export class RouteProvider extends React.Component<any, {}> {

  routeStore: RouteStore;

  constructor(props) {
    super(props);
    console.log("RouteProvider:constructor", props);
    this.routeStore = new RouteStore();
    this.updateState(props);
  }

  updateState(props) {
    this.routeStore.match = props.match;
    this.routeStore.location = props.location;
    this.routeStore.history = props.history;
  }

  componentWillReceiveProps(props) {
    console.log("componentWillReceiveProps", props)
    this.updateState(props);
  }

  render() {
    return <Provider routeStore={this.routeStore}>
      {this.props.children}
    </Provider>
  }

}

export const WithRoute = (RouteStoreExpectedProvider) => {
  return (props)=>{
    return <RouteProvider {...props}>
      <RouteStoreExpectedProvider />
    </RouteProvider>
  }
}
