import * as React from "react";
import * as _ from "lodash";
import {ColumnProps, FilterEvent} from "./VirtualizedDataGridDefinition";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";


export class VirtualizedDataGridFilterCell extends React.Component<{
  column: ColumnProps,
  style: any
  className?: string
  _key: string
  _ref?: (node: HTMLDivElement) => any
  onAddFilter: (e: FilterEvent) => any

}, { editing: boolean }> {

  constructor(props) {
    super(props);
    this.state = {editing: false};
  }

  editor;

  setTextInputFocus() {
    let inputNode = this.editor.getInputNode();
    if (inputNode) {
      inputNode.focus();
      if (inputNode.tagName === 'INPUT') {
        inputNode.select();
      }

    }
  }

  componentDidUpdate() {
    if (this.state.editing) {
      this.setTextInputFocus();
    }
  }

  render() {
    const {
      column,
      className,
      _key,
      _ref,
      style,
      onAddFilter
    } = this.props;


    let filterElement = null;


    if (this.props.column.filterable) {
      if (column.filterRenderer) {

        const formatterProp = {
          column: this.props.column,
          onChange: onAddFilter // TODO: rename
        };

        if (React.isValidElement(column.filterRenderer)) {
          filterElement = React.cloneElement(column.filterRenderer as any, formatterProp);
        } else if (_.isFunction(column.filterRenderer)) {
          const ColumnFilter = column.filterRenderer as any;
          filterElement = <ColumnFilter {...formatterProp} />
        }
      } else {
        filterElement = <div style={{overflow: "hidden"}}>
          <Input className={'ReactVirtualized__Grid__innerScrollContainer__Filter__Cell--DefaultRenderer'}
                 onChange={(e, data) => {
                   onAddFilter({filterTerm: data.value, column: column})
                 }}/>
        </div>
      }

    } else {
      filterElement = <span/>;
    }

    return <div className={className}
                key={_key}
                ref={_ref}
                style={style}>{filterElement}</div>
  }

}
