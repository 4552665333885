import * as React from "react";
import * as _ from "lodash";
import DatePicker from "react-datepicker";
import {EditorBase, HandleKeyWrapper} from "./EditorBase";
import * as moment from "moment";
import {EditableFormatter, FormatterBase} from "./FormatterBase";


const validMoment = (value) => {

  if (!(
    _.isDate(value) &&
    !(_.isString(value) && _.isEmpty(value)) ||
    !_.isUndefined(value)
  )) {
    return undefined;
  }

  let momentValue = moment(value);
  if (momentValue.isValid()) {
    return momentValue.toDate();
  } else {
    return undefined;
  }
}

export class DatePickerFormatter extends FormatterBase<any, any> {

  format() {
    let value = validMoment(this.props.value);
    return value ? moment(value).format('YYYY/MM/DD') : '';
  }

  render() {
    return <EditableFormatter {...this.props} value={this.format()}/>
  }
}

export class DatePickerEditor extends EditorBase<{
  format?: Array<any>,
  selectsStart?: boolean,
  value?: any,
  column?: any,
  onBlur?: Function,
  rowData?: any
}, { value: Date }> {

  constructor(props) {
    super(props);
    this.state = {
      value: validMoment(props.value)
    };
  }

  getValue(): any {
    let updated = {};
    updated[this.props.column.key] = this.state.value ? moment(this.state.value).format('YYYY-MM-DD') : '';
    return updated;
  }

  handleChange(date) {
    console.log("handleChaaaaange");
    this.setState({
      value: date
    });
  }

  handleKeyDown(e) {
    if (this.datePicker && !this.datePicker.isCalendarOpen() && e.which === 13) {
      this.props.onBlur();
    }
  }

  handleBlur(e) {
    if (this.datePicker && !this.datePicker.isCalendarOpen()) {
      this.props.onBlur();
    }
  }

  datePicker: DatePicker;

  render() {
    return <HandleKeyWrapper noStopKeys={[9, 27]}>
      <DatePicker
        ref={(d) => {
          this.datePicker = d;
        }}
        className='form-control'
        dateFormat='yyyy/MM/dd'
        selected={this.state.value}
        onChange={this.handleChange.bind(this)}
        onKeyDown={this.handleKeyDown.bind(this)}
        onBlur={this.handleBlur.bind(this)}
      />
    </HandleKeyWrapper>
  }
}

