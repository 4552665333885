import * as coreJs from "core-js" // /library";
import * as _ from "lodash";

export namespace StringUtils {

  export function toAscii(value: string) {
    return value.replace(/[！-～]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    })
  }

  export function toHalfNumber(value: string) {

    return value.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
  }

  export function strLength(str) {
    if (_.isEmpty(str)) {
      return 0;
    }
    return coreJs.Array.from(str).length;
  };

  export function strWidth(str) {
    str = "" + str;
    if (_.isEmpty(str)) {
      return 0;
    }
    return _.reduce(coreJs.Array.from(str), (width, c)=>{
      if(isHanHankaku(c)) {
        return width + 1;
      } else {
        return width + 2;
      }
    }, 0);
  }

  function isHanHankaku(c){
    const code = c.charCodeAt(0);
    let f: boolean;
    f  = (code >= 0x0 && code < 0x81);
    f = f || (code == 0xf8f0);
    f = f || (code >= 0xff61 && code < 0xffa0);
    f = f || (code >= 0xf8f1 && code < 0xf8f4);
    return !!f;
  }


}
