import {autorun, observable} from "mobx";
import {agent, SuperagentPromise} from "../utils/agent";
import * as _ from "lodash";
import * as React from "react";

export interface ModalParams {
  title: string;
  content: JSX.Element;
  callback?: (buttonLabel: string) => void;
  negative?: boolean;
  positiveLabel?: string;
  negativeLabel?: string;
}

function wrappedAgent(appUtilStore: AppUtilStore): SuperagentPromise {

  const wrap = {} as SuperagentPromise;
  _.each([
    'get',
    'getc',
    'post',
    'put',
    'del'
  ], (method) => {


    wrap[method] = function (url, data, schema = data) {
      return agent[method].apply(agent, arguments)
        .catch((reason) => {
          appUtilStore.showError(reason, schema);
          if (reason.response.status === 401) {
            appUtilStore.isAuthenticated = false;
          }
          throw reason;
        })
    }

  });


  return wrap;
}

const MessageDictionary = {
  "Bad Request": "リクエストが不正です",
  "Internal Server Error": "操作を完了できません",
  "Not Found": "データが見つかりません",
  "Unauthorized": "認証されていません",
};

const BodyMessageDictionary = {
  "notNull Violation\: (.*) cannot be null": "[<%= f1 %>]は必須入力です",
  "Not Found": "対象データは削除された、または他のユーザーによって更新済みです",
  "Validation error": "データ整合性に問題があります。操作をやり直してみてください",
  "Unauthorized": "タイムアウトか、サーバーメンテナンスにより認証が解除されました。再度ログインしてください。",
};

export class AppUtilStore {
  @observable isAuthenticated: boolean;
  @observable height: number = window.innerHeight;
  @observable width: number = window.innerWidth;
  @observable dimmerActive: boolean;
  @observable modalOpen: boolean;
  @observable dropDownParams: any;
  @observable title: string;


  constructor() {
    autorun(() => {
      document.title = this.title;
    });
    this.title = '作品リスト';
  }

  agent: SuperagentPromise = wrappedAgent(this);

  modalParams: ModalParams = {} as any;

  showModal(params: ModalParams) {
    this.modalParams = _.defaults(
      {
        negative: false,
        positiveLabel: 'OK',
        negativeLabel: 'Cancel'
      }
      , params);
    this.modalOpen = true;
  }

  showElementFixed(event: any, contents: any) {
    event.persist();
    this.dropDownParams = {
      open: true,
      event: event,
      contents: contents
    };
  }

  convertMessage(message: string) {
    return MessageDictionary[message] ? MessageDictionary[message] : message;
  }

  convertBodyMessage(message: string, schema?: any) {
    let result = _.compact(_.map(BodyMessageDictionary, (val, key) => {
      let match = new RegExp(key).exec(message);

      return match && _.template(val)(
        _.reduce(
          match,
          (res, captureString, i) => {
            if (schema && schema.properties && schema.properties[captureString]) {
              res[`f${i}`] = schema.properties[captureString].title;
            } else {
              res[`f${i}`] = captureString;
            }

            return res;
          }, {}));

    }));

    return result.length > 0 ? result[0] : message;

  }

  convertError(message: string) {
    return MessageDictionary[message] || message;
  }


  handleApiCall(promise: Promise<any>, schema?: any) {
    return promise
      .catch((reason) => {
        this.showError(reason, schema);
        this.isAuthenticated = false;
        throw reason;
      });
  }

  showError(reason: { message: string, response: { body: any, text: string } }, schema?: any) {
    this.showModal({
      title: this.convertMessage(reason.message),
      content: <div>
        <div>
          {
            reason.response.body ?
              _.concat(
                reason.response.body.message.split(",\n").map((message) => {
                  return <div key={message}>{this.convertBodyMessage(message, schema)}</div>
                }),
                reason.response.body.errors.map((error) => {
                  return <div key={error}>{error}</div>
                }),
              )
              : this.convertBodyMessage(reason.response.text)
          }
        </div>
      </div>
    })
  }
}
