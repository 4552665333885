import * as React from "react";
import * as ReactDOM from "react-dom";
import * as _ from "lodash";
import Bluebird = require("bluebird");

export class HandleKeyWrapper extends React.Component<{
  noStopKeys?: Array<number>
}, { noStopKeys: Array<number> }> {
  constructor(props) {
    super(props);
    this.state = _.assign({
      noStopKeys: [9, 13, 27]
    }, props)
  }

  handleKeyDown(e) {

    if (!_.includes(this.state.noStopKeys, e.which)) {
      e.stopPropagation();
    }
  }

  render() {
    return <div onKeyDown={this.handleKeyDown.bind(this)}>
      {this.props.children}
    </div>
  }

}

export class EditorBase<P, S> extends React.Component<P, S> {
  input: any;

  getValue(): any {
    let updated = {};
    updated[(this.props as any).column.key] = this.getInputNode().value;
    return updated;
  }

  getInputNode() {
    let domNode = this.input || ReactDOM.findDOMNode(this);
    if (domNode.tagName === 'INPUT') {
      return domNode;
    }

    return domNode.querySelector('input:not([type=hidden])');
  }

}