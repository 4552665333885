import * as React from "react";
import {observer} from "mobx-react";
import {RouteStore} from "../providers/RouteProvider";
import {AppUtilStore} from "../store/AppUtilStore";
import {StockDetailStore} from "../store/StockDetailStore";
import {DateUtils} from "../../utils/dateUtils";
import {VirtualizedDataGrid} from "./lib/VirtualizedDataGrid";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid/Grid";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import {StockEditStore} from "../store/StockEditStore";
import {StockDetailArtStore} from "../store/StockDetailArtStore";


export interface StockEditProps {
  routeStore?: RouteStore;
  appUtilStore?: AppUtilStore;
  stockEditStore?: StockEditStore;
  stockDetailStore?: StockDetailStore;
  stockDetailArtStore?: StockDetailArtStore;
}

@observer(["stockEditStore", "stockDetailStore", "stockDetailArtStore", "routeStore", "appUtilStore"])
export class StockEditComponent extends React.Component<StockEditProps, any> {

  bindCellKeyDownToStore(store: { updateByIndex: (number) => any }) {
    return (e: KeyboardEvent, rowIndex: number, columnIndex: number) => {
      if (e.keyCode === 13 && e.ctrlKey) {
        store.updateByIndex(rowIndex);
        return false;
      }
      return true;
    }
  }

  render() {

    if (!this.props.stockEditStore.visible) {
      return null;
    }
    const seStore = this.props.stockEditStore;
    const sdStore = this.props.stockDetailStore;
    const sdaStore = this.props.stockDetailArtStore;

    let toolbar: JSX.Element = null;

    const tabChange = (action) => {
      if (seStore.action !== action) {
        this.props.routeStore.history.push(`/stocks/${seStore.stockId}/${action}`)
      }
    };

    if (seStore.isDetail) {
      toolbar = <div>
        <Grid>
          <Grid.Column floated='left' width={11}>

            <div style={{width: 80, display: 'inline-block'}}>
              <Input value={sdStore.addRowsInputNum}
                     style={{width: "100%"}}
                     onChange={(e) => {
                       try {
                         const skip = parseInt(e.currentTarget.value, 10);
                         if (skip >= 0) {
                           sdStore.addRowsInputNum = skip
                         } else {
                           sdStore.addRowsInputNum = 0;
                         }
                       } catch (e) {
                         sdStore.addRowsInputNum = 0;
                       }
                     }}
                     onKeyDown={(e) => {
                       let increment = sdStore.addRowsInputNum;
                       if (e.keyCode === 38) {
                         increment++;
                       } else if (e.keyCode === 40) {
                         increment--;
                       }
                       if (increment < 0) {
                         increment = 0;
                       }
                       sdStore.addRowsInputNum = increment;
                     }}
              />
            </div>
            <Button onClick={() => {
              sdStore.addRows();
            }}>行追加</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <div style={{textAlign: "right"}}>
              <Button floated="right" onClick={() => {
                sdStore.saveRows()
              }} icon="save"/>
            </div>
          </Grid.Column>
        </Grid>
      </div>;
    }
    if (seStore.isArt) {
      toolbar = <div>
        <Grid>
          <Grid.Column floated='right' width={5}>
            <div style={{textAlign: "right"}}>
              <Button floated="right" onClick={() => {
                sdaStore.saveRows()
              }} icon="save"/>
            </div>
          </Grid.Column>
        </Grid>
      </div>;
    }

    const stock = seStore.stock;
    return <div>
      <Segment.Group horizontal>
        <Segment>
            <span>
            <span><h3>{stock.place.name} {stock.detail}</h3></span>
            <span><h4>仕入れ日: {DateUtils.format(stock.stockDate)}</h4></span>
            </span>
        </Segment>
      </Segment.Group>

      <div>
        <div className="ui top attached tabular menu">
          <a className={(seStore.isDetail ? 'active' : '') + " item"}
             onClick={tabChange.bind(this, 'details')}>伝票編集
          </a>
          <a className={(seStore.isArt ? 'active' : '') + " item"}
             onClick={tabChange.bind(this, 'arts')}>作品編集
          </a>
        </div>

        <div className="ui bottom attached active tab segment" style={{display: 'block'}}>
          <div>
            {toolbar}

            {
              seStore.isDetail && sdStore.visible && <VirtualizedDataGrid
                updateRef={sdStore.records}
                columns={sdStore.columns}
                rowsCount={sdStore.rowsCount}
                rowGetter={sdStore.rowGetter}
                onGridRowsUpdated={sdStore.onGridRowsUpdated}
                onCellKeyDown={this.bindCellKeyDownToStore(sdStore)}
                onGridSort={sdStore.onGridSort}
                sortColumn={sdStore.sortColumn}
                sortDirection={sdStore.sortDirection}
                fixedColumnCount={4}
                height={this.props.appUtilStore.height - 300}
              />
            }

            {
              seStore.isArt && sdaStore.visible && <VirtualizedDataGrid
                updateRef={sdaStore.records}
                columns={sdaStore.columns}
                rowsCount={sdaStore.rowsCount}
                rowGetter={sdaStore.rowGetter}
                onGridRowsUpdated={sdaStore.onGridRowsUpdated}
                onCellKeyDown={this.bindCellKeyDownToStore(sdaStore)}
                onGridSort={sdaStore.onGridSort}
                sortColumn={sdaStore.sortColumn}
                sortDirection={sdaStore.sortDirection}
                fixedColumnCount={4}
                height={this.props.appUtilStore.height - 300}
              />
            }

          </div>
        </div>

      </div>
    </div>

  }

}
