import * as React from "react";
import {inject, observer, Provider} from "mobx-react";
import {StockEditComponent} from "../components/StockEditComponent";
import {StockDetailStore} from "../store/StockDetailStore";
import {StockDetailArtStore} from "../store/StockDetailArtStore";
import {AppUtilStore} from "../store/AppUtilStore";
import {autorun, transaction} from "mobx";
import {RouteStore} from "./RouteProvider";
import {StockEditStore} from "../store/StockEditStore";

@inject("appUtilStore", "routeStore")
@observer
export class StockEditProvider extends React.Component<{ appUtilStore?: AppUtilStore, routeStore?: RouteStore }, {}> {

  stockEditStore: StockEditStore;
  stockDetailStore: StockDetailStore;
  stockDetailArtStore: StockDetailArtStore;

  constructor(props) {
    super(props);
    this.stockEditStore = new StockEditStore(props.routeStore, props.appUtilStore);
    this.stockDetailArtStore = new StockDetailArtStore(props.routeStore, props.appUtilStore);
    this.stockDetailStore = new StockDetailStore(props.routeStore, props.appUtilStore);

    autorun(() => {

      if (this.stockEditStore.isInit) {
        props.history.push(`/stocks/${props.match.params.stockId}/details`)
      } else {
        transaction(() => {
          this.stockEditStore.retrieve();

          if (this.stockEditStore.isArt) {
            this.stockDetailArtStore.retrieve();
          } else if (this.stockEditStore.isDetail) {
            this.stockDetailStore.retrieve();
          }

        })
      }
    })
  }

  render() {
    return <Provider {...this}>
        <StockEditComponent/>
      </Provider>
  }
}
