import {Treebeard} from "react-treebeard";
import {autorun, computed, observable} from "mobx";
import {IArt} from "../../main/models/Art";
import {AppUtilStore} from "./AppUtilStore";
import {RouteStore} from "../providers/RouteProvider";
import {ArtCostStore} from "./artDetailStore/ArtCostStore";
import Bluebird = require("bluebird");


export class ArtDetailStore {

  @observable.ref visible: boolean;
  @observable.ref art: IArt;

  public artCostStore: ArtCostStore;

  get agent() {
    return this.appUtilStore.agent;
  }

  get action() {
    return this.routeStore.match.params.action;
  }

  get artId() {
    return this.routeStore.match.params.artId;
  }

  constructor(
    public routeStore: RouteStore, public appUtilStore: AppUtilStore,
  ) {
    this.artCostStore = new ArtCostStore(this);

    autorun(() => {
      this.visible = false;
      return this.retrieve()
        .then(() => {

          if (this.isCost) {
            this.appUtilStore.title = `【${this.art.id}】 ${this.art.title}経費編集`;
          }
          this.visible = true;
        });
    });
  }

  retrieve() {
    return Bluebird.all(
      [
        this.agent.get(`/rest/arts/${this.artId}?scope=full`)
          .then((art) => {
            this.art = art.body;
          })
      ]);
  }

  @computed
  get isCost(): boolean {
    return this.action === 'cost';
  }

}
