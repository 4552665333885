import {Treebeard} from "react-treebeard";
import {computed, observable} from "mobx";
import {IStock} from "../../main/models/Stock";
import {SAResponse} from "../utils/agent";
import {RouteStore} from "../providers/RouteProvider";
import * as React from "react";
import {AppUtilStore} from "./AppUtilStore";


export class StockEditStore {

  @observable.ref visible: boolean;
  @observable.ref stock: IStock;

  get agent() {
    return this.appUtilStore.agent;
  }

  get action() {
    return this.routeStore.match.params.action;
  }

  get stockId() {
    return this.routeStore.match.params.stockId;
  }

  constructor(public routeStore: RouteStore, private appUtilStore: AppUtilStore) {
  }

  retrieve() {
    this.visible = false;

    return this.agent.get(`/rest/stocks/${this.stockId}?scope=forStockList&sort=id`)
      .then((stock: SAResponse<IStock>) => {
        this.stock = stock.body;

        if (this.isDetail) {
          this.appUtilStore.title = this.stock.detail + "仕入れ伝票編集";
        } else if (this.isArt) {
          this.appUtilStore.title = this.stock.detail + "仕入れ作品編集";
        }

        this.visible = true;
      });

  }

  @computed
  get isInit(): boolean {
    return this.action === ':action';
  }

  @computed
  get isDetail(): boolean {
    return this.action === 'details';
  }

  @computed
  get isArt(): boolean {
    return this.action === 'arts';
  }

}
