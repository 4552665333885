import * as React from "react";
import * as _ from "lodash";
import {action, computed, observable, transaction} from "mobx";

import {SemanticCOLORS, SemanticICONS} from "semantic-ui-react";
import {IArtCostReceipt} from "../../../main/models/ArtCostReceipt";
import {RouteStore} from "../../providers/RouteProvider";
import {IArt} from "../../../main/models/Art";
import {agent as pureAgent} from "../../utils/agent";
import {NumberCellFormatter} from "../../components/dataGrid/NumberCell";
import {ColumnProps} from "../../components/lib/VirtualizedDataGridDefinition";
import {DataGridBaseStore} from "../dataGrid/DataGridBaseStore";
import {JsonSchemeScope} from "../../components/Utilities";
import {AppUtilStore} from "../AppUtilStore";
import {IArtCost} from "../../../main/models/ArtCost";
import Bluebird = require("bluebird");
import {ManageStore} from "./ManageStore";


type IArtRegisterStore = IArtCost & {
  no?: number;
  isUpdated?: boolean;
  _message?: string;
}

export class RegisterArtStore extends DataGridBaseStore<IArtRegisterStore> {

  @observable.ref visible: boolean;

  get artCostReceiptId(): number {
    return this.routeStore.match.params.artCostReceiptId;
  }

  constructor(public manageStore: ManageStore, public routeStore: RouteStore, appUtilStore: AppUtilStore) {
    super(appUtilStore);
  }

  customSchemaScope(schemaScope: JsonSchemeScope) {
    schemaScope.includes.push(
      {
        prefix: 'art',
        includes: [{
          prefix: 'author',
        }]
      },
      {
        prefix: 'artCostItem',
      },
      {
        prefix: 'artCostReceipt',
      }
    );
    return schemaScope;
  }

  get entityPluralLabel(): string {
    return 'artCosts';
  }


  getActionIconProp(dependentValues: any): { name: SemanticICONS, color: SemanticCOLORS } {
    const name: SemanticICONS = dependentValues.art &&
    dependentValues.art.artResult ?
      'gift' : 'list';

    const color: SemanticCOLORS = dependentValues.isUpdated ? 'red' :
      dependentValues.art &&
      dependentValues.art.artResult &&
      (dependentValues.art.artResult.planArtId) === dependentValues.id ?
        'yellow' : 'black';

    return {name, color};
  }

}


export class RegisterNewArtStore extends RegisterArtStore {

  get additionalColumnProps(): ColumnProps[] {
    const props = super.additionalColumnProps;
    props.push({
      key: "_message",
      name: "メッセージ",
      width: 200
    });
    return props;
  }

  retrieve() {
    return this.schemas
    .then(()=>{
      return this.retrieveSuggestion()
    })
    .then(() => {
      this.records = [];
      this.visible = true;
    });
  }

  updateByIndex(index: number) {
    // ctrl+enter で保存処理が呼ばれるのを回避する
  }
  @computed
  get visibleAndOrderColumnKeys(): string[] {
    return [
      'action',  // system
      '_message', // system
      'artId',
      'art.author.name',
      'art.title',
      'artCostItemId',
      'receiptDate',
      'cost',
      'note',
      'art.technique',
      'art.edition',
      'art.since',
      'art.artSign.name',
      'art.reasonedNo',
      'art.listPrice',
      'art.bazaarPrice',
      'art.auctionPrice',
      'art.cost',
      'art.artDetailCost',
      'art.consignmentPrice',
      'art.note'
    ];
  }

  @action.bound
  registerArtCosts() {

    if (_.filter(this.records, (planArt) => {
      return !_.includes(planArt._message, '登録できます');
    }).length > 0) {
      this.appUtilStore.showModal({
        title: '登録できません',
        content: <div>登録できない作品が存在します</div>
      });
      return;
    }

    const entityEndpoint = `/rest/artCosts/bulk`;

    const promise = this.agent.post(entityEndpoint, this.records.map((artCosts) => {
      return artCosts;
    }));
    promise
    .then((response) => {
      console.log(response);
      this.records = [];
    })
    .catch((response) => {
      console.log('agent.put.catch', response);
      let planArts = this.records;
      this.records = [];

      planArts.forEach((pa: IArtCost) => {
        return this.addRegisterTarget(pa.artId);
      })

    })
    .finally(() => {
      console.log('agent.put.finally');
    })
  }

  addRegisterTarget(artId: number) {

    if (artId === 0) {
      return;
    }

    const artEndpoint = `/rest/arts/${artId}?scope=full`;
    const artCostsEndpoint = `/rest/artCosts/?artId=${artId}&artCostReceiptId=${this.artCostReceiptId}`;

    const entity: IArtRegisterStore = {
      artId: artId,
      artCostReceiptId: this.artCostReceiptId,
      receiptDate: this.manageStore.artCostReceipt.receiptDate,
      isUpdated: true,
    };
    const artPromise = pureAgent.get<IArt>(artEndpoint);
    const artCostsPromise = pureAgent.get<IArtCostReceipt[]>(artCostsEndpoint);

    return Bluebird.all([artPromise, artCostsPromise])
    .then(([art, artCosts]) => {
      console.log(art);
      console.log(artCosts);
      entity.art = art.body;
      entity._message = '登録できます';
    })
    .catch((response) => {

      if (response.status === 401) {
        this.appUtilStore.isAuthenticated = false;
      } else if (response.status === 404) {
        entity._message = '存在しません';
      } else {
        console.error(response);
        entity._message = '不明なエラーです';
      }
    })
    .finally(() => {
      this.records = [entity, ...this.records];
    });
  }

  @action.bound
  addArtKeyDown(e) {
    if (e.which === 13) {
      const artId = _.toInteger(e.target.value.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      }));
      e.target.value = "";
      console.log(e);
      return this.addRegisterTarget(artId);
    }
  }


  canSaveDependentValues(dependentValues: any): boolean {
    return false;
  }


  canDeleteDependentValues(dependentValues: any): boolean {
    return true;
  }

}

export class EditArtStore extends RegisterArtStore {

  get recordQuery(): string {
    return `?scope=forRestList&count=1000&sort=artId&artCostReceiptId=${this.artCostReceiptId}`;
  }

  retrieve() {
    return super.retrieve().then(() => {
      this.visible = true;
    })
  }

  @computed
  get visibleAndOrderColumnKeys(): string[] {
    return [
      'action',  // system
      'artId',
      'art.author.name',
      'art.title',
      'artCostItemId',
      'receiptDate',
      'cost',
      'note',
      'art.technique',
      'art.edition',
      'art.since',
      'art.artSign.name',
      'art.reasonedNo',
      'art.listPrice',
      'art.bazaarPrice',
      'art.auctionPrice',
      'art.cost',
      'artDetailCost',
      'art.consignmentPrice',
      'art.note'
    ];
  }

  getAdditionalCellActions(dependentValues): JSX.Element[] {
    const children = super.getAdditionalCellActions(dependentValues);
    children.push(
      <div key="editArt" className="item" onClick={() => {
        this.routeStore.history.push(`/entity/arts?scope=full&id=${dependentValues.artId}`)
      }}>作品編集
      </div>
    );

    children.push(
      <div key="artHistory" className="item" onClick={() => {
        this.routeStore.history.push(`/entity/planArts?scope=forRestListReadOnly&artId=${dependentValues.artId}`)
      }}>作品履歴</div>
    );

    if (dependentValues.art) {
      children.push(
        <div key="editArtCost" className="item" onClick={() => {
          this.routeStore.history.push(`/artDetails/${dependentValues.art.id}/cost`)
        }}>作品経費一覧</div>
      );
    }

    if (dependentValues.art &&
      dependentValues.art.artResult) {
      children.push(
        <div key="soldAtPlan" className="item" onClick={() => {
          this.routeStore.history.push(`/plans/${dependentValues.art.artResult.planArt.artCostReceiptId}/arts/sales`)
        }}>売却企画</div>
      );
    }


    return children;
  }

}