import * as React from "react";
import {inject, observer} from "mobx-react";

import {agent} from "../utils/agent";
import {EntityStore} from "../store/EntityStore";
import * as Bluebird from "bluebird";
import * as _ from "lodash";

import Form from "react-jsonschema-form";
import {RouteStore} from "../providers/RouteProvider";


@inject("entityStore", "routeStore")
@observer
export class EntityComponent extends React.Component<{ entityStore?: EntityStore, routeStore?: RouteStore }, {}> {

  constructor(props) {
    super(props);
    console.log("EntityComponent:constructor", props);
  }

  retrieveEntity(props: { entityStore?: EntityStore, routeStore?: RouteStore }) {
    let retrieves = [
      agent.get(`/rest/${props.routeStore.match.params.entity}.schema`)
    ];
    if (props.routeStore.match.params.id !== 'new') {
      retrieves.push(agent.get(`/rest/${props.routeStore.match.params.entity}/${props.routeStore.match.params.id}`))
    }

    Bluebird
      .all(retrieves)
      .then(([schema, entity]: any[]) => {
        props.entityStore.entity = !_.isUndefined(entity) ? _.omitBy(entity.body, _.isNull) : {};
        schema.body.properties = _.omitBy(schema.body.properties, (val, key) => {
          return key.endsWith('ById');
        })
        schema.body.required = _.remove(schema.body.required, ["id",
          "createdAt",
          "updatedAt",
          "version"]);

        props.entityStore.schema = schema.body;
        props.entityStore.visible = true;
      })
      .catch((data) => {
        console.log("catch", data)
      })
  }

  componentDidMount() {
    console.log("componentDidMount", this.props)
    this.retrieveEntity(this.props);
  }

  componentWillReceiveProps(props) {
    console.log("componentWillReceiveProps", props)
    this.retrieveEntity(props);
  }

  formText(props) {
    return <span className="form-text">{props.value}</span>
  }

  uiSchema = {
    "id": {
      "ui:widget": this.formText
    },
    "version": {
      "ui:widget": "hidden"
    },
    "birthDay": {
      "ui:widget": "alt-date"
    },
    "createdAt": {
      "ui:widget": this.formText
    },
    "updatedAt": {
      "ui:widget": this.formText
    },
    "deletedAt": {
      "ui:widget": "hidden"
    }
  };

  onDelete() {
    agent.del(`/rest/${this.props.routeStore.match.params.entity}/${this.props.routeStore.match.params.id}?version=${this.props.entityStore.entity.version}`)
      .then((response) => {
        console.log('agent.put.then', response)
        this.props.entityStore.entity = _.omitBy(response.body, _.isNull);
      })
  }

  onSubmit(formCallbackArgs) {
    console.log("onSubmit", this.props.entityStore.entity, formCallbackArgs)
    this.props.entityStore.entity = formCallbackArgs.formData;
    let promise: Bluebird<any>;

    if (formCallbackArgs.formData.id) {
      promise = agent.put(`/rest/${this.props.routeStore.match.params.entity}/${formCallbackArgs.formData.id}`, formCallbackArgs.formData);
    } else {
      promise = agent.post(`/rest/${this.props.routeStore.match.params.entity}`, formCallbackArgs.formData);
    }

    promise
      .then((response) => {
        console.log('agent.put.then', response)
        this.props.entityStore.entity = _.omitBy(response.body, _.isNull);
      })
      .catch((response) => {
        console.log('agent.put.catch', response)
      })

  }

  render() {
    if (this.props.entityStore.visible) {
      if (_.isUndefined(this.props.entityStore.entity)) {
        return <div>loading</div>;
      } else {
        return <div>
          <div>
            <div>{JSON.stringify(this.props.entityStore.entity)}</div>
            <Form
              className="form-inlinea"
              schema={this.props.entityStore.schema}
              uiSchema={this.uiSchema}
              onChange={(formCallbackArgs) => {
                this.props.entityStore.entity = formCallbackArgs.formData;
              }}
              onSubmit={this.onSubmit.bind(this)}
              formData={this.props.entityStore.entity}
            >
              <p>
                <button type="submit" className="btn btn-info">
                  Submit
                </button>
                {
                  this.props.entityStore.entity.id ?
                    <button type="button" className="btn btn-warning" onClick={this.onDelete.bind(this)}>
                      Delete</button> : null
                }
              </p>
            </Form>
          </div>
        </div>
      }
    } else {
      return null;
    }
  }
}
